import React from 'react'
import Checkbox from '../../../components/checkbox/checkbox';
import InfoPopup from '../../../components/info-popup/info-popup'
import Select from '../../../components/select/select'
const EASY_MESH_TYPE = {
    CONTROLLER: 0,
    AGENT: 1,
}

const WIRELESS_CIPHER = {
    NONE: 0,
    WEP: 1,
    WPA: 2,
    WPA2: 3,
    WPA_WPA2: 4
}

const WIRELESS_CRYPTO = {
    NONE: 0,
    TKIP: 1,
    AES: 2,
    TKIP_AES: 3
}
let typeMode = {
    'Nó Principal': EASY_MESH_TYPE.CONTROLLER,
    'Nó Secundário': EASY_MESH_TYPE.AGENT
}

function getModeOptions() {
    let options = []

    for (const [key, value] of Object.entries(typeMode)) {
        options.push({ value: value, text: key })
    }
    return options
}

export default function profileMesh({ mesh, setMesh, wlan, setWlan, radio, setRadio, editedProfile }) {

    function handleMesh() {
        if (!mesh.enabled && editedProfile) {
            wlan.freq_5G.ssid = editedProfile.wireless.freq_2_4G.ssid
            wlan.freq_5G.key = editedProfile.wireless.freq_2_4G.key
            wlan.freq_5G.cipher = WIRELESS_CIPHER.WPA2
            wlan.freq_5G.crypto = WIRELESS_CRYPTO.AES
            wlan.enableDualBand = true
            setWlan({ ...wlan })
        }
        mesh.enabled = !mesh.enabled
        setMesh({ ...mesh })

    }
    return [
        <div className='with-info'>
            <Checkbox id='mesh-enable'
                label='Habilitar'
                value={mesh?.enabled}
                toggleFn={handleMesh}
                key='mesh'
            ></Checkbox>
            {!mesh?.enabled ?
                <InfoPopup
                    message={<div className='mesh-info'> Com a tecnologia inmesh habilitada,
                        você consegue expandir sua rede Wi-Fi ao utilizar um ou mais roteadores configurados
                        em conjunto com uma ONT Intelbras.<br></br>
                        Também não será possível alterar algumas configurações avançadas que já serão pré-configuradas
                        para o melhor funcionamento da rede inmesh.<br></br><br></br>
                        Obs: Modelos W4-300F e W4-300S não tem suporte a essa função.
                    </div>}
                ></InfoPopup>
                : null}
        </div>,
        <div>
            {!mesh?.enabled ? null :
                <div>
                    <Select id='mesh-type'
                        label='Modo de operação'
                        value={mesh.type}
                        onChange={(e) => {
                            mesh.type = Number(e.target.value)
                            mesh.backhaul = 2
                            setMesh({ ...mesh })
                        }}
                        options={getModeOptions()}
                    ></Select>
                    <div className='no-managed'> A função InMesh está disponível a partir da 1.21.8 para as versões de firmware
                        recomendamos que mantenha sempre seu parque atualizado com a última versão de firmware disponível na
                        plataforma, acessando a aba Atualização.
                    </div>
                </div>
            }
        </div>
    ]
}