import React from 'react'
import Input from '../../../components/input/input';
import Checkbox from '../../../components/checkbox/checkbox';
import constants from './profile-contants'
import WanDNS from './profile-wan-dns'

function dhcpMtuRangeValidator(value){

    if(value < constants.MIN_IP4_MTU || value > constants.MAX_IP4_MTU){
        return `O MTU deve estar entre ${constants.MIN_IP4_MTU} e ${constants.MAX_IP4_MTU}`
    }

    return ''
}


export default function ProfileWanDhcp({wan, setWan}) {

    const handleMTU = (e) => {

        let value = e.target.value

        if(isNaN(value)) return

        value = Number(value)

        if(value > constants.MAX_IP4_MTU + 100000) return

        wan.mtu = value
        setWan({...wan})

    }

    return [

        <Input id='wan-mtu'
            name='dhcp.mtu'
            label='MTU'
            value={wan.mtu}
            onChange={handleMTU}
            validators={[dhcpMtuRangeValidator]}
            key='dhcp-1'
        ></Input>,

        <Checkbox name='wan-automatic-dns'
            label='Obter DNS automaticamente'
            value={wan?.dns_v4.automatic}
            toggleFn={() => {
                setWan({...wan, dns_v4: {...wan?.dns_v4, automatic: !wan?.dns_v4.automatic}})
            }}
        ></Checkbox>,
        <div>
            {WanDNS(wan, setWan)}
        </div>
        

    ]
}