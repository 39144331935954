import React, { useState } from 'react'
import SearchIcon from '../../../components/icons/search-icon'

import './multifield-search.css'

export default function MultiFieldSearch({onSearch}) {

    const [deviceid, setDeviceId] = useState('')
    // const [alias, setAlias] = useState('')
    const [clientid, setClientId] = useState('')
    const [profile, setProfile] = useState('')
    const [version, setVersion] = useState('')

    const scapePoints = (value) => {
        return value.replace(/\./g, '\\.')
    }

    const submitSearch = () => {
        let queryString = `deviceid=${deviceid}&clientid=${clientid}&profile=${profile}&fw_version=${scapePoints(version)}`
        onSearch(queryString)
    }

    const handleEnter = (e) => {
        if(e.key === 'Enter')
            submitSearch()
    }

    const cleanFilters = () => {
        setDeviceId('')
        // setAlias('')
        setClientId('')
        setProfile('')
        setVersion('')
        let queryString = ''
        onSearch(queryString)
    }

    return <div className='multi-field-search'>

        <div className='field'>
            <label>Dispositivo</label>
            <input className='start'
                id='multi-search-device'
                value={deviceid}
                onChange={e => setDeviceId(e.target.value.toLowerCase())}
                placeholder='ID do dispositivo'
                onKeyUp={handleEnter}
            ></input>
        </div>
        {/* <div className='field'>
            <label>Apelido</label>
            <input 
                id='multi-search-alias'
                value={alias}
                onChange={e => setAlias(e.target.value)}
                placeholder='Apelido'
                onKeyUp={handleEnter}
            ></input>
        </div> */}

        <div className='field'>
            <label>Cliente</label>
            <input
                id='multi-search-client'
                value={clientid}
                onChange={e => setClientId(e.target.value)}
                placeholder='ID do cliente'
                onKeyUp={handleEnter}
            ></input>
        </div>

        <div className='field'>
            <label>Perfil</label>
            <input
                id='multi-search-profile'
                value={profile}
                onChange={e => setProfile(e.target.value)}
                placeholder='Perfil'
                onKeyUp={handleEnter}
            ></input>
        </div>

        <div className='field'>
            <label>Versão</label>
            <input
                id='multi-search-version'
                value={version}
                onChange={e => setVersion(e.target.value)}
                placeholder='Versão de firmware'
                onKeyUp={handleEnter}
            ></input>
        </div>


        <button className='field-search'
            onClick={submitSearch}
        >
            <label>Buscar</label>
            <SearchIcon size='20'></SearchIcon>
        </button>

        <div id='clean-filters' onClick={cleanFilters}>limpar todos os filtros</div>

    </div>
}