import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { MainHeaderContext } from '../../../components/main-header/main-header-state'

import List, { ListOrderStates } from '../../../components/list/list'

import './devices.css'
import Button from '../../../components/button/button';
import isElectron from '../../server/is-electron'
import { useToasts } from 'react-toast-notifications'
import { BackendContext, backendStatus } from '../../../backend/backend';
import DeviceDetails from './device-details';
import DownloadApps from '../../components/download-apps';
import DiagramRemotize from '../../components/diagram';
import DeviceTour from './device-tour';
import { UIEventsContext } from '../../uievents-context';
import Pager from '../../../components/pager/pager'
import DeviceAsssociator from './device-associator';
import MultiFieldSearch from './multifield-search';
import SearchIcon from '../../../components/icons/search-icon'
import { UserContext } from '../../user-context'
import Tabs from '../../../components/tabs/tabs'
import deviceFactory from '../../apis/device-factory'
import Loading from '../../../components/loading/loading'
import InfoPopup from '../../../components/info-popup/info-popup'
import DeviceFactory from '../../apis/device-factory'
import HelpIcon from '../../../components/icons/help-icon'
import TooltipText from '../../../components/tooltip-text/tooltip-text'


export const profileStatus = {
    NO_PROFILE: 0,
    ASSOCIATING: 1,
    CONFIRMED: 2,
    REAPLY: 4,
}

export const deviceStatus = {
    WAITING: 0,
    OFFLINE: 1,
    ONLINE: 2,
    CONFIRMING: 3,
    UPDATING: 4,
    ERROR: 5
}

export const assocStatus = {
    NOT_ASSOCIATED: 0,
    ASSOCIATING: 1,
    ASSOCIATED: 2,
}

const FIND_TIMEOUT = 30

export function profileIsApplying(device) {

    if(DeviceFactory.isTR069(device.model))
        return false

    return device.profile.status === profileStatus.ASSOCIATING || device.profile.status === profileStatus.REAPLY
}

export function canBeManaged(device) {

    let  {assoc_status} = device

    return (assoc_status === assocStatus.ASSOCIATED) && deviceFactory.haveManagementAPI(device)
}

function getCategoryFromPath(history) {

    let pathname = history.location.pathname

    if(pathname.charAt(pathname.length - 1) === '/') {
        history.push('0')
    }

    return pathname.charAt(pathname.length - 1)
}

async function fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, filters, sort){

    const {pageSize, pageNumber} = pagerInfo

    let category = getCategoryFromPath(history)

    let result = await backend.retrieveFresh(
        `devices?category=${category}&pages=y&pagesize=${pageSize}&pagenumber=${pageNumber}${filters ? '&' + filters : ''}${sort ? '&' + sort : ''}`
    )

    if(result.status !== backendStatus.SUCCESS){
        return 
    }

    pagerInfo.totalElements = result.headers.get('x-devices-total')
    pagerInfo.hasGateways = result.headers.get('x-has-gateway') === "true"

    setPagerInfo({...pagerInfo})

    if(result.status !== backendStatus.SUCCESS)
        return

    setDevices(() => {
        setLoading(false)
        return result.content
    })
    
}

async function fetchProfiles(backend, setProfiles, setLoading){

    let result = await backend.retrieveFresh('profiles?idsonly=y')

    if(result.status !== backendStatus.SUCCESS)
        return

    setProfiles(() => {
        setLoading(false)
        return result.content
    })

}

function handleProfileName(device){

    if(!device.profile){
        device.profile = {
            name: 'Sem perfil',
            status: 0
        }
    }

}

function deviceNeedsUpdate(device, newDevice) {
    return device.clientid !== newDevice.clientid
}
function deviceProfileNeedsUpdate(device, newDevice) {
    return device.profile.profileid !== newDevice.profile.profileid
}

const DevicesPage = () => {

    const [loading, setLoading] = useState(true)
    const [infoOpen, setInfoOpen] = useState(false)
    const [buttonLoading, setbuttonLoading] = useState(false)
    const [devices, setDevices] = useState([])
    const [profiles, setProfiles] = useState([])
    const [selectedDevice, setSelectedDevice] = useState(null)
    const [username, setUsername] = useState("")
    const [searched, setSearched] = useState(false)
    const [filterQueryString, setFilterQueryString] = useState("")
    const [sortQueryString, setSortQueryString] = useState(null)
    const { addToast } = useToasts()

    const queryRef = useRef(filterQueryString)
    const sortRef = useRef(sortQueryString)

    const [pagerInfo, setPagerInfo] = useState({pageSize: 20, pageNumber: 1, totalElements: 0, hasGateways: false})

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const uievents = useContext(UIEventsContext)

    const history = useHistory()
    const [user,] = useContext(UserContext)

    useEffect(() => {
        queryRef.current = filterQueryString
        sortRef.current = sortQueryString
    }, [filterQueryString, sortQueryString])

    const uieventsHandler = () => {
        fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, queryRef.current, sortRef.current)
    }

    useEffect(() => {

        header.notifications.set(true)

        uievents.setHandler('devices', uieventsHandler)

        // eslint-disable-next-line
    },[])

    useEffect(() => {

        if(sortQueryString === null) return

        fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, filterQueryString, sortQueryString)

        // eslint-disable-next-line
    }, [sortQueryString])

    useEffect(() => {

        if(!user) return

        if(!user.type){
            setLoading(true)
            return
        }

        fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, filterQueryString, sortQueryString)

        // eslint-disable-next-line
    }, [user])

    const selectDevice = (deviceid) => {
        devices.forEach((device) => {
            if(deviceid === device.deviceid){
                handleProfileName(device)
                setSelectedDevice(device)
            }
        })
    }

    const updateSelectedProfile = async() => {

        let result = await backend.retrieveFresh(`devices/${selectedDevice.deviceid}/profile`)

        selectedDevice.profile = result.content

        if(selectedDevice.profile.status === profileStatus.CONFIRMED){

            addToast('Perfil associado com sucesso', {
                appearance: 'success',
                autoDismiss: true,
                autoDismissTimeout: 1500,
            })

            setSelectedDevice({...selectedDevice})
            return
        }

        setTimeout(() => {updateSelectedProfile()}, 2000)
    }
    
    const onUnauthorized = () => {
        history.push('/login')
    }
    
    useEffect(() => {

        header.hidden.set(false)
        header.navigation.set(true)
        header.title.set('Dispositivos')

        fetchProfiles(backend, setProfiles, setLoading)

        setUsername(localStorage.getItem('username'))

        backend.setOnUnauthorized(onUnauthorized)

        // eslint-disable-next-line 
    }, [])

    const getDevice = (deviceid) => {
        for(let device of devices) {
            if(device.deviceid === deviceid){
                return device
            }
        }
    }

    const submitDeviceUpdate = async(device) => {

        let oldDevice = getDevice(device.deviceid)

        if(deviceNeedsUpdate(oldDevice, device)) {
            await backend.update(`devices/${device.deviceid}`, device)
            setSelectedDevice({...device, profile: {...device.profile}})
            addToast(`Salvo`, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 1500,
                })
        }

        if(deviceProfileNeedsUpdate(oldDevice, device)){

            let newDevice = {...device, profile: {...device.profile}}
            newDevice.profile = getProfileInfo(device.profile.profileid)
            newDevice.profile.status = profileStatus.ASSOCIATING

            setSelectedDevice({...newDevice})
            await backend.create(`devices/${newDevice.deviceid}/profile`, newDevice.profile)

            setTimeout(() => {updateSelectedProfile()}, 2000)
        }

        fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, filterQueryString, sortQueryString)

    }

    const sendAssociateRequest = async(data) => {

        let result = await backend.create('devices/associate_request', data)
        let response = result.content

        if(response.success.length > 0){
            addToast(response.success.length > 1 ? 'Foram associados ' + response.success.length + ' dispositivos ao seu perfil.' :
                'Foi associado ' + response.success.length + ' dispositivo ao seu perfil.', { 
                    appearance: 'success',
                    autoDismiss: true,
                    pauseOnHover: true, }
            )
        }

        response.error.forEach(device => {
            addToast(device.deviceid + ': ' + device.error, { 
                appearance: 'error',
                autoDismiss: false, }
            )
        })

    }

    const findDeviceTimeout = async() => {

        setbuttonLoading(false)

        addToast('Nenhum dispositivo foi encontrado em sua rede.', { 
            appearance: 'error',
            autoDismiss: true,
            pauseOnHover: true, }
        )
    }

    const findDevices = async() => {
        const { ipcRenderer } = window.require('electron');

        let data = {
            isp: localStorage.getItem('user_id')
        }

        let findTimeout = setTimeout(findDeviceTimeout, FIND_TIMEOUT * 1000)

        setbuttonLoading(true)

        ipcRenderer.send('devices-request', data)

        ipcRenderer.once('devices-response', async(event, arg) => {

            clearTimeout(findTimeout)

            if(arg.devices.length > 0) {
                addToast(arg.devices.length > 1 ? 'Foram encontrados ' + arg.devices.length + ' dispositivos em sua rede.' : 
                'Foi encontrado ' + arg.devices.length + ' dispositivo em sua rede.', { 
                    appearance: 'info',
                    autoDismiss: true,
                    pauseOnHover: true, }
                )
                await sendAssociateRequest({devices: arg.devices, password: arg.password})
                fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, filterQueryString, sortQueryString)
            } else {
                addToast('Nenhum dispositivo foi encontrado em sua rede.', { 
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: true, }
                )
            }

            setbuttonLoading(false)
            
        });
    }

    const setSort = (key, state) => {

        let sort = ""

        if(state !== ListOrderStates.NONE) {
            sort += `sort-${key}=${getOrderValue(state)}`
        }

        setSortQueryString(sort)
    }

    const getOrderValue = (state) => {
        switch(state) {
            case ListOrderStates.UP:
                return 1
            case ListOrderStates.DOWN:
                return -1
            default:
                return 0
        }
    }

    const getColumns = () => {

        let columns = [
            {header:'Dispositivo', size: '180px', orderable: true, orderFn:(state) => setSort('deviceid', state)},
            // {header: 'Apelido', size: '150px', orderable: true, orderFn:(state) => setSort('alias', state)},
            {header: 'Modelo', size: '150px', orderable: true, orderFn:(state) => setSort('model', state)},
            {header: 'Cliente', orderable: true, orderFn:(state) => setSort('clientid', state)}
        ]

        if(username === 'remotizeadm')
            columns.push({header:'Provedor'})
        else
            columns.push({header:'Perfil', size: '280px', orderable: true, orderFn:(state) => setSort('profile.name', state)})
            
        columns.push(
            {header:'Versão', size: '140px', orderable: true, orderFn:(state) => setSort('fw_version', state)},
            {header:'Status', size: '110px', orderable:true, orderFn:(state) => setSort('status', state)})

        return columns
    }

    const getProfileInfo = (id) => {
        for(let profile of profiles) {
            if(profile.id === id)
                return {
                    profileid: profile.id,
                    name: profile.name
                }
        }
    }

    const getDeviceStatus = ({deviceid, status, assoc_status, fw_version, model}) => {

        if(!assoc_status) status = deviceStatus.ERROR

        switch(status){
            case deviceStatus.CONFIRMING:
                return <span className='device-status'><Loading show={true} mini></Loading></span>
            case deviceStatus.WAITING:
                return <span className='device-status'><div className='device-status-icon waiting'></div> Associando </span>
            case deviceStatus.OFFLINE:
                return <span className='device-status'><div className='device-status-icon offline'></div> Offline</span>
            case deviceStatus.ONLINE:

                return <span className='device-status'>
                    <div className='device-status-icon online'></div>
                    Online
		    {canBeManaged({model, fw_version, assoc_status}) && !DeviceFactory.isGateway(model)?
                        <div className='device-status-info' onClick={() => history.push(`/manage/${deviceid}/wan`)}>
                            <SearchIcon size='18'></SearchIcon>
                        </div>
                        :
                        <InfoPopup message='Versão de firmware não gerenciável'></InfoPopup>}
                    {/*{canBeManaged({model, fw_version, assoc_status}) && !DeviceFactory.isGateway(model)?
                        <div className='device-status-info' onClick={() => history.push(`/manage/${deviceid}/wan`)}>
                            <SearchIcon size='18'></SearchIcon>
                        </div>
                        :null}
                    {canBeManaged({model, fw_version, assoc_status}) && DeviceFactory.isGateway(model)?
                        <div className='device-status-info' onClick={() => history.push(`/manage/${deviceid}/gateway/info`)}>
                            <SearchIcon size='18'></SearchIcon>
                        </div>
                        :null}
                    {!canBeManaged({model, fw_version, assoc_status}) &&   
                    <InfoPopup message='Versão de firmware não gerenciável'></InfoPopup>} Liberar apenas quando página de gateway estiver pronta*/}
                </span>
            case deviceStatus.UPDATING:
                return <span className='device-status'><div className='device-status-icon updating'></div> Atualizando </span>
            case deviceStatus.ERROR:
                return <span className='device-status'><div className='device-status-icon error'></div> Não associado </span>
            default:
                return ''
        }
    }

    const hasMoreThanOnePage = () => {
        return (pagerInfo.totalElements / pagerInfo.pageSize) > 1
    }

    const handleManualAssociationReturn = (deviceList) => {

        deviceList.forEach(({device, status, error}) => {
            if(!status){
                addToast(`${device}: ${error}`, {
                    appearance: 'error',
                    autoDismiss: false
                })
            }
        })
    }

    const submitAssociation = async(associate) => {
        let result = await backend.create('devices', associate)

        if(result.status === backendStatus.SUCCESS) {
            fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, filterQueryString, sortQueryString)
            handleManualAssociationReturn(result.content)
        }

    }

    const fillDevice = (device) => {
        device.model = device.model === '' ? 'Confirmando' : device.model
        device.fw_version = device.fw_version === '' ? 'Confirmando' : device.fw_version
        device.profile.name = device.profile.name === '' ? 'Intelbras' : device.profile.name
    }

    const deviceRemoved = () => {
        setInfoOpen(false)
        fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, filterQueryString, sortQueryString)
    }

    const getProfileName = (device) => {
        return <span className='device-list-profile'>
            {DeviceFactory.isTR069(device.model)?
            <span style={{display:'flex',marginLeft:'5px'}}>-------<span style={{marginLeft:'5px'}} className='info-popup'><TooltipText width={'350px'} message={'Não aplica perfil, utiliza customização de firmware'} /><HelpIcon size={13}></HelpIcon></span></span>:
            device.profile.name}

            {profileIsApplying(device) && <Loading show={true} mini></Loading>}
        </span>
    }


    return <div id='devices-page' className='page with-pager'>

        {loading ? <div className='loading'></div> :

            <div className='container scroll-area'>

                <div id='devices-page-toolbar'>

                    <div className="discovery">
                    {isElectron() ?
                        <Button
                            title={'Procurar dispositivos em sua rede'}
                            id='btn-discovery'
                            text={'Procurar dispositivos'}
                            loading={buttonLoading ? 1 : 0}
                            onClick={findDevices}
                         ></Button>
                        :
                        <DeviceAsssociator onSubmit={submitAssociation}></DeviceAsssociator> }
                    </div>

                    <MultiFieldSearch
                        onSearch={ (queryString) => {
                            setSearched(true)
                            setFilterQueryString(queryString)
                            fetchDevices(history, backend, setDevices, setLoading, pagerInfo, setPagerInfo, queryString, sortQueryString)
                        }}
                    ></MultiFieldSearch>

                </div>


                {pagerInfo.hasGateways ? <Tabs
                    route = {history.location.pathname}
                    activateFn = {(route) => history.push(route)}
                    data={[
                        {label:"Roteadores", route:"0"},
                        {label:"Gateways", route:"1"}
                    ]}
                ></Tabs> : null}

                {devices.length === 0 || <List
                    breakWidth={900}
                    columns={getColumns()}
                    lines={devices
                        .map(device => {

                            fillDevice(device)

                            let values = [device.deviceid, device.model, device.clientid || 'ND']

                            if(username === 'remotizeadm')
                                values.push(device.isp)
                            else
                                values.push(getProfileName(device))
                            values.push(device.fw_version, getDeviceStatus(device))

                            return values
                        })}
                        onClick={(deviceLine) => {
                            selectDevice(deviceLine[0])
                            setInfoOpen(true)
                        }}
                ></List>}

                {hasMoreThanOnePage() && <div className='pager-container'>
                    <Pager
                        pageSize={pagerInfo.pageSize}
                        pageNumber={pagerInfo.pageNumber}
                        totalElements={pagerInfo.totalElements}
                        pageChangeFn={(pageNumber) => {
                            let newPagerInfo = {...pagerInfo, pageNumber: pageNumber}
                            setPagerInfo(newPagerInfo)
                            fetchDevices(history, backend, setDevices, setLoading, newPagerInfo, setPagerInfo, filterQueryString, sortQueryString)
                        }}
                    ></Pager>
                </div>}

                {!isElectron() && devices.length === 0 && !searched ? <DownloadApps
                    infoText='Você ainda não possui dispositivos associados à sua conta.
                    Para associar novos dispositivos manualmente, clique em "Associar dispositivos" acima.
                    Para associação automática baixe um de nossos aplicativos para desktop e instale em seu computador.'
                ></DownloadApps> : null}

                {isElectron() && devices.length === 0 ? <DiagramRemotize
                    infoText="Para associar dispositivos à sua conta você precisa conectar os equipamentos de rede conforme o
                    diagrama abaixo. Os roteadores deverão estar conectados via porta INTERNET a um switch com link DHCP e acesso à
                    internet, para que possam se comunicar com o servidor Remotize."
                ></DiagramRemotize> : null}

                {(searched && devices.length === 0) && <div>Nenhum dispositivo encontrado.</div>}

                {
                    infoOpen ? <DeviceDetails
                        id='device-details'
                        device={{...selectedDevice, profile: {...selectedDevice.profile}}}
                        setDevice={setSelectedDevice}
                        onOverlayClick={() => {
                            setInfoOpen(false)
                        }}
                        onProfileChanged={(e) => {
                            selectedDevice.profile = getProfileInfo(e.target.value)
                            setSelectedDevice({...selectedDevice})
                        }}
                        profiles={profiles}
                        onSubmit={submitDeviceUpdate}
                        onDeviceRemoved={deviceRemoved}
                    ></DeviceDetails> : null
                }

                <DeviceTour/>
            </div>
        }
        
    </div>
}

export default DevicesPage