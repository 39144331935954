import React from 'react'
import { Switch, Route } from 'react-router'
import SiteSurvey from './site-survey'
import MonitoringWifiData from './wifi-data'

export default function WifiPage() {

    return(
    <div id='wifi-page'>
        <Switch>
            <Route path='/manage/:id/wifi/site-survey' component={SiteSurvey}></Route>
            <Route path='/manage/:id/wifi' component={MonitoringWifiData}></Route>
        </Switch>
    </div>
    )
}