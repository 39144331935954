import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from './user-context'



const eventsAddress = process.env.REACT_APP_EVENTS_ADDRESS || `wss://${window.location.hostname}`

export const UIEventsContext = React.createContext()

export default function UIEvents({children}) {

    const [handlers, setHandlers] = useState({})
    const [socket, setSocket] = useState(null)

    const user = useContext(UserContext)[0]

    useEffect(() => {

        if(!user) return

        if(!socket) {
            connect()
            return
        }

        // eslint-disable-next-line
    }, [user, socket])

    const connect = async() => {

        let websocket = new WebSocket(`${eventsAddress}/fwapi/uievents/${user.username}`)
        websocket.onmessage = handleMessage
        websocket.onclose = handleClosed
        setSocket(websocket)
    }

    const handleClosed = () => {

        setTimeout(() => setSocket(null), 5000)
    }

    const handleMessage = (event) => {

        for(let eventName in handlers){
            if(eventName === event.data)
                handlers[eventName]()
        }
    }

    const setHandler = (event, handler) => {

        handlers[event] = handler
        setHandlers({...handlers})

    }

    return <UIEventsContext.Provider value={{setHandler:setHandler}}>{children}</UIEventsContext.Provider>
}