import React, { useContext, useEffect } from 'react'

import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import { useHistory } from 'react-router-dom'

import RemotizeLogo from '../../../images/remotize-green.png'
import GoogleLogo from '../../../images/g-logo.png'

import './login.css'
import { MenuContext } from '../../../components/menu/menu-state'

import firebase from 'firebase/app'
import HeaderBig from '../../../components/headerbig/headerbig'

const LoginPage = () => {

    let header = useContext(MainHeaderContext)

    let history = useHistory()
    let menu = useContext(MenuContext)

    useEffect(() => {

        header.hidden.set(true)
        menu.open.set(false)
        menu.fixed.set(false)

        // eslint-disable-next-line
    }, [])

    const authWithGoogle = async() => {

        let provider = new firebase.auth.GoogleAuthProvider()

        provider.addScope('profile')
        provider.addScope('email')

        await firebase.auth().signInWithPopup(provider)

        history.push('/')

    }

    return(
        <div className='login-page'>

            <HeaderBig></HeaderBig>

            <div id='login-remotize-logo'>
                <img alt='remotize' width='400' src={RemotizeLogo}></img>
            </div>

            <div id='login-form-container'>

                <div className='login-welcome'>Bem vindo ao <b>Intelbras Remotize</b></div>

                <div className='login-content'>

                    <span>Entrar com:</span>

                    <div className='google-button' onClick={authWithGoogle}>
                        <img alt='google' src={GoogleLogo}></img>
                        <span>Google</span>
                    </div>

                </div>
            </div>

            <div id='login-faq'>Dúvidas? Consulte nosso <a href='/#/faq' target='_blank'>FAQ</a> e
            o nosso <a href='https://remotize.intelbras.com.br/manual' target='_blank' rel='noreferrer'>
                Manual online
            </a>
            </div>

        </div>
    )
}

export default LoginPage