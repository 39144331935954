import React, {useEffect, useContext, useState } from 'react'
import {XYPlot,YAxis, XAxis, VerticalBarSeries} from 'react-vis';
import { BackendContext } from '../../../../../../backend/backend'
import Loading from '../../../../../../components/loading/loading'
import DeviceFactory from '../../../../../apis/device-factory'


import './devices.css'

const colorPalette = ['#103543', '#6893A7', '#B8D4E1','#5ec0bc','#6CCFF6',"red" ]

export default function BarGraphic (){
    const [stats, setStats] = useState(null)
    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchDevices()
    
        // eslint-disable-next-line
    }, [])

    const getRandomColor = () => {
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

    const getVersionChartColor = (index) => {
      if(index >= colorPalette.length){
          return getRandomColor()
      }

      return colorPalette[index]
  }

    const fetchDevices = async() => {

      let result = await backend.retrieveFresh("/devices/brief")

      var models =result.content.models
      var total =result.content.total-1
      
      models.map((obj,i)=>{
        if(DeviceFactory.isGateway(obj.model)){
          models.splice(i,1)
        }else if(!DeviceFactory.isTR069(obj.model)){
          models.splice(i,1)
        }
        return models
      })
      models = models.map((obj,i) => ({...obj, model: obj.model ,color: getVersionChartColor(i)}))

      setStats({total: total, models: models})
  }
    return( stats ? <div id="bar-stats">

        <div className='update-info-container'>
          <div className='model-devices-chart-bar'>
            <XYPlot
              xType="ordinal"
              yType="linear"
              width={500}
              height={300}
              colorType={"literal"}
              stackBy='y'>
              <XAxis/>
              <YAxis/>
              {stats.models.map(element =>{
                return (
                  <VerticalBarSeries barWidth={0.4} data={[{x:`${element.model}`,y:`${element.total}`, color:`${element.color}`}]}/>
                )
              })}
              </XYPlot>
            </div>

        </div>

    </div> : <Loading show={true}></Loading>)
}