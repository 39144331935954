import React, { useState, useEffect, useContext } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import Loading from '../../../../../components/loading/loading'
import { BackendContext, backendStatus } from '../../../../../backend/backend'
import { MainHeaderContext } from '../../../../../components/main-header/main-header-state'

import MonitoringWanData from './wan/tr069-wan-data'
import Tabs from '../../../../../components/tabs/tabs'
import LanManagementPage from './lan/lan'
import Tr143ManagementPage from './tr143/tr143'
import SipManagementPage from './sip/sip'
import SipManagementPageFH from './sip/sip-fh'
import MaintenancePage from '../bifrost/maintenance/maintenance'
import DeviceFactory from '../../../../apis/device-factory'
import WifiPage from '../tr069/wifi/wifi-page'
import CustomizeManagementPage from './customize/customize'
import InMeshManagementPage from './inmesh/inmesh'
import { canBeManaged } from '../../devices-page'
import TR069WanConfiguration from './wan/tr069-wan-configuration'

export const DEVICE_STATUS_OFFLINE = 1

export function  getTraffic(value, unitBytes, time) {

    const BYTE_SIZE = 8
    const MEGABIT_SIZE = 1000000

    if(time === 0) return 0

    return ((value * unitBytes * BYTE_SIZE) / time) / MEGABIT_SIZE
}

export const DeviceContext = React.createContext(null)

export default function TR069ManagePage() {

    const [device, setDevice] = useState(null)

    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)
    const params = useParams()
    const history = useHistory()

    const deviceid = params.id

    const fetchDeviceInfo = async() => {

        let result = await backend.retrieveFresh(`devices/${deviceid}`)

        if(result.status === backendStatus.SUCCESS)
            setDevice(result.content[0])
    }

    useEffect(() => {

        fetchDeviceInfo()

        let deviceInterval = setInterval(fetchDeviceInfo, 5000)

        return () => clearInterval(deviceInterval)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!device) return

        header.title.set(`${device.model} - ${device.deviceid}`)

        // eslint-disable-next-line
    }, [device])

    const getDataLines = () => {
        let lines=[
            {label: 'WAN', route:`/manage/${deviceid}/wan`},
            {label: 'Wi-Fi', route:`/manage/${deviceid}/wifi`},
            {label: 'LAN', route:`/manage/${deviceid}/lan`},
            {label: 'Manutenção', route:`/manage/${deviceid}/maintenance`},

        ]
        if(DeviceFactory.isTr143(device.model)){
            lines.splice(3,0,{label: 'T. Velocidade', route:`/manage/${deviceid}/tr143`},)
        }
        if(!DeviceFactory.isFiberhomeModels(device.model)){
            lines.splice(2,0,{label: 'InMesh', route:`/manage/${deviceid}/inmesh`},)
            lines.splice(4,0,{label: 'Customize', route:`/manage/${deviceid}/customize`},)
        }                    
        if(device.model==="WiFiber 121 AC" || device.model==="WiFiber AX 1800V"|| device.model==="WiFiber AX 3000V"){
            lines.splice(3,0,{label: 'SIP', route:`/manage/${deviceid}/sip`},)
        }
        if(device.model==="Fiberhome HG6145D2" || device.model==="Fiberhome HG6145F"|| device.model==="Fiberhome HG6145F3"){
            lines.splice(3,0,{label: 'SIP', route:`/manage/${deviceid}/sip-fh`},)
        }
                
        if(device.model==="ONU R1"){
            lines.splice(1,1)
            /*lines.aplice(2,2) relacionada ao inMesh que deve ser uma opção premium*/
        }
        if (device.model === "ONT 121 W"){
            lines.splice(1,1)
        }

        return lines
    }




    if(!device || !canBeManaged(device))
        return <Loading show={true}></Loading>

    return (
        <div id="device-management-page">

            {!DeviceFactory.isTR069(device.model) && device.status === DEVICE_STATUS_OFFLINE ?

            <div className='offline-info'>
                <Loading show={true}></Loading>
                <span>O dispositivo está offline. Aguardando o retorno.</span>
            </div>

            : <React.Fragment>

            <Tabs
                route={history.location.pathname}
                data={getDataLines()}
                
                activateFn={(route) => history.push(route)}
            ></Tabs>

            <Switch>
                <Route path='/manage/:id/wan'>
                    <MonitoringWanData WanConfigurationComponent={TR069WanConfiguration}></MonitoringWanData>
                </Route>
                <Route path='/manage/:id/wifi' component={WifiPage}></Route>
                <Route path='/manage/:id/inmesh' component={InMeshManagementPage}></Route>
                <Route path='/manage/:id/lan' component={LanManagementPage}></Route>
                <Route path='/manage/:id/tr143' component={Tr143ManagementPage}></Route>
                <Route path='/manage/:id/customize' component={CustomizeManagementPage}></Route>
                <Route path='/manage/:id/sip' component={SipManagementPage}></Route>
                <Route path='/manage/:id/sip-fh' component={SipManagementPageFH}></Route>
                <Route path='/manage/:id/maintenance' component={MaintenancePage}></Route>
            </Switch>

            </React.Fragment>}



        </div>
    )
}