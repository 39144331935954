import React from 'react'

export default function ChevronRightIcon({size}){

    return(
        <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox={`0 0 8 16`}>
            <g>
            <path d="M1.196 14.314a.485.485 0 0 1-.349-.145.53.53 0 0 1-.021-.735c1.854-1.961 4.717-5.026 5.57-5.996-.85-.961-3.716-4.031-5.57-5.994A.532.532 0 0 1 .843.707a.498.498 0 0 1 .714.014C7.562 7.104 7.562 7.17 7.562 7.445c0 .277 0 .336-6 6.71a.504.504 0 0 1-.366.16"/>
            </g>
        </svg>
    )
}