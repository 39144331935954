import React from 'react'
import Input from '../../../components/input/input';
import common from '../../../components/form/validators/common'
import zxcvbn from 'zxcvbn';


const verifyPasswordStrength =  async(value) => {

    const strength = zxcvbn(value).score
    if(strength< 3){
        return '.'
    }
    return ''
}

export default function ProfileRouterAccess({routerAccess, setRouterAccess}) {

    return [

        <Input id='router-user'
            label='Usuário'
            value={routerAccess.username}
            name='router-user'
            onChange={(e) => setRouterAccess({...routerAccess, username: e.target.value})}
            validators={[common.required, common.nonASCII, {fn: common.size, params:{min: 1, max: 32}}]}
            key='router-1'
        ></Input>,

        <Input id='router-pass'
            label='Senha'
            value={routerAccess.password}
            name='router-pass'
            type='password'
            onChange={(e) => setRouterAccess({...routerAccess, password: e.target.value})}
            validators={[common.required, verifyPasswordStrength, {fn: common.size, params:{min: 1, max: 32}}]}
            key='router-12'
            passwordMeter={true}
            strongPasswordRequired={true}
        ></Input>

    ]
}