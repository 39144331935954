import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BackendContext, backendStatus } from '../../../backend/backend'
import RemoveIcon from '../../../components/icons/remove'
import RoundedForbiddenIcon from '../../../components/icons/rounded-forbidden'
import List from '../../../components/list/list'
import Loading from '../../../components/loading/loading'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import Modal, { ModalTypes } from '../../../components/modal/modal'
import Select from '../../../components/select/select'
import { UserContext } from '../../user-context'
import { RemotizeType } from '../signup/signup'

import './accounts.css'

const accountTypeOptions = [
    {value: 0, text: 'Bloqueado'},
    {value: 1, text: 'Administrador'},
    {value: 2, text: 'Técnico'},
]

export default function AccountsPage() {

    const [users, setUsers] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [deletingUser, setDeletingUser] = useState({name:''})
    const [error, setError] = useState(false)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const mainUser = useContext(UserContext)[0]
    const history = useHistory()

    useEffect(() => {

        header.title.set('Contas')

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(mainUser.type !== RemotizeType.ADMIN){
            history.push('/')
            return
        }

        if(mainUser.ispid && mainUser.ispid !== ''){
            fetchUsers()
        }

        // eslint-disable-next-line
    }, [mainUser])

    const fetchUsers = async() => {

        let resultUser = await backend.retrieveFresh(`users/${mainUser.id}`)
        if(resultUser.status === backendStatus.SUCCESS){
            mainUser.ispid = resultUser.content.ispid
        }

        let result = await backend.retrieveFresh(`isp/${mainUser.ispid}/users`)
        if(result.status === backendStatus.SUCCESS){
            setUsers(result.content)
        }
    }

    const updateUserPermission = async(user, value) => {

        user.type = Number(value)

        let result = await backend.update(`users/${user.id}`, user)
        if(result.status === backendStatus.SUCCESS){
            fetchUsers()
            return
        }

    }

    const confirmAccountDelete = (user) => {

        setDeletingUser(user)
        setConfirm(true)
    }

    const dismissDelete = async(confirmed) => {
        if(confirmed){
            let result = await backend.delete(`users/${deletingUser.username}`)
            if(result.status !== backendStatus.SUCCESS){
                setError(true)
            }
        }

        setConfirm(false)
        fetchUsers()
    }

    if(!users)
        return <Loading show={true}></Loading>

    return <div id='accounts-page' className='page'>

        <Modal id='confirm-delete'
            show={confirm && deletingUser}
            type={ModalTypes.CONFIRM_WARNING}
            content={
                <div id='confirm-delete-modal'>
                    <RoundedForbiddenIcon></RoundedForbiddenIcon>
                    O usuário <b>{deletingUser.name}</b> será removido do sistema.
                    Assim, ele não terá mais acesso aos recursos do Remotize gerenciados pelo seu provedor.
                    <br></br>
                    <br></br>
                    <b>Tem certeza que deseja remover o usuário {deletingUser.name}?</b>
                    <br></br>
                </div>
            }
            confirmText='Sim, quero remover'
            dismissText='Não, quero manter'
            onDismissClick={dismissDelete}
        ></Modal>

        <Modal id='delete error'
            show={error}
            type={ModalTypes.ERROR}
            content='Houve um erro ao remover o usuário. Tente novamente mais tarde.'
            dismissText='Entendi'
            onDismissClick={() => setError(false)}
        ></Modal>

        <List
            width='850px'
            columns={[
                {header: 'Nome'},
                {header: 'Email'},
                {header: 'Nível de acesso', size: '185px'},
                {header: 'Remover', size: '100px', align: 'center'}
            ]}
            lines={
                users.map(user => {
                    return [
                        user.name,
                        user.email,
                        user.id === mainUser.id ? accountTypeOptions[user.type].text : <Select value={user.type}
                            options={accountTypeOptions}
                            onChange={e => updateUserPermission(user, e.target.value)}
                        ></Select>,
                        mainUser.id !== user.id ? <div className='accounts-delete' onClick={() => confirmAccountDelete(user)}>
                            <RemoveIcon size="24"></RemoveIcon>
                        </div> : ''
                    ]
                })
            }
        >
        </List>

        {users.length === 1 ?
            <div id='single-user-info'>
                <div className='subtitle'>
                    Adicione novos usuários à sua conta
                </div>

                Você ainda não possui outros usuários associados à sua conta. Para adicionar novos usuários,
                basta informar o seu CNPJ durante o primeiro login com uma nova conta.

            </div>
        : null}
    </div>
}