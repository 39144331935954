import React, {useState, useEffect, useContext} from 'react'

import List from '../../../components/list/list'
import Input from '../../../components/input/input'
import CheckIcon from '../../../components/icons/check-icon'
import { BackendContext } from '../../../backend/backend'
import Loading from '../../../components/loading/loading'
import Overlay from '../../../components/overlay/overlay'
import CloseIcon from '../../../components/icons/close'

export default function UpdateDeviceSelector({tour}) {

    const [devices, setDevices] = useState([])
    const [loadingDevices, setLoadingDevices] = useState(true)
    const [filteredDevices, setFilteredDevices] = useState([])
    const [deviceFilter, setDeviceFilter] = useState("")
    const [selectorOpen, setSelectorOpen] = useState(false)
    const [selectDevicesLoading, setSelectDevicesLoading] = useState(false)

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchDevices()
        
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        fetchDevices()

        // eslint-disable-next-line
    }, [tour])

    const fetchDevices = async() => {
        setLoadingDevices(true)
        let result = await backend.retrieveFresh('devices?controlledUpdate=y')
        setDevices(!tour ? result.content : require('./devices_mock.json'))
        setLoadingDevices(false)
    }

    const fetchFilteredDevices = async(filter) => {

        if(filter === ''){
            setFilteredDevices([])
            return
        }

        let setLoadingTimer = setTimeout(() => {setSelectDevicesLoading(true)}, 200)

        let result = await backend.retrieve(`devices/${filter}?partial=y`)
        setFilteredDevices(result.content)
        setSelectDevicesLoading(false)
        clearTimeout(setLoadingTimer)
    }

    const getLines = () => {
        return devices.map((device) => {
            return [device.model, device.deviceid, <div className='update-del-icon' onClick={() => {
                device.controlledUpdate = true
                selectDeviceToUpdate(device)
            }}>
                <CloseIcon size={12}></CloseIcon>
            </div>]
        })
    }

    const selectDeviceToUpdate = async(device) => {

        device['loading'] = true
        setFilteredDevices([...filteredDevices])

        device.controlledUpdate = !device.controlledUpdate

        backend.flushCache()

        await backend.update(`devices/${device.deviceid}`, device)

        fetchFilteredDevices(deviceFilter)
        fetchDevices()

        delete device['loading']
        setFilteredDevices([...filteredDevices])
    }

    const getFilteredDeviceElement = (device) => {

        return <div className='update-device-item' key={device.deviceid} onClick={() => selectDeviceToUpdate(device)}>

            {
                <div className='update-device-item-selected-icon'>
                    { device.loading ? <Loading mini={true} show={true}></Loading> : (device.controlledUpdate ?<CheckIcon size='20'></CheckIcon> : null) }
                </div>
            }

            <div className='update-device-item-model'>{device.model}</div>
            <div className='update-device-item-id'>{device.deviceid}</div>
        </div>
    }

    return <div className='autoupdate-device-list'>

        <div id='add-update-device'
            onClick={() => setSelectorOpen(true)}
        >adicionar dispositivos</div>

        <Overlay open={selectorOpen} onClick={() => setSelectorOpen(false)}></Overlay>

        <div id='update-device-selector' className={selectorOpen ? 'open' : ''}>

            <div className='close-icon' onClick={() => setSelectorOpen(false)}>
                <CloseIcon size='13'></CloseIcon>
            </div>

            <Input
                label='Buscar dispositivo'
                value={deviceFilter}
                onChange={(e) => {
                    setDeviceFilter(e.target.value)
                    fetchFilteredDevices(e.target.value)
                }}
            ></Input>

           {filteredDevices.length > 0 ? <div className='info-text'>
                Clique sobre os dispositivos para selecionar ou remover a seleção
            </div> : null}

            {
                filteredDevices.length === 0 ? <div className='info-text'>
                    Digite o endereço MAC dos dispositivos para buscar
                </div>:

                selectDevicesLoading ? <Loading show={true}></Loading> :

                filteredDevices.map((device) => {
                    return getFilteredDeviceElement(device)
                })
            }


        </div>

        <div className='update-device-search-list' id='update-device-search-list'>

            {loadingDevices ? <Loading show={true}></Loading> :

            devices.length === 0 ? <div className='info-text'>Você não possui dispositivos de teste selecionados para a atualização controlada</div> :
            <List
                columns={[
                    {header:'Modelo'},
                    {header:'Dispositivo'},
                    {header:'Remover', size:'90px'},
                ]}
                lines={getLines()}
            ></List>
            }
        </div>
    </div>
}