import React from 'react'

import ChevronRightIcon from '../icons/chevron-right'
import ChevronLeftIcon from '../icons/chevron-left'

import './pager.css'

export default function Pager({pageSize, pageNumber, totalElements, pageChangeFn, pageIndicator = 'Página', showTotalPages = false, showSelector = false}) {

    const clickNext = () => {

        if(!renderChanger(false))
            return

        pageChangeFn(pageNumber + 1)
    }

    const clickPrevious = () => {

        if(!renderChanger(true))
            return

        pageChangeFn(pageNumber - 1)
    }

    const renderChanger = (isPrevious) => {
        return (isPrevious && pageNumber > 1) ||
        (!isPrevious && pageNumber < (totalElements / pageSize))
    }

    const PageSelector = ({currentPage}) => {
        let pages = []
        let total = Math.ceil(totalElements/pageSize)

        for(let i = 1; i <= total; i++) {
            pages.push(i)
        }
        return <select
                disabled={total === 1}
                onChange={e => pageChangeFn(Number(e.currentTarget.value))}
                value={currentPage}>
            {pages.map(page => <option key={page} value={page}>{page}</option>)}
        </select>
    }

    return <div className='pager'>

        <div className='pager-changer'
            onClick={clickPrevious}
        >
            {renderChanger(true) && <ChevronLeftIcon size={20}></ChevronLeftIcon>}
        </div>

        <div className='pager-page-number'>
            {pageIndicator} {showSelector ?  <PageSelector currentPage={pageNumber}></PageSelector> : pageNumber} {` ${showTotalPages ? `\\ ${Math.ceil(totalElements/pageSize)}` : ''}`}
        </div>

        <div className='pager-changer next'
            onClick={clickNext}
        >
            {renderChanger(false) && <ChevronRightIcon size={24}></ChevronRightIcon>}
        </div>
    </div>
}