import React, { useState, useEffect, useContext } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router-dom'
import Loading from '../../../../../components/loading/loading'
import { BackendContext, backendStatus } from '../../../../../backend/backend'
import { MainHeaderContext } from '../../../../../components/main-header/main-header-state'

import MonitoringWanData from './wan/wan-data'
import Tabs from '../../../../../components/tabs/tabs'
import LanManagementPage from './lan/lan'
import MaintenancePage from './maintenance/maintenance'
import DeviceFactory from '../../../../apis/device-factory'
import WifiPage from './wifi/wifi-page'
import { canBeManaged } from '../../devices-page'
import WanConfiguration from './wan/wan-configuration'

export const DEVICE_STATUS_OFFLINE = 1

export function  getTraffic(value, unitBytes, time) {

    const BYTE_SIZE = 8
    const MEGABIT_SIZE = 1000000

    if(time === 0) return 0

    return ((value * unitBytes * BYTE_SIZE) / time) / MEGABIT_SIZE
}

export function removeStrings(input, substringsToRemove) {
    let result = input;

    for (let i = 0; i < substringsToRemove.length; i++) {
        result = result.replace(new RegExp(substringsToRemove[i], 'g'), "");
    }

    return result;
}

export function isMinimalVersion(device, baseVersion) {//baseVersion is the minimum version that the device needs to have
    let versionString = device.fw_version.split(".");
    let major = parseInt(versionString[0]);

    if (isNaN(major)) {
        return false;
    }

    let minor = parseInt(versionString[1]);
    if (isNaN(minor)) {
        return false;
    }

    // firmware = 1.11.'1'-test1.63679 or bifrost-1.11.'1' or 1.11.'1'
    let charsToRemove = ["-dev", "-test1", "-test2"];
    let patch = parseInt(removeStrings(versionString[2], charsToRemove));
    if (isNaN(patch)) {
        return false;
    }

    if (major < baseVersion[0] || (major == 1 && minor < baseVersion[1]) || (major == baseVersion[0] && minor == baseVersion[1] && patch < baseVersion[2])) {
        return false;
    }
    return true
}

export const DeviceContext = React.createContext(null)

export default function BifrostManagePage() {

    const [device, setDevice] = useState(null)

    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)
    const params = useParams()
    const history = useHistory()

    const deviceid = params.id

    const fetchDeviceInfo = async() => {

        let result = await backend.retrieveFresh(`devices/${deviceid}`)

        if(result.status === backendStatus.SUCCESS)
            setDevice(result.content[0])
    }

    useEffect(() => {

        fetchDeviceInfo()

        let deviceInterval = setInterval(fetchDeviceInfo, 5000)

        return () => clearInterval(deviceInterval)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!device) return

        header.title.set(`${device.model} - ${device.deviceid}`)

        // eslint-disable-next-line
    }, [device])

    if(!device || !canBeManaged(device))
        return <Loading show={true}></Loading>

    return (
        <div id="device-management-page">

            {!DeviceFactory.isTR069(device.model) && device.status === DEVICE_STATUS_OFFLINE ?

            <div className='offline-info'>
                <Loading show={true}></Loading>
                <span>O dispositivo está offline. Aguardando o retorno.</span>
            </div>

            : <React.Fragment>

            <Tabs
                route={history.location.pathname}
                data={[
                    {label: 'WAN', route:`/manage/${deviceid}/wan`},
                    {label: 'Wi-Fi', route:`/manage/${deviceid}/wifi`},
                    {label: 'LAN', route:`/manage/${deviceid}/lan`},
                    {label: 'Manutenção', route:`/manage/${deviceid}/maintenance`}
                ]}
                activateFn={(route) => history.push(route)}
            ></Tabs>

            <Switch>
                <Route path='/manage/:id/wan'>
                    <MonitoringWanData WanConfigurationComponent={WanConfiguration}></MonitoringWanData>
                </Route>
                <Route path='/manage/:id/wifi' component={WifiPage}></Route>
                <Route path='/manage/:id/lan' component={LanManagementPage}></Route>
                <Route path='/manage/:id/maintenance' component={MaintenancePage}></Route>
            </Switch>

            </React.Fragment>}

        </div>
    )
}