import React, { useState, useContext, useEffect, useRef } from 'react'
import { backendStatus } from '../../../../../../backend/backend'
import WanStatistics from './wan-statistics'
import Loading from '../../../../../../components/loading/loading'
import InfoCard from '../../../../../../components/infocard/infocard'
import { getWanModeString } from '../../../../profiles/profile-viewer'
import NetworksIcon from '../../../../../../components/icons/networks-icon'
import PonIcon from '../../../../../../components/icons/pon-icon'
import { DeviceContext } from '../../manage-page'
import { DefaultPPPoE } from './pppoe-constants'
import { DefaultPonStatus } from './wan-constants'
import DeviceFactory from '../../../../../apis/device-factory'

import './wan.css'
import { DefaultInterface } from './wan-constants'

const RETRY_TIME = 2000
const PPPOE_MODE = 2
const FECTH_INTERVAL = 10000

export default function MonitoringWanData({WanConfigurationComponent}) {

    const [wan, setWan] = useState(null)
    const [wanMode, setWanMode] = useState(null)
    const [_interface, setInterface] = useState(null)
    const [pppoe, setPPPoE] = useState(null)

    const [ponStatus, setPonStatus] = useState(DefaultPonStatus)
    const [currentWan, setCurrentWan] = useState(null)
    const [currentInterface, setCurrentInterface] = useState(null)
    const [wanIndex, setWanIndex] = useState(0)

    const device = useContext(DeviceContext)

    const wanTimer = useRef(null)
    const pppTimer = useRef(null)
    const ponTimer = useRef(null)
    const itfTimer = useRef(null)
    const mounted = useRef(true)

    useEffect(() => {

        fetchDeviceWan()

        return () => {

            console.log('umounting wan', wanTimer.current)

            /* eslint-disable */
            mounted.current = false
            clearTimeout(wanTimer.current)
            clearTimeout(pppTimer.current)
            clearTimeout(itfTimer.current)
            /* eslint-enable */
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!wan) return

        if(!pppoe && wan[wanIndex].mode === PPPOE_MODE){
            fetchPPPoE()
            return
        }

        fetchInterface(wan[wanIndex].interfaceID)
        fetchPonStatus()

        // eslint-disable-next-line
    }, [wanMode, wanIndex])

    useEffect(() => {

        if(!wan) return

        if(wanMode !== wan[wanIndex].mode)
            setWanMode(wan[wanIndex].mode)

        // eslint-disable-next-line
    }, [wan])

    useEffect(() => {

        if(!pppoe) return

        if(!pppoe[wanIndex] || pppoe[wanIndex] === {}){
            pppoe[wanIndex] = DefaultPPPoE
            setPPPoE([...pppoe])
            return
        }

        fetchInterface(pppoe[wanIndex].interfaceID)

        // eslint-disable-next-line
    }, [pppoe, wanIndex])

    useEffect(() => {

        if(!wan) return

        setCurrentWan(wan[wanIndex])

        // eslint-disable-next-line
    }, [wan, wanIndex])

    useEffect(() => {

        if(mounted.current)
            ponTimer.current = setTimeout(fetchPonStatus, FECTH_INTERVAL)

        return () => {
            clearTimeout(ponTimer.current)
        }

        // eslint-disable-next-line
    }, [ponStatus])

    const fetchDeviceWan = async() => {

        let result = await device.retrieveResource('wan')

        if(result.status !== backendStatus.SUCCESS){
            if(mounted.current)
                wanTimer.current = setTimeout(fetchDeviceWan, RETRY_TIME)
            return
        }

        if(wanIndex >= result.content.length)
            setWanIndex(0)

        setWan(result.content)
    }

    const fetchPPPoE = async() => {
        let result = await device.retrieveResource('pppoe')

        if(result.status !== backendStatus.SUCCESS){
            console.log('Error fetching pppoe:', result.content)
            if(mounted.current)
                pppTimer.current = setTimeout(fetchPPPoE, RETRY_TIME)
            return
        }

        if(result.content.length !== 0){
            setPPPoE(result.content)
        }else{
            setPPPoE([DefaultPPPoE])
        }
    }


    const fetchInterface = async(interfaceID) => {

        if(!interfaceID) return

        if(interfaceID === 'new'){
            setInterface(DefaultInterface)
            return
        }

        let result = await device.retrieveResource(`interface/${interfaceID}`)

        if(result.status !== backendStatus.SUCCESS){
            console.log('Error fetching interface:', result.content)
            if(mounted.current)
                itfTimer.current = setTimeout(fetchInterface, RETRY_TIME)
            return
        }

        if(!result.content.vlanid)
            result.content['vlanid'] = ''

        setCurrentInterface(result.content)
        setInterface(result.content)
    }

    const fetchPonStatus = async() => {
        if(DeviceFactory.isTR069(device.data.model)){
            let result = await device.retrieveResource('pon_status')

            if(result.status !== backendStatus.SUCCESS){
                console.log('Error fetching pon_status:', result.content)
                if(mounted.current)
                    ponTimer.current = setTimeout(fetchPonStatus, RETRY_TIME)
                return
            }

            if(result.content.length !== 0){
                setPonStatus(result.content)
            }
        }
    }

    const getPonDataLines = () => {

        let lines = [
            {label: 'RX Power (dBm)', value: ponStatus.rx_power},
            {label: 'TX Power (dBm)', value: ponStatus.tx_power},
            {label: 'Tensão (V)', value: ponStatus.voltage},
            {label: 'Corrente Bias (mA)', value: ponStatus.bias_current},
            {label: 'Temperatura (°C)', value: ponStatus.temperature},
        ]

        return lines
    }

    const getDataLines = () => {

        let lines = [
            {label: 'Tipo de conexão', value: getWanModeString(currentWan.mode)},
            {label: 'Endereço IP', value: currentInterface.ip4},
            {label: 'Endereço MAC', value: currentInterface.mac},
            {label: 'Máscara de sub-rede', value: currentInterface.netmask},
            {label: 'Gateway', value: currentWan.gateway},
            {label: 'MTU', value: currentInterface.mtu},
            {label: 'DNS 1', value: currentWan.dns_v4.dns1},
            {label: 'DNS 2', value: currentWan.dns_v4.dns2},
        ]

        return lines
    }

    const getIP6Lines = () => {
        let lines = _interface.ip6_list.map((ip6, index) => {
            return {label: `Endereço IPv6 ${index + 1}`, value: ip6}
        })

        lines = [ ...lines, ...[
            {label: 'Gateway IPv6', value: wan[wanIndex].gateway_v6},
            {label: 'DNSv6 1', value: wan[wanIndex].dns_v6.dns1},
        ]]

        if(wan[wanIndex].dns_v6.dns2 !== "")
            lines.push({label: 'DNSv6 2', value: wan[wanIndex].dns_v6.dns2})

        return lines
    }

    return <div id='management-wan'>

        {!wan || !currentWan || !_interface || wanIndex >= wan.length ? <Loading show={true}></Loading> :

        <div className='wan-flex-container'>

            <div className='wan-info'>

                <div className='subtitle'>Informações</div>

                <WanStatistics
                    deviceid={device.data.deviceid}
                    wan={wan[wanIndex]}
                    withIpv6={_interface.ip6_count > 0}
                ></WanStatistics>

                    <div className='wan-info-cards'>

                    {DeviceFactory.isTR069(device.data.model) && <div className='wan-card'>
                        <div className='dashboard-subtitle'>
                            <PonIcon size='20'></PonIcon>
                            <label>PON Status</label>
                        </div>

                        <InfoCard
                            lines={getPonDataLines()}
                        ></InfoCard>
                    </div>}

                    <div className='wan-card'>
                        <div className='dashboard-subtitle'>
                            <NetworksIcon size='20'></NetworksIcon>
                            <label>IPv4</label>
                        </div>

                        <InfoCard
                            lines={getDataLines()}
                        ></InfoCard>
                    </div>

                    {_interface.ip6_count > 1 ? <div className='wan-card'>
                        <div className='dashboard-subtitle'>
                            <NetworksIcon size='20'></NetworksIcon>
                            <label>IPv6</label>
                        </div>

                        <InfoCard
                            lines={getIP6Lines()}
                        ></InfoCard>
                    </div> : null}

                </div>

            </div>

            <WanConfigurationComponent
                wan={wan}
                setWan={setWan}
                wanIndex={wanIndex}
                setWanIndex={setWanIndex}
                _interface={_interface}
                setInterface={setInterface}
                pppoe={pppoe}
                fetchWan={fetchDeviceWan}
            ></WanConfigurationComponent>

       </div>

        }
    </div>
}