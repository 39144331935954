import React, { useContext, useEffect, useState } from 'react'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Button from '../../../components/button/button'
import Input from '../../../components/input/input'
import Loading from '../../../components/loading/loading'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import { MenuContext } from '../../../components/menu/menu-state'
import Modal, { ModalTypes } from '../../../components/modal/modal'
import { FirebaseContext } from '../../firebase'
import { UserContext } from '../../user-context'
import { LGPDFileLink } from '../lgpd/lgpd-consent'
import { RemotizeType } from '../signup/signup'
import packageJson from '../../../../package.json'

export default function MyAccountPage() {

    const [isp, setIsp] = useState(null)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [agreementDate, setAgreementDate] = useState(null)
    const [userConsent, setUserConsent] = useState(null)
    const [typeConfirm, setTypeConfirm] = useState(false)
    const [confirmationText, setConfirmationText] = useState('')

    const header = useContext(MainHeaderContext)
    const [user,] = useContext(UserContext)
    const menu = useContext(MenuContext)
    const backend = useContext(BackendContext)
    const firebase = useContext(FirebaseContext)

    useEffect(() => {

        header.title.set('Minha conta')

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!user.ispid) return

        fetchIsp()
        fetchUserConsent()

        // eslint-disable-next-line
    }, [user])

    useEffect(() => {

        if(userConsent)
            setAgreementDate(new Date(userConsent.timestamp * 1000))

        // eslint-disable-next-line
    }, [userConsent])

    const fetchIsp = async() => {

        let result = await backend.retrieveFresh(`isp/${user.ispid}`)
        if(result.status === backendStatus.SUCCESS){
            setIsp(result.content)
        }
    }

    const fetchUserConsent = async() => {

        let result = await backend.retrieveFresh(`lgpd/consent/${user.username}`)


        if (result.status === backendStatus.SUCCESS){
            setUserConsent(result.content)
            return
        }


        setTimeout(fetchUserConsent, 2000)
    }


    const proceedToTypeConfirmation = (confirmed) => {

        setDeleteConfirm(false)
        setTypeConfirm(false)

        if(!confirmed) return

        setTypeConfirm(true)
    }

    const deleteUser = async(confirmed) => {

        let result = await backend.delete(`users/${user.username}`)
        if(result.status !== backendStatus.SUCCESS)
            console.error('Error deleting user:', result.content)

        firebase.signOut()
    }

    const deleteAccountModalContent = () => {
        return <div className='delete-account-modal'>

            <br></br>

            Ao deletar a conta, removeremos automaticamente todos os seus dados vinculados ao Portal Remotize.

            <br></br>
            <br></br>

            {user.last_admin ? <div>
                Além disso, você é o único administrador cadastrado para o provedor <b>{isp.name}</b>.
                Assim, as seguintes ações também serão realizadas:

                <ul>
                    <li>Todos os dados do provedor serão removidos.</li>
                    <li>Todas as contas de usuário vinculadas provedor serão removidas.</li>
                    <li>Todos os roteadores vinculados ao provedor serão desassociados</li>
                    <li>Todos os roteadores desassociados (ação acima) voltarão ao padrão de fábrica <b>Intelbras</b></li>
                </ul>

                <div className='centered'>
                    <b>Deseja deletar sua conta?</b>
                </div>

            </div> : null}
        </div>
    }

    const typeConfirmModalContent = () => {
        return <div className='delete-account-modal'>

            <b style={{color: 'red'}}>Atenção:</b> Esta ação é irreversível!

            <br></br>
            <br></br>

            Digite o seguinte para prosseguir: <b style={{color: 'red'}}>deletar</b>

            <br></br>
            <br></br>
            
            <Input id='type-confirm'
                // label='Digite deletar para prosseguir'
                placeholder='digite deletar'
                value={confirmationText}
                onChange={(e) => setConfirmationText(e.target.value)}
            ></Input>

            <Button id='type-confirm-button'
                isError
                text='Deletar'
                disabled={confirmationText !== 'deletar'}
                onClick={deleteUser}
            ></Button>
        </div>
    }

    if(!isp || !agreementDate)
        return <Loading show={true}></Loading>

    return <div id='my-account-page' className='page'>

        <div className='remotize-version'>{packageJson.version}</div>

        <Modal
            id='delete-my-account-modal'
            title='Deletar conta'
            show={deleteConfirm}
            content={deleteAccountModalContent()}
            type={ModalTypes.CONFIRM_WARNING}
            dismissText='Não, quero manter'
            confirmText='Sim, desejo deletar'
            onDismissClick={proceedToTypeConfirmation}
        >
        </Modal>

        <Modal
            id='delete-account-confirm-modal'
            title='Deletar conta'
            show={typeConfirm}
            content={typeConfirmModalContent()}
            type={ModalTypes.INFO}
            onOverlayClick={proceedToTypeConfirmation}
        ></Modal>

        <div className='personal-info'>

            <div id='personal-info-pic'>
                <img alt='' src={menu.avatar.value}></img>
            </div>

            <div id='personal-info-data'>
                <div className='user-info'>
                    <label>{user.name}</label>
                    <span>{user.email}</span>
                </div>
                <div className='user-info'>
                    <label>Nível de acesso</label>
                    <span>{user.type === RemotizeType.ADMIN ? 'Administrador' : 'Técnico'}</span>
                </div>
                <div className='user-info'>
                    <label>Informações do provedor</label>
                    <span className='isp-info'>
                        {isp.name}
                        <br></br>
                        {isp.cnpj}
                        <br></br>
                        {isp.phone}
                    </span>
                </div>
            </div>
        </div>

        <div className='personal-info'>
            <div id='personal-info-data'>

                <div className='user-info'>
                    <label>Privacidade e Termos de Uso</label>
                    <span>
                        <LGPDFileLink label='Termos de Uso' linkID='terms_of_use'></LGPDFileLink>
                        <LGPDFileLink label='Política de Privacidade' linkID='privacy_policy'></LGPDFileLink>
                    </span>
                    <br></br>
                    Estes são os documentos lidos e aceitos por
                    você em {agreementDate.toLocaleDateString('pt-br')} às {agreementDate.toLocaleTimeString('pt-br')}.
                </div>
                <div className='user-info'>
                    <label>Remover conta</label>
                    <Button isError
                        text='Deletar minha conta'
                        onClick={() => setDeleteConfirm(true)}
                    ></Button>
                </div>
            </div>

        </div>
    </div>
}