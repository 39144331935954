import React, { useState, useContext } from 'react'
import Button from '../../../components/button/button'

import { BackendContext, backendStatus } from '../../../backend/backend'
import DefaultStatusModals from '../../../components/modal/default-status-modals'

import './firmware.css'

export default function NotificationForm() {

    const [text, setText] = useState('')

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const backend = useContext(BackendContext)

    const submit = async(e) => {
        e.preventDefault()
        
        setLoading(true)

        let result = await backend.create('notifications', {
            text: text,
        })

        if(result.status === backendStatus.SUCCESS){
            setLoading(false)
            setSuccess(true)
            return
        }

        setLoading(false)
        setError(true)
    }

    const continueFn = () => {
        setLoading(false)
        setSuccess(false)
        setError(false)
    }

    return <div className='mandatory-update-box'>

        <DefaultStatusModals
            success={success}
            saving={loading}
            error={error}
            successText='Notificação enviada com sucesso'
            savingText='Aguarde'
            errorText='Houve um erro ao enviar a notificação'
            continueFn={continueFn}
        ></DefaultStatusModals>

        <br></br>
        <form className='mandatory-version-update-form' onSubmit={submit}>
            <div className='subtitle'>Enviar Notificação</div>

            <textarea className='itb-input notification' id='notification-text'
                placeholder='Escreva o texto da notificação aqui'
                value={text}
                onChange={(e) => setText(e.target.value)}
                rows='5'
            >
            </textarea>

            <Button text='Enviar'></Button>
        </form>
        
    </div>
}