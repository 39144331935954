import React from 'react'

export default function Wizard({title}){

    return (
        <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>{title ? title : 'Ajuda'}</title>
            <desc>Created with Sketch.</desc>
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rounded" transform="translate(-544.000000, -288.000000)">
                    <g id="Action" transform="translate(100.000000, 100.000000)">
                        <g id="-Round-/-Action-/-help" transform="translate(442.000000, 186.000000)">
                            <g>
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,19 L11,19 L11,17 L13,17 L13,19 Z M15.07,11.25 L14.17,12.17 C13.67,12.68 13.31,13.14 13.13,13.86 C13.05,14.18 13,14.54 13,15 L11,15 L11,14.5 C11,14.04 11.08,13.6 11.22,13.19 C11.42,12.61 11.75,12.09 12.17,11.67 L13.41,10.41 C13.87,9.97 14.09,9.31 13.96,8.61 C13.83,7.89 13.27,7.28 12.57,7.08 C11.46,6.77 10.43,7.4 10.1,8.35 C9.98,8.72 9.67,9 9.28,9 L8.98,9 C8.4,9 8,8.44 8.16,7.88 C8.59,6.41 9.84,5.29 11.39,5.05 C12.91,4.81 14.36,5.6 15.26,6.85 C16.44,8.48 16.09,10.23 15.07,11.25 Z" id="🔹Icon-Color" fill="#1D1D1D"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}