import React from 'react'
import InfoPopup from '../../../components/info-popup/info-popup';
import NumberPicker from '../../../components/number-picker/number-picker'
import { RemotizeType } from '../signup/signup';

/* eslint-disable*/
Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
}
/* eslint-enable*/

const MIN_TIME = 0
const MAX_TIME = 1440

function getNewTime(time){

    if(time >= MAX_TIME)
        time = MIN_TIME
    if(time < MIN_TIME)
        time = MAX_TIME + time

    return time
}

export default function UpdateTimePicker({label, window, setWindow, user}){

    const changeStart = (value) => {
        let newValue = getNewTime(window.start + value)
        setWindow({...window, start: newValue})
    }

    const changeEnd = (value) => {
        let newValue = getNewTime(window.end + value)
        setWindow({...window, end: newValue})
    }


    return <div className='update-time-picker'>

        <div className='update-time-text'>
            As atualizações serão feitas das
        </div>

        {user.type !== RemotizeType.ADMIN ?

            <div className='common-time-window'>
                {Math.floor(window.start / 60).pad() + ':' + (window.start % 60).pad() +
                ' às ' + Math.floor(window.end / 60).pad() + ':' + (window.end % 60).pad()}
            </div>

            :

            <div className='update-time-window' id='update-time-window'>
                <div className='update-time'>
                    <NumberPicker value={Math.floor(window.start / 60).pad()}
                        onClickUp={() => changeStart(60)}
                        onClickDown={() => changeStart(-60)}
                    ></NumberPicker>
                    <div className='update-time-separator'>:</div>
                    <NumberPicker value={(window.start % 60).pad()}
                        onClickUp={() => changeStart(15)}
                        onClickDown={() => changeStart(-15)}
                    ></NumberPicker>
                </div>

                <span>às</span> 

                <div className='update-time'>
                    <NumberPicker value={Math.floor(window.end / 60).pad()}
                        onClickUp={() => changeEnd(60)}
                        onClickDown={() => changeEnd(-60)}
                    ></NumberPicker>
                    <div className='update-time-separator'>:</div>
                    <NumberPicker value={(window.end % 60).pad()}
                        onClickUp={() => changeEnd(15)}
                        onClickDown={() => changeEnd(-15)}
                    ></NumberPicker>
                </div>
            </div>
        }

        <InfoPopup
            message='A atualização em massa de dispositivos feita pela
            ativação de versão e pela atualização automática controlada
            será realizada dentro desta janela de tempo.'
        ></InfoPopup>

    </div>
}