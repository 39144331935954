
const APPLY_ACTION = 5

export class BifrostAPI {

    constructor(backend) {
        this.backend = backend
    }

    async create(deviceid, resourceName, data) {

        let result = await this.backend.create(`deviceproxy/${deviceid}/${resourceName}`, data)

        return result

    }

    async retrieve(deviceid, resourceName) {

        try {

            let result = await this.backend.retrieveFresh(`deviceproxy/${deviceid}/${resourceName}`)

            return result

        }catch{
            console.error(`Error retrieving  ${resourceName}`)
            return {status: 2} //FIXME: magic number
        }
    }

    async update(deviceid, resourceName, data) {

        let result = await this.backend.update(`deviceproxy/${deviceid}/${resourceName}/${data.id}`, data)

        return result

    }

    async delete(deviceid, resourceName, data) {

        let result = await this.backend.delete(`deviceproxy/${deviceid}/${resourceName}/${data.id}`, data)

        return result

    }


    async apply(deviceid) {

        let result = await this.backend.create(`deviceproxy/${deviceid}/action`, {actionID: APPLY_ACTION})

        return result
    }
}