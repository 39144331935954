import React, { useState, useEffect, useContext } from 'react'
import { BackendContext } from '../../../../../../backend/backend'
import Loading from '../../../../../../components/loading/loading'
import { DiscreteColorLegend, RadialChart } from 'react-vis'
import DeviceFactory from '../../../../../apis/device-factory'

const colorPalette = ['#103543', '#6893A7', '#B8D4E1','#5ec0bc','#6CCFF6' ]

const normalizeData = (model) => {
  
  let data=[]

  model.models.map(element =>{
    var account = (element.total*100)/model.total
    var percentage = account.toFixed(1)

    return data.push({
      angle: element.total,
      label: `${percentage}%`,
      color:element.color,
   })
  })
  return data
}

const normalizeDataLegend = (model) => {

    let data = []

    model.models.map(element=>{
      var account = (element.total*100)/model.total
      var percentage = account.toFixed(1)
        return data.push({
          title: `${element.model} (${element.total}) -${percentage}% `,
          color:element.color,
          strokeWidth:14
        })
    })

    return data
}


export default function RadialGraphic() {

    const [stats, setStats] = useState(null)
    const [showLabels, setShowLabels] = useState(false)

    const backend = useContext(BackendContext)
    useEffect(() =>  {

        fetchDevices()

        // eslint-disable-next-line
    }, [])

    const getRandomColor = () => {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    }

    const getVersionChartColor = (index) => {
        if(index >= colorPalette.length){
            return getRandomColor()
        }

        return colorPalette[index]
    }

    const fetchDevices = async() => {

        let result = await backend.retrieveFresh("/devices/brief")

        var models =result.content.models
        var total =result.content.total-1
        
        models.map((obj,i)=>{
          if(DeviceFactory.isGateway(obj.model)){
            models.splice(i,1)
          }else if(!DeviceFactory.isTR069(obj.model)){
            models.splice(i,1)
          }
          return models
        })

        models = models.map((obj,i) => ({...obj, model: obj.model ,color: getVersionChartColor(i)}))

        setStats({total: total, models: models})
    }
    const composeModelCard = (model) => {
        return <div className='devices-chart-container'>
                <div className='model-devices-chart'onMouseEnter={()=>setShowLabels(true)} onMouseLeave={()=>{setShowLabels(false)}}>
                    <RadialChart
                        className='radial-chart'
                        data={normalizeData(model)}
                        height={250}
                        width={290}
                        innerRadius={100}
                        radius={65}
                        padAngle={0.04}
                        colorType={"literal"}
                        labelsRadiusMultiplier={2.2}
                        showLabels={showLabels}
                        //labelsStyle={{width:'100px'}}
                        onValueMouseOut={()=>{

                        }}
                        onValueMouseOver={()=>{
                          
                        }}
                    />
                    <DiscreteColorLegend items={normalizeDataLegend(model)} className='radial-chart-legend' />
                </div>
              </div>
    }


    return stats ? <div id="radial-stats">

        <div className='update-info-container'>
        
        {composeModelCard(stats)}


        </div>

    </div> : <Loading show={true}></Loading>
}

