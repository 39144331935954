import React from 'react'
//import BarGraphic from './bar-graphic';
import RadialGraphic from './radial-graphic';
import BarGraphic from './bar-graphic';

import './devices.css'

export default function Devices (){

    return(<div className='gateway-page-container'>
    
    <div className='gateway-card'>
        <div className='subtitle'>Dispositivos</div>
            <div className='graphics'>
            <RadialGraphic />
{            <BarGraphic />
}            </div>
            </div>
            </div>)
}