import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Button from '../../../components/button/button'
import Loading from '../../../components/loading/loading'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'

export default function ProfileEditConfirmation() {

    const history = useHistory()
    const [state,] = useState(history.location.state)
    const [devicesCount, setDevicesCount] = useState(null)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)

    useEffect(() => {

        if(!state.profile){
            history.push("/profiles/list")
            return
        }

        header.title.set(`Aviso de edição - ${state.profile.name}`)
        header.backRoute.set('/profiles/list')
        fetchDevicesCount()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(devicesCount === null) {
            return
        }

        if(devicesCount === 0) {
            history.push({pathname: "/profiles/add", state: {...state, count: devicesCount}})
        }

        // eslint-disable-next-line
    }, [devicesCount])

    const fetchDevicesCount = async() => {

        let result = await backend.retrieveFresh(`profiles/${state.profile.id}/devices`)

        if(result && result.status === backendStatus.SUCCESS){
            setDevicesCount(result.content.count)
        }
    }

    if(!state.profile || !devicesCount)
        return null

    return <div id='profile-edit-confirm-page'>

        <div className='subtitle'>Atenção!</div>

        Esta ação não pode ser revertida!

        {state.profile.autoApply ?
        <div>
            <div>
                Ao editar o perfil <b>{state.profile.name}</b>,
                todos os roteadores associados à ele
                terão as configurações <b>modificadas</b>.
            </div>
            Para alterar as configurações de um roteador específico você deve criar um novo perfil e
            associá-lo ao roteador desejado.
        </div>
        :<div>
            <div>
                Ao editar o perfil <b>{state.profile.name}</b>,
                ele será ajustado nos roteadores do seu parque que estiverem com ele associado,
                porém, as novas alterações só serão aplicadas após o restauro de cada roteador
                (via interface, botão físico ou gerenciamento remoto).
            </div>
        </div>}

        <br></br>

        Esta ação impactará <b>{
            devicesCount ? devicesCount : <Loading show={true} mini></Loading>
        }</b> dispositivo{devicesCount > 1 ? 's' : ''}.

        <br></br>
        <br></br>

        <b>Tem certeza que deseja prosseguir com a edição do perfil?</b>

        <div className='button-group'>
            <Button id='confirm-edit-cancel'
                text='Não, quero voltar'
                outlined
                onClick={() => history.push("profiles/list")}
            ></Button>

            <Button id='confirm-edit-confirm'
                text='Sim, quero editar o perfil'
                isError
                onClick={() => {
                    history.push({pathname: '/profiles/add', state: {...state, count: devicesCount}})}
                }
            ></Button>
        </div>
    </div>
}