import React, { useEffect, useContext } from 'react'

import { MainHeaderContext } from '../../../components/main-header/main-header-state'

import './faq.css'

export default function FaqPage() {

    const header = useContext(MainHeaderContext)

    useEffect(() => {
        header.navigation.set(false)
        header.title.set('Remotize - Perguntas frequentes')
    })

    return <div id='main-app' className='with-header'>
        <div className='faq'>

        <div className='subtitle'>O que é o Remotize?</div>
            O Remotize é a nova plataforma para gerenciamento centralizado de roteadores 
            da nova linha Wi-Force. Com o Remotize os provedores de internet podem, por exemplo,
            verificar se os produtos associados à sua conta estão online,
            definir configurações-padrão e atualizar o firmware de forma prática e fácil. 
            Para acessar o portal, o endereço é <a href='https://remotize.intelbras.com.br'>
            https://remotize.intelbras.com.br</a>.

        <div className='subtitle'>Qual a diferença entre o Remotize e o Customize?</div>
            As duas plataformas foram criadas pela Intelbras para facilitar o dia a dia dos provedores.
            Porém, a principal diferença é que o Remotize é específico para a nova linha de roteadores chamada
            Wi-Force e traz funcionalidades como configuração e atualização de forma automatizada,
            sem necessitar gerar um firmware e atualizar um roteador de cada vez.

        <div className='subtitle'>Quais são as etapas que eu preciso concluir para utilizar o Remotize?</div>
            Primeiramente você precisa se cadastrar no sistema utilizando uma conta Google
            (futuramente disponibilizaremos outras maneiras de se cadastrar) e então acessar o portal.
            Em seguida, você precisa baixar o aplicativo desktop do Remotize e utilizá-lo para associar
            os roteadores à sua conta. Após isso, está tudo pronto e você pode começar a usar as funções
            da plataforma.

        <div className='subtitle'>Por que preciso baixar o aplicativo desktop do Remotize?</div>
            O aplicativo desktop do Remotize é necessário para que você consiga associar os roteadores à sua conta,
            permitindo assim que eles sejam gerenciados. Para isso,
            baixe através do Portal Remotize (<a href='https://remotize.intelbras.com.br'>
            https://remotize.intelbras.com.br</a>) o aplicativo correspondente ao
            sistema operacional que você utiliza em seu computador (Windows ou Linux), realize a instalação,
            execute o aplicativo e siga as orientações do tutorial guiado para começar a utilizá-lo.

        <div className='subtitle'>Por que eu preciso associar os dispositivos à minha conta?</div>
            A associação é necessária para que você consiga gerenciar os produtos.
            Atenção: somente roteadores ainda não configurados podem ser associados.

        <div className='subtitle'>Eu preciso continuar usando o aplicativo desktop após associar os dispositivos à 
        minha conta?</div>
            O aplicativo é necessário somente para associar os roteadores à sua conta.
            Após realizar essa associação você pode utilizar o Remotize web, acessando através
            do endereço <a href='https://remotize.intelbras.com.br'>https://remotize.intelbras.com.br</a>.

        <div className='subtitle'>Como eu mudo as configurações-padrão de fábrica do roteador?</div>
            Você precisará criar um perfil de configuração no Portal Remotize e então associá-lo aos
            roteadores que estiverem na sua conta. A partir daí, se esses roteadores forem resetados,
            eles retornarão às configurações que você definiu e não mais para o padrão Intelbras.

        <div className='subtitle'>O que é um perfil de configuração?</div>
            É um registro que você irá criar no Portal Remotize e que contém as configurações-padrão que 
            você deseja associar aos roteadores. Você pode criar quantos perfis de
            configuração forem necessários.

        <div className='subtitle'>Preciso usar o mesmo perfil de configuração para todos os roteadores?</div>
            Essa é uma decisão sua. Você pode usar o mesmo perfil de configuração para todos os roteadores
            ou criar um perfil específico para cada um, por exemplo, para customizar as configurações
            para cada cliente.

        <div className='subtitle'>Como eu aplico um perfil de configuração?</div>
            No portal Remotize, após ter associado roteadores à sua conta, acesse a lista de dispositivos
            e clique sobre o roteador que deseja aplicar o perfil. Uma aba será aberta e através dela você
            conseguirá associar o perfil de configuração desejado.

        <div className='subtitle'>Como voltar as configurações-padrão de um roteador para o padrão Intelbras?</div>
            Basta desassociar o perfil de configuração do roteador. Ao fazer isso e em seguida resetar o produto,
            ele retornará para as configurações-padrão Intelbras.

        <div className='subtitle'>Como atualizar meus roteadores via Remotize?</div>
            Acesse o menu Atualização. Quando houver uma nova versão de firmware disponível, você pode ativá-la
            para que seus roteadores sejam atualizados conforme a janela de horário configurada.
            Ao ativar uma versão, todos os roteadores associados à sua conta serão atualizados
            automaticamente quando o horário programado para execução da atualização for atingido.
            Se você associar novos roteadores à sua conta e eles estiverem com versões anteriores
            de firmware, eles serão automaticamente atualizados.

        <div className='subtitle'>O que é atualização automática controlada?</div>
            Com a atualização automática controlada você pode atualizar apenas uma quantidade
            específica de produtos que você definir e manter apenas esses produtos atualizados
            pelo período de tempo (dias) configurado. Dessa forma, você pode validar se a
            atualização correspondeu às suas necessidades antes de ativar a nova versão para
            todo o seu parque de roteadores.

        <div className='subtitle'>Eu posso acessar remotamente meus roteadores via Remotize?</div>
            Por enquanto não. Essa funcionalidade será disponibilizada futuramente. Por isso, para acessar
            remotamente um produto, você pode utilizar o acesso remoto do próprio roteador.

        <div className='subtitle'>Como eu sugiro melhorias ou ideias?</div>
            Você pode entrar em contato através de qualquer um dos canais de comunicação da
            Intelbras: <a href='https://www.intelbras.com/pt-br/contato/atendimento/'>
            https://www.intelbras.com/pt-br/contato/atendimento/</a>.

        </div>
    </div>
}