import React, { useContext, useEffect, useState } from 'react'

import { updateStatusEnum } from './update-versions'
import InfoLine from '../../../components/info-line/info-line'
import { getDeviceName } from '../../remotize-devices'
import { BackendContext, backendStatus } from '../../../backend/backend'

const SECONDS_IN_A_DAY = 86400
const SECONDS_IN_A_HOUR = 3600
const SECONDS_IN_A_MINUTE = 60

let timeInterval = null

export default function UpdateValidating({firmwares, update}) {

    const [remainingTimes, setRemainingTimes] = useState([])
    const [devices, setDevices] = useState([])

    const versions = update.versions
    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchDevices()
        updateTimes()
        timeInterval = setInterval(updateTimes, 60000)
        return () => { clearInterval(timeInterval) }

        // eslint-disable-next-line
    }, [])

    const fetchDevices = async() => {
        let result = await backend.retrieveFresh('devices?controlledUpdate=y')
        if(result.status === backendStatus.SUCCESS)
            setDevices(result.content)
    }


    const getActiveFirmwareVersion = (model) => {
        for(let firmware of firmwares) {
            if(firmware.model === model)
                return firmware.version
        }

        return ''
    }

    const updateTimes = () => {


        for(let version of versions){
            remainingTimes[version.model] = getRemainingTime(version.start_time)
        }
        setRemainingTimes({...remainingTimes})
    }

    const getRemainingTime = (startTime) => {

        let now = Math.round((new Date()).getTime() / 1000);
        let spent = now - startTime
        let updateTime = update.days * SECONDS_IN_A_DAY

        let remaining = updateTime - spent

        if(remaining <= 0)
            return 'concluído'

        let days = Math.floor(remaining / SECONDS_IN_A_DAY)
        remaining = remaining % SECONDS_IN_A_DAY
        let hours = Math.floor(remaining / SECONDS_IN_A_HOUR)
        remaining = remaining % SECONDS_IN_A_HOUR
        let minutes = Math.floor(remaining / SECONDS_IN_A_MINUTE)

        let text = days ? (days > 1 ? `${days} dias, ` : `${days} dia, `) : ''
        text += hours ? (hours > 1 ? `${hours} h e ` : `${hours} h e `) : ''
        text += `${minutes} min`

        return text
    }

    const getTestDevicesCount = (model) => {
        let count = 0
        for(let device of devices)
            if(device.model === model)
                count++

        return count
    }

    const composeValidationsInfo = () => {


        return <React.Fragment>

            <div className='model-stats-container'>
                {versions.map(({status, model, start_time}) => {
                    if(status === updateStatusEnum.VALIDATING || status === updateStatusEnum.STOPPED)
                        return <div className='update-validation-info' key={model}>
                            <div className='title'>{getDeviceName(model)}</div>
                            <InfoLine label='Versão sob teste' value={getActiveFirmwareVersion(model)}></InfoLine>
                            {status === updateStatusEnum.STOPPED ? 'Teste interrompido' : [
                                <InfoLine key='info-1' label='Tempo restante' value={remainingTimes[model]}></InfoLine>,
                                <InfoLine key='info-2' label='Dispositivos de teste' value={getTestDevicesCount(model)}></InfoLine>
                            ]}
                        </div>
                    return null
                })}
            </div>

        </React.Fragment>
    }

    return <div id='update-validating-section'>

        <div className='subtitle'>
            Atualização automática controlada
        </div>

        {composeValidationsInfo()}

    </div>
}