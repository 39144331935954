import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Checkbox from '../../../components/checkbox/checkbox'
import Form from '../../../components/form/form'
import Loading from '../../../components/loading/loading'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import Modal, { ModalTypes } from '../../../components/modal/modal'
import { FirebaseContext } from '../../firebase'
import { UserContext } from '../../user-context'

export function LGPDFileLink({label, linkID, inline}){

    const backend = useContext(BackendContext)
    const firebase = useContext(FirebaseContext)

    const showFile = async(documentType) => {

        let result = await fetch(`${backend.getDriverBaseUrl()}/lgpd/documents/${documentType}`, {
            headers: {
                "Authorization": "Bearer " + await firebase.getToken()
            }
        })

        let rdata = await result.blob()
        var newBlob = new Blob([rdata], {type: "application/pdf"})
        const dataUrl = window.URL.createObjectURL(newBlob)
        window.open(dataUrl)
        setTimeout(function(){
            window.URL.revokeObjectURL(dataUrl)
        }, 100)
    }


    return(
        <div id={linkID} className={`center-link ${inline ? 'inline' : ''}`} onClick={(e) => {
            e.preventDefault()
            showFile(linkID)
        }}>
            {label}
        </div>
    )
}

export async function fetchLatestTerms(backend, setLatestTerms){

    let result = await backend.retrieveFresh('lgpd/documents/latest')
    if (result.status === backendStatus.SUCCESS){
        setLatestTerms(result.content)
        return
    }

    setTimeout(() => fetchLatestTerms(backend, setLatestTerms), 2000)
}

export async function saveConsent(backend, username, latestTerms) {

    let userConsent = {
        username: username,
        terms_of_use: latestTerms.terms_of_use,
        privacy_policy: latestTerms.privacy_policy,
        agreed: true,
        timestamp: Math.floor(Date.now() / 1000)
    }

    let result = await backend.create('lgpd/consent', userConsent)
    if(result.status !== backendStatus.SUCCESS){
        return false
    }

    return true

}

export default function LGPDConsentPage() {

    const [latestTerms, setLatestTerms] = useState(null)
    const [agreed, setAgreed] = useState(false)
    const [error, setError] = useState(false)

    const [user,] = useContext(UserContext)
    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const history = useHistory()

    useEffect(() => {

        header.title.set('Consentimento')

        fetchLatestTerms(backend, setLatestTerms)

        // eslint-disable-next-line
    }, [])


    const submit = async() => {

        let saved = saveConsent(backend, user.username, latestTerms)

        if(!saved){
            setError(true)
            return
        }

        history.push('/')
    }

    if(!latestTerms)
        return <Loading show={true}></Loading>

    return <div id='lgpd-consent-page' className='page'>

        <Modal id='consent-modal'
            show={error}
            type={ModalTypes.ERROR}
            onDismissClick={() => setError(false)}
            content='Houve um erro ao enviar suas informações. Tente novamente.'
            dismissText='Entendi'
        ></Modal>

        <div className='subtitle'>Declaração de consentimento</div>

        <span>
           Nossos <b>Termos de Uso</b> e <b>Política de Privacidade</b> foram atualizados.
           Para continuar utilizando o Portal Remotize, é necessário que você leia e aceite estas condições.
        </span>

        <LGPDFileLink label='Termos de Uso' linkID='terms_of_use'></LGPDFileLink>
        <LGPDFileLink label='Política de Privacidade' linkID='privacy_policy'></LGPDFileLink>

        <Form
            onSubmit={submit}
            submitText='Continuar'
            disableButton={!agreed}
        >

            <Checkbox id = 'consent-checkbox'
                label='Declaro que li e aceito os Termos de Uso e a Política de Privacidade'
                value={agreed}
                toggleFn={() => setAgreed(!agreed)}
            ></Checkbox>

        </Form>


    </div>
}