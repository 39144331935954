import React, { useEffect, useContext, useState } from 'react'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import List from '../../../components/list/list'
import ThumbIcon from '../../../components/icons/thumb-icon'
import ChecklistIcon from '../../../components/icons/checklist-icon'

import './permissions.css'
import Toggle from '../../../components/toggle/toggle'
import ChevronRightIcon from '../../../components/icons/chevron-right'
import { useHistory } from 'react-router-dom'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Loading from '../../../components/loading/loading'
import { permissionsDiff, intelbrasDefaultPermissions } from './permissions-utils'
import InfoPopup from '../../../components/info-popup/info-popup'


export default function AppPermissionsList() {

    const [permissions, setPermissions] = useState(null)
    const [defaults, setDefaults] = useState(null)

    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)
    const history = useHistory()

    useEffect(() => {

        header.title.set('Aplicativo')

        fetchPermissions()
        fetchDefaults()

        //TODO: implements permissions mqtt event to auto refresh the list

        // eslint-disable-next-line
    }, [])

    const fetchPermissions = async() => {

        let result = await backend.retrieveFresh('users/associations')


        if(result.status === backendStatus.SUCCESS) {
            setPermissions(result.content)
        }
    }

    const fetchDefaults = async() => {

        let result = await backend.retrieveFresh('permissions')

        if(result.status === backendStatus.SUCCESS){
            setDefaults(result.content)
        }
    }

    const getRequests = () => {

        return permissions.map((permission) => {

            const {alias, deviceid} = permission.request
            const {association_status} = permission

            return [deviceid, alias, (association_status ? 'Aprovado' : 'Negado'), getActions(permission)]
        })
    }

    const setAssociationStatus = async(permission, status) => {

        permission.association_status = status

        let result = await backend.update(`users/associations/${permission.id}`, permission)

        if(result.status === backendStatus.SUCCESS){
            fetchPermissions()
            return
        }

        //TODO: handle errors
        alert('Ocorreu um erro')

    }

    const getActions = (permission) => {
        return <div className='app-permissions-actions'>
            <div className='action approve' onClick={() => setAssociationStatus(permission, true)}>
                <ThumbIcon size={18}></ThumbIcon>
                <label>Permitir</label>
            </div>
            <div className='action reprove' onClick={() => setAssociationStatus(permission, false)}>
                <ThumbIcon size={18}></ThumbIcon>
                <label>Negar</label>
            </div>
            <div className='action permissions' onClick={() => history.push(`/app/${permission.id}`)}>
                <ChecklistIcon size={18}></ChecklistIcon>
                <label>Permissões</label>
            </div>
        </div>
    }

    const toggleAutoAccept = async() => {

        defaults.auto_accept = !defaults.auto_accept
        setDefaults({...defaults})

        let diff = permissionsDiff(intelbrasDefaultPermissions, defaults)
        let result = await backend.update('permissions', diff)

        console.log(result)

        fetchDefaults()

    }

    return !defaults ? <Loading show={true}></Loading> : <div id='app-permissions-page'>

        <div id='app-permissions-config'>

            <div className='subtitle'>
                Liberação automática para clientes
            </div>

            <div className='app-auto-approve'>
                <Toggle
                    label='Ativar a aprovação automática'
                    value={defaults.auto_accept}
                    toggleFn={toggleAutoAccept}
                ></Toggle>
                <InfoPopup
                    message='Ao ativar, todas as solicitações de seus clientes para utilizar
                        o aplicativo serão automaticamente aprovadas e eles terão acesso às
                        funcionalidades definidas no botão abaixo.'
                ></InfoPopup>
            </div>

            <div id='edit-default-permissions' onClick={() => history.push('/app/default')}>
                <label>Editar as permissões padrão</label>
                <span>Defina as permissões de acesso padrão para as funcionalidades do app</span>
                <ChevronRightIcon size={30}></ChevronRightIcon>
            </div>
        </div>

        <div id='app-permissions-list'>

             <div className='subtitle'>
                Liberação individual para clientes
                <span>
                    Gerencie as liberações de acesso e as funcionalidades permitidas de forma manual e individual.
                </span>
            </div>

            {permissions ? <List
                width={800}
                columns={[
                    {header:'Dispositivo', size: 200},
                    {header:'Cliente'},
                    {header:'Status'},
                    {header:'Ações', align:'center'}]}
                lines={getRequests()}
            ></List> :
            <Loading show={true}></Loading>}
        </div>

    </div>
}