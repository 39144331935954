const APPLY_ACTION = 5

export class TR069API {

    constructor(backend) {
        this.backend = backend
    }

    async create(deviceid, resourceName, data, timeout=20) {

        let ptimeout = '?timeout='+timeout.toString()
        let result = await this.backend.create(`deviceproxy/${deviceid}/${resourceName}${ptimeout}`, data)

        return result

    }

    async retrieve(deviceid, resourceName) {

        try {
            let rsc = resourceName.split('?')
            let par = new URLSearchParams(rsc[1]);

            if(par.has('timeout')){
                if(parseInt(par.get('timeout'), 10) <= 20)
                    par.set('timeout', 20)
            }else{
                par.append('timeout', 20)
            }
        const maxAttempts = 5;
        const delay = 50000; // 50 segundos
        let attempt = 0;
        let result;

        while (attempt < maxAttempts) {
            console.log(`Tentativa ${attempt + 1}`);
            
            result = await this.backend.retrieveFresh(`deviceproxy/${deviceid}/` + rsc[0] + '?' + par);
            
            if (result && result.status === 1) { // Verifique se o resultado é bem-sucedido
                return result;
            }

            attempt++;
            console.log(`Falha ao recuperar ${resourceName}, tentando novamente em 50 segundos...`);

            // Aguarda 50 segundos antes da próxima tentativa
            await new Promise(resolve => setTimeout(resolve, delay));
        }
            return result

        }catch{
            console.error(`Error retrieving  ${resourceName}`)
            return {status: 2} //FIXME: magic number
        }
    }

    async update(deviceid, resourceName, data, timeout=20) {

        let ptimeout = '?timeout='+timeout.toString()
        let result = await this.backend.update(`deviceproxy/${deviceid}/${resourceName}/${data.id}${ptimeout}`, data)

        return result

    }

    async delete(deviceid, resourceName, data) {

        let result = await this.backend.delete(`deviceproxy/${deviceid}/${resourceName}/${data.id}`, data)

        return result

    }


    async apply(deviceid, timeout=20) {

        let ptimeout = '?timeout='+timeout.toString()
        let result = await this.backend.create(`deviceproxy/${deviceid}/action${ptimeout}`, {actionID: APPLY_ACTION})

        return result
    }
}
