
export const intelbrasDefaultPermissions = {

    auto_accept: false,
    network:{
        wireless: {
            ssid: true,
            password: true,
            radio: false,
        },
        wan: false,
        lan: true,
    },
    security: {
        port_redirect: true,
        dmz: true,
    },
    tools:{
        site_survey: true
    },
    devices:{
        list: true
    },
    config:{
        update: false,
        user_password: false,
        reboot: true,
        restore: false,
        management: false,
    }

}

export function permissionsDiff(a, b) {

    let diff = {}

    Object.keys(a).forEach(key => {
        if(typeof a[key] === 'object'){
            let partial = permissionsDiff(a[key], b[key])
            if(Object.keys(partial).length !== 0)
                diff[key] = partial
        }else{
            if(a[key] !== b[key])
                diff[key] = b[key]
        }
    })

    return diff
}