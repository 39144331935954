import React, {useState, useEffect, useContext} from 'react'
import './input.css'
import zxcvbn from 'zxcvbn';

import EyeIcon from '../../components/icons/eye-icon'
import EyeOffIcon from '../../components/icons/eye-off-icon'
import { FormContext } from '../form/form'
import InfoPopup from '../../components/info-popup/info-popup'

const initialPasswordFeatures = {
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasMinCharacters: false
}

const passwordLevels = ['MUITO FRACA', 'FRACA', 'MÉDIA', 'FORTE', 'MUITO FORTE']

function getClasses(error, label, collapse, externalError, disabled, passwordMeter, externalWarning){
    let classes = 'itb-input-field'

    if(!label)
        classes += ' no-label'
        
    if((externalError !== '') || (error && error.active))
        classes += ' error';

    if(externalWarning !== '')
        classes += ' warning';

    if(collapse)
        classes += ' collapse'
    
    if(disabled)
        classes += ' disabled'

    if(passwordMeter)
        classes += ' password-meter'

    return classes
}

export default function Input(props){
    
    const [type, setType] = useState(props.type)
    const [error, setError] = useState({active:false, message:''})
    const [passwordFeatures, setPasswordFeatures] = useState({...initialPasswordFeatures})
    const [passwordStrength, setPasswordStrength] = useState(passwordLevels[0])
    const [passwordCharacters, setPasswordCharacters] = useState(0)

    const formContext = useContext(FormContext)
    const externalError = props.errorMessage || ''
    const externalWarning = props.warningMessage || ''

    useEffect(() => {
        if(formContext) {
            if (!props.collapse && !props.disabled){
                formContext.setValidators(props.name, runValidators, props.value)
                if (props.collapse === false && props.disabled === false) {
                    runValidators(props.value)
                }
            }
            else {
                formContext.subError(props.name, props.collapsible)
            }
        }

        return () => {
            if(formContext)
                formContext.removeValidators(props.name)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, props.collapse, props.disabled])


    useEffect(() => {
        setType(props.type)
    }, [props.type])

    useEffect(() => {

        if (!props.dependentValues || formContext.pristine) return

        runValidators(props.value)
        
        if(formContext)
            formContext.clearErrors()

        // eslint-disable-next-line
    }, [props.dependentValues])

    const getPasswordIcons = () => {

        if(props.type !== 'password')
            return ''

        let typeClass = (type === 'password' ? '' : 'eye-off')
        return (
            <div id={`itb-input-icon-${props.id || props.name}`}
                className={`itb-input-password-icon clickable ${typeClass}`}
                onClick={() => {
                    setType(type === 'password' ? 'text' : 'password')
                }}
            >
                {type === 'password' ? <EyeOffIcon></EyeOffIcon> : <EyeIcon></EyeIcon>}
            </div>
        )
    }

    async function runValidators(value) {

        let noErrors = true

        setError({active: false, message: ''})
        if(formContext)
            formContext.subError(props.name, props.collapsible)

        if(validators){
            for(let validator of validators) {

                let message = ''
                if(typeof(validator) === 'object')
                    message = await validator.fn(value, validator.params)
                else
                    message = await validator(value)

                if(message !== ''){

                    noErrors = false
                    setError({active: true, message: message})

                    if(formContext)
                        formContext.addError(props.name, props.collapsible)

                    break;
                }
            }
        }


        return noErrors
    }

    const verifyPasswordStrength = (value) => {
        setPasswordCharacters(value.length)

        const hasLowercase = (/[a-z]/.test(value))
        const hasUppercase = (/[A-Z]/.test(value))
        const hasNumber = (/[0-9]/.test(value))
        const hasMinCharacters = (value.length >= 8)

        setPasswordFeatures({ hasLowercase, hasUppercase, hasNumber, hasMinCharacters})

        let strength = zxcvbn(value).score
        if (strongPasswordRequired){
            setPasswordStrength(passwordLevels[strength])
        }else{
            return
        }

        const strengthSpan = document.querySelector('.itb-input-field .password-strength > span')

        switch (strength) {
            case 0:
            case 1:
                strengthSpan.style.color = 'red';
                break
            case 2:
                strengthSpan.style.color = 'goldenrod';
                break
            case 3:
            case 4:
                strengthSpan.style.color = 'var(--itb-green)';
                break
            default:
                strengthSpan.style.color = 'var(--text-color)';
        }
    }
    
    let  {id, label, placeholder, autoComplete, value, onChange, onBlur, collapse, noMessage, validators, disabled,
        passwordMeter, strongPasswordRequired} = props

    let propsCopy = {...props}

    if(typeof propsCopy.noMessage !== 'undefined')
    delete propsCopy.noMessage
    if(typeof propsCopy.collapse !== 'undefined')
    delete propsCopy.collapse
    if(typeof propsCopy.errorMessage !== 'undefined')
    delete propsCopy.errorMessage
    if(typeof propsCopy.warningMessage !== 'undefined')
    delete propsCopy.warningMessage
    if(typeof propsCopy.passwordMeter !== 'undefined')
    delete propsCopy.passwordMeter
    if(typeof propsCopy.strongPasswordRequired !== 'undefined')
    delete propsCopy.strongPasswordRequired
    if(typeof propsCopy.dependentValues !== 'undefined')
    delete propsCopy.dependentValues

    if(!id){
        id = props.name ? props.name : id
    }

    return (
        <div className={getClasses(error, label, collapse, externalError, disabled, passwordMeter, externalWarning)}>



           <div className='itb-input-container'>
                <input className={'itb-input' + (props.type === 'password' ? '-password' : '')}
                    {...propsCopy}
                    id={id}
                    name={props.name ? props.name : id}
                    type={type}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    disabled={disabled}
                    maxLength={props.maxLength ? props.maxLength : null}
                    minLength={props.minLength ? props.minLength : null}
                    onChange={(e) => {

                        if(formContext)
                            formContext.setPristine(false)

                        if(validators)
                            runValidators(e.target.value)

                        if(props.type === 'password' && passwordMeter)
                            verifyPasswordStrength(e.target.value)

                        if(onChange)
                            onChange(e)

                    }}
                    onBlur={() => {

                        if(validators)
                            runValidators(value)

                        if(onBlur)
                            onBlur()
                    }}
                    value={value}
                ></input>

                {props.type !== 'password' || !passwordMeter ?
                    <label to={id}>{label}</label> :
                    <div className='password-meter-tooltip'>
                        <label to={id}>{label}</label>
                    </div>
                }

                {
                    getPasswordIcons()
                }
            </div>


            {externalWarning ?
            <span id={`${id}-warning`} className='itb-input-warning-text'>
                {externalWarning !== '' ? externalWarning || externalWarning : ''}
            </span>:
            <span id={`${id}-error`} className='itb-input-error-text'>
                {(error && error.active && !noMessage) || externalError !== '' ? error.message || externalError : ''}
            </span>}

            {props.type !== 'password' || !passwordMeter ? null :
                strongPasswordRequired ?
                <div className='password-strength'>
                    A sua senha deve ser do tipo <b>FORTE</b> e no momento ela é do tipo <span><b>{passwordStrength}</b></span>
                </div> :

                <div>
                    <ul className='password-features'>
                        <li ok={passwordFeatures.hasLowercase ? 1 : 0}>Minúscula</li>
                        <li ok={passwordFeatures.hasUppercase ? 1 : 0}>Maiúscula</li>
                        <li ok={passwordFeatures.hasNumber ? 1 : 0}>Número</li>
                    </ul>
                    <div className='password-meter-container'>
                        <ul className='password-features'>
                            <li ok={passwordFeatures.hasMinCharacters ? 1 : 0}>Mínimo de 8 caracteres ({passwordCharacters} caractere{passwordCharacters === 1 ? '': 's'})</li>
                        </ul>
                        <InfoPopup
                            message={<div style={{width: '400px'}}>
                                Para construir uma senha FORTE você deve utilizar letras maiúsculas, minúsculas, 
                                números e ao mínimo 8 caracteres.
                            </div>}
                        ></InfoPopup>
                    </div>
                </div>
            }
        </div>
    )
}
