
const PhoneUtils = {

    isValidValue: (e) => {

        let value = e.target.value 

        for(let c of value)
            if(isNaN(c) && c !== '('  && c !== ')' && c !== '-' && c !== ' ')
                return false

        return true
    },

    applyMask: (phone) => {

        phone = phone.replace(/\(|\)| |-/gi, '')

        let masked = ''

        for(let i in phone){
            if(i === '0')
                masked += '('

            masked += phone[i]

            if(i === '1')
                masked += ') '
            if(phone.length === 10 && i === '5')
                masked += '-'
            if(phone.length === 11 && i === '6')
                masked += '-'
        }

        return masked
    },
    applyMaskOnchange: (inputTargetValue) => {
        let mask = inputTargetValue.replace(/\D/g, "");
        if(mask.length < 11)
            mask = mask.match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
        else
            mask = mask.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
        let maskedInput =
            mask[2] === ""
                ? mask[1]
                : "(" + mask[1] + ")" + mask[2] +
                    (mask[3] === ""
                    ? ""
                    : "-" + mask[3]);
        return maskedInput
    },

    removeMask: (phone) => {

        return phone.replace(/\(|\)| |-/gi, '')
    }

}

export default PhoneUtils