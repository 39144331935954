import React from 'react';
import { DefaultToast, useToasts } from 'react-toast-notifications';
import './custom-toast.css'

export const DismissAllToast = ({ children, ...props }) => {
  const { removeToast, toastStack } = useToasts()

  const dismissAll = () => {
    toastStack.forEach(toast => {
      removeToast(toast.id)
    })
  };

  return(<div className="dismiss-all-toast">
          <DefaultToast {...props}>
            <div className="toast-content">
              <div className="toast-text">
                {children}
              </div>
              <div className="txt-dismiss-all">
                {toastStack.length > 1 ? <span onClick={() => {dismissAll()}}>Fechar todos</span> : null}
              </div>
            </div>
              
          </DefaultToast>
        </div>);
}