import React, { useEffect } from 'react'
import SignalIcon from '../../components/icons/signal'
import './site-survey-card.css'

const ciphers = {
    0: 'Sem senha',
    1: 'WEP',
    2: 'WPA',
    3: 'WPA2',
    4: 'WPA/WPA2',
    5: 'WPA3',
    6: 'WPA2/WPA3'
}

const protocols = {
    0: 'Indefinido',
    1: '802.11b',
    2: '802.11g',
    3: '802.11n',
    4: '802.11b/g/n',
    5: '802.11ac',
    6: '802.11b/g',
    7: '802.11g/n',
    8: '802.11a',
    9: '802.11a/n',
    10: '802.11ac/n',
    11: '802.11a/ac',
    12: '802.11a/n/ac',
    13: '802.11b/g/n/ac'
}

export default function SiteSurveyCard({Ssid, Channel, Mac, Cipher, Protocol, SignalLevel, Id}) {

    useEffect(() => {
    }, [])

    return  <div id={Id} className='site-survey-card-container'>
                <div className='site-survey-title'>
                    {Ssid ? <div className='site-survey-card-title'><strong>{Ssid}</strong></div> : ''}
                </div>

                <div className='info-container'>
                    <div className='site-survey-main-info'>
                        {Channel ? <div className='site-survey-card-label'>Canal: {Channel}</div> : ''}
                        {Mac ? <div className='site-survey-card-label'>MAC: {Mac}</div> : ''}
                        {Cipher ? <div className='site-survey-card-label'>Segurança: {ciphers[Cipher]}</div> : ''}
                        {Protocol ? <div className='site-survey-card-label'>Protocolo: {protocols[Protocol]}</div> : ''}

                    </div>

                    <div className='sec-info-container'>
                        <div className='site-survey-icon'>
                            <SignalIcon signal_level={SignalLevel} size='30'></SignalIcon>
                        </div>

                        <div className='site-survey-sec-info'>
                            <div className='site-survey-card-label'><strong>Sinal</strong> {SignalLevel}dbm</div>
                        </div>
                    </div>
                    
                </div>
             </div>
}