import React, { useState, useEffect, useContext } from 'react'

import Input from '../../../components/input/input'
import Button from '../../../components/button/button'

import './firmware.css'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import DefaultStatusModals from '../../../components/modal/default-status-modals'
import MandatoryVersionUpdate from './mandatory-version'
import NotificationForm from './notification'
import RemotizeDevices from '../../remotize-devices'

import { BackendContext, backendStatus } from '../../../backend/backend'
import { UserContext } from '../../user-context'
import { useHistory } from 'react-router-dom'

const UploadFwPage = () => {

    const [model, setModel] = useState('GF1200')
    const [version, setVersion] = useState('')
    const [file, setFile] = useState('')

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [user,] = useContext(UserContext)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const history = useHistory()

    useEffect(() => {

        if(!user.superuser)
            history.push('/devices')


        header.title.set('Firmwares')
        // eslint-disable-next-line 
    }, [])

    const submit = async(e) => {
        e.preventDefault()

        //TODO: think in a generic way to return driver specific data

        setLoading(true)
        if (file.size === 0 || file.size === undefined ) {
            setLoading(false)
            setError(true)
            return
        }

        let result = await backend.create('firmwares', {
            model: model,
            version: version
        })

        console.log(result)

        let id = result.content.id
        let oldId = result.content.oldId 
        console.log('oldId',oldId)
        console.log('id'.id)


        result = await backend.upload('fwfile', 
            file,
            {'X-File-Id': id,'X-File-OldId':oldId,'X-Model':model}
        )

        if(result.status === backendStatus.SUCCESS){
            setLoading(false)
            setSuccess(true)
            return
        }

        setLoading(false)
        setError(true)

    }

    const continueFn = () => {
        setLoading(false)
        setSuccess(false)
        setError(false)
    }

    return <div id='upload-fw-page' className='page container'>

        <DefaultStatusModals
            success={success}
            saving={loading}
            error={error}
            successText='Firmware enviado com sucesso'
            savingText='Aguarde enquanto seu firmware está sendo enviado'
            errorText='Houve um erro ao enviar o firmware'
            continueFn={continueFn}
        ></DefaultStatusModals>

    <div className='adm-container'>


        <form className='upload-fw-form' onSubmit={submit}>

            <div className='subtitle'>Enviar um novo firmware</div>

            <Input id='fw-model'
                label='Versão'
                placeholder='1.0.0'
                value={version}
                onChange={(e) => setVersion(e.target.value.trim())}
            >
            </Input>

            <RemotizeDevices
                model={model}
                setModel={setModel}
            ></RemotizeDevices>

            <Input id='fw-model'
                className='itb-input'
                type='file'
                label='Modelo'
                onChange={(e) => setFile(e.target.files[0])}
            >
            </Input>

            <Button text='Salvar'></Button>

        </form>

        <MandatoryVersionUpdate></MandatoryVersionUpdate>
        <NotificationForm></NotificationForm>

        </div>
    </div>
}

export default UploadFwPage