const suspiciousRegexList = [
    "(`(.*?)`)",
    "(\\$\\((.*?)\\))",
    "(dev\\/)",
    "mtdblock",
    "reboot",
    "telnetd",
    "UDPserver",
    "flash default",
    "flash reset",
    "flash set",
    "passwd",
];

const weakPasswordList = [
    "admin123",
    "admin",
    "password",
    "qwerty1234",
    "qwerty123",
    "qwerty",
    "abc123",
    "123456789",
    "12345678",
    "abcdefgh",
    "11111111",
    "server123",
    "server",
    "root1234",
    "root",
    "senha12345",
    "router123",
    "router",
    "teste123",
    "teste",
    "        "
];

const suspiciousRegexListTranslate = {
    0: 'crase dupla', //"(`(.*?)`)"
    1: 'a expressão "$()"', //"(\\$\\((.*?)\\))"
    2: 'a palavra dev/', //"(dev\\/)"
    3: 'a palavra mtdblock',
    4: "a palavra reboot",
    5: "a palavra telnetd",
    6: "a palavra UDPserver",
    7: "a palavra flash default",
    8: "a palavra flash reset",
    9: "a palavra flash set",
    10: "a palavra passwd",
};

const CommonValidators = {
    required: async(value) => {

        return value === '' ? 'Este campo é obrigatório' : ''
    },

    alphaNumeric: async(value) => {
        
        if(!value.match(/^[0-9a-zA-Z]+$/))
            return 'Este campo deve conter apenas letras e números'
        
        return ''
    },

    lettersAndSpace: async(value) => {

        if(!value.match(/^[a-zA-Z|\s]+$/))
            return 'Este campo deve conter apenas letras'

        return ''
    },

    onlySpaceBetween: async(value) => {

        if (value.length > value.trim().length)
            return 'Não é permitido uso de espaço no começo e fim.'

        return ''
    },

    nonASCII: async(value) => {

        if(!value) return ''

        for(let i = 0; i < value.length; i++){

            let charCode = value.charCodeAt(i)

            if(charCode < 32 || charCode > 127)
                return 'Contém caracteres inválidos'
        }

        return ''
    },

    suspiciousString: async(value) => {

        if(!value) return ''
    
        let regex = new RegExp();
        
        for (let i = 0; i < suspiciousRegexList.length; i++) {
            regex = new RegExp(suspiciousRegexList[i], 'g');
            if (regex.exec(value)) {
                return `Esse campo não aceita ${suspiciousRegexListTranslate[i]}`;
            }
        }
    
        return ''
    },

    waekPasswordString: async(value) => {

        if(!value) return ''
    
        let regex = new RegExp();
        
        for (let i = 0; i < weakPasswordList.length; i++) {
            regex = new RegExp(weakPasswordList[i], 'g');
            if (regex.exec(value.toLowerCase())) {
                return `O uso da palavra (${weakPasswordList[i]}) não é recomendado pois encontra-se em uma lista de senhas conhecidas.`;
            }
        }
    
        return ''
    },

    size: async(value, {min,max}) => {

        if(value.length < min || value.length > max)
            return `O campo deve conter entre ${min} e ${max} caracteres`

        return ''
    },

    isNumber:  async(value) => {
        if(isNaN(value))
            return 'Valor deve ser numérico'

        return ''
    },

    anatelPasswordCriteria: async(value) => {

        const hasLowercase = (/[a-z]/.test(value))
        const hasUppercase = (/[A-Z]/.test(value))
        const hasNumber = (/[0-9]/.test(value))
        const hasMinCharacters = (value.length >= 8)

        if (hasLowercase && hasUppercase && hasNumber && hasMinCharacters){
            return ''
        } else {
            return 'Não é permitido o uso de senha fraca'
        }
    },

    value: async(value, {min,max}) => {
        if(((min || min === 0) && value < min) || ((max || max === 0) && value > max)) {
            if (!min && min !== 0) return `O campo deve possuir valor menor ou igual a ${max}`
            else if (!max && max !== 0) return `O campo deve possuir valor maior ou igual a ${min}`
            else return `O campo deve possuir valor entre ${min} e ${max}`
        }

        return ''
    }

}

export default CommonValidators