import React from 'react'
import ChevronRightIcon from '../icons/chevron-right'

import './router-button.css'

export default function RouterButton({id, label, description, onClick}) {

    return(
    <div className='router-button' id={id} onClick={onClick}>
        <label>
            {label}
            <div className='router-button-description'>{description}</div>
        </label>
        <ChevronRightIcon size='20'></ChevronRightIcon>
    </div>
    )

}