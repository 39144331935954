import React, { useEffect, useState } from 'react'
import List, { ListOrderStates } from '../../../../../../components/list/list'

const WifiSiteSurveyOpModes = {
    0: 'ND',
    1: 'B',
    2: 'G',
    3: 'N',
    4: 'BGN',
    5: 'AC',
    6: 'BG',
    7: 'GN',
    8: 'A',
    9: 'AN',
    10: 'ACN',
    11: 'A/AC',
    12: 'A/N/AC',
    13: 'B/G/N/AC'

}

const WifiRadioBandwidth = {
    0: '20MHz',
    1: '40MHz',
    2: '80MHz',
    3: '160MHz'
}

export default function SiteSurveyList({networks, is5GHz}) {

    const [localList, setLocalList] = useState(null)
    const [orderSignal, setOrderSignal] = useState(ListOrderStates.NONE)
    const [orderChannel, setOrderChannel] = useState(ListOrderStates.NONE)

    useEffect(() => {

        setLocalList([...networks])

        // eslint-disable-next-line
    }, [networks])

    useEffect(() => {

        if(!localList) return

        orderBySignal()

        // eslint-disable-next-line
    }, [orderSignal])

    useEffect(() => {

        if(!localList) return

        orderByChannel()

        // eslint-disable-next-line
    }, [orderChannel])

    const orderBySignal = () => {

        let newList = localList.sort((a, b) => {
            switch(orderSignal){
                case ListOrderStates.UP:
                    return a.signal_level - b.signal_level
                case ListOrderStates.DOWN:
                    return b.signal_level - a.signal_level
                default:
                    return 0
            }
        })

        setLocalList([...newList])

    }

    const orderByChannel = () => {

        let newList = localList.sort((a, b) => {
            switch(orderChannel){
                case ListOrderStates.UP:
                    return b.channel - a.channel
                case ListOrderStates.DOWN:
                    return a.channel - b.channel
                default:
                    return 0
            }
        })

        setLocalList([...newList])

    }


    const getLines = () => {

        if(!localList) return []

        let lines = []
        for(let net of localList) {
            if((is5GHz && net.channel > 13) || (!is5GHz && net.channel <= 13)){
                lines.push([
                    net.ssid,
                    WifiRadioBandwidth[net.bandwidth],
                    net.channel,
                    !net.extension ? 'Acima' : 'Abaixo',
                    WifiSiteSurveyOpModes[net.op_mode],
                    `${net.signal_level} dBm`
                ])
            }
        }

        return lines
    }

    return <List
        columns={[
            {header: 'Nome'},
            {header: 'Largura'},
            {header: 'Canal', orderable: true, orderFn: (state) => setOrderChannel(state)},
            {header: 'Extensão'},
            {header: 'Modo'},
            {header: 'Sinal', orderable: true, orderFn: (state) => setOrderSignal(state)}
        ]}
        lines={getLines()}
    ></List>
}