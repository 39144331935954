import React, { useState } from 'react'
import Input from '../input/input'
import SearchIcon from '../icons/search-icon'

import './select-search.css'

export default function SelectSearch({id, label, placeholder, value, options, onSelect}){

    const [focus, setFocus] = useState(false)
    const [search, setSearch] =useState('')

    const getOptionText = () => {
        for(let opt of options) {
            if(opt.value === value)
                return opt.text
        }

        return ''
    }

    const getFilteredOptions = () => {
        return options.filter(opt => opt.text.includes(search))
    }

    return <div className='select-search'>

        <Input
            id={id || ''}
            label={label}
            value={focus ? search : getOptionText()}
            placeholder={placeholder}
            onFocus={() => setFocus(true)}
            onBlur={() => setTimeout(setFocus, 250, false)}
            onChange={e => setSearch(e.target.value)}
            autoComplete='off'
        ></Input>

        <SearchIcon size='20'></SearchIcon>

        {options && focus && <div className='select-search-options'>
            {getFilteredOptions().map(opt => {
                return <span key={`opt-${opt.value}`} onClick={() => {
                    onSelect(opt.value)
                }}>{opt.text}</span>
            })}
        </div>}

    </div>
}