import React from 'react'
import Devices from './devices/devices'



export default function GatewayManagePage (){

    return(<div id="device-management-page">
        <Devices />
            </div>)
   
}