import React from "react";
import common from "../../../components/form/validators/common";
import network from '../../../components/form/validators/network';
import UploadIcon from "../../../components/icons/upload";
import Input from "../../../components/input/input";
import Checkbox from "../../../components/checkbox/checkbox";
import { useState } from "react";

const MAXSIZEFILE = 4096;
const MIN_NOTIFY_INTERVAL = 60;
const MAX_NOTIFY_INTERVAL = 43200;



export default function ProfileTR069({ tr069, setTr069, remoteAccess }) {

    let [file, setFile] = useState(null)

    const readFileAsync = file2read => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = async file => {
                const text = file.target.result;
                resolve(text ?? '');
            };

            reader.onerror = () => {
                alert(`${30}_FILE_IS_TOO_LARGE_ERROR_MESSAGE`);
                reject();
            };

            reader.readAsText(file2read);
        });
    };

    const handleNotifyInterval = (e) => {

        let value = e.target.value

        if (isNaN(value)) return

        let intervalTime = Number(value)

        setTr069({ ...tr069, acs: { ...tr069.acs, tr069_interval: intervalTime } })
    }

    const validateNotifyInterval = (value) => {

        if (Number(value) === 0) {
            return ''
        }

        if (Number(value) < MIN_NOTIFY_INTERVAL) {
            return 'O valor mínimo é de 60 segundos'
        }

        if (Number(value) > MAX_NOTIFY_INTERVAL) {
            return 'O valor máximo é de 43200 segundos'
        }

        return ''
    }

    const handleFileSelected = evt => {
        const fileSelection = evt?.target?.files;
        if (!fileSelection || fileSelection.length === 0) {
            return;
        }

        if (fileSelection[0].size < MAXSIZEFILE) {
            return fileSelection[0];
        } else {
            alert(`${30}_FILE_IS_TOO_LARGE_ERROR_MESSAGE`);
        }
    };

    const validateconnPort = (value) => {
        if (80 === parseInt(value)) {
            return `Porta ${value} reserva para gerenciamento Interno`
        }

        if (remoteAccess.enabled && remoteAccess.port === parseInt(value)) {
            return `Porta ${value} reserva para gerenciamento Remoto`
        }

        return ''
    }

    const isValidURLWithPort = (url) => {
        if (!(url.startsWith('http://') || url.startsWith('https://'))) {
            return 'Apenas URL com "http://" ou "https://';
        }

        // Remove o "http://" ou "https://" da URL para facilitar a verificação da porta
        let urlWithoutProtocol = url.replace(/^https?:\/\//, '');
        const portIndex = urlWithoutProtocol.lastIndexOf(':');

        if (portIndex !== -1) {
            // urlWithoutProtocol = urlWithoutProtocol.split('/acs').join('')
            let fix = urlWithoutProtocol.split('/')
            if (fix.length > 0) {
                urlWithoutProtocol = fix[0]
            }
        }

        if (portIndex === -1 || isNaN(urlWithoutProtocol.slice(portIndex + 1))) {
            return 'Verifica se a URL possui uma porta válida';
        }

        const path = urlWithoutProtocol.slice(portIndex + 1);
        if (path.trim() === '') {
            return 'Verifica se a URL tem um caminho válido após a porta';
        }

        return '';
    }

    return [
        <div>
            <Checkbox
                label="Habilitar TR-069"
                id='tr069'
                value={tr069.tr069_enable}
                toggleFn={() => setTr069({ ...tr069, tr069_enable: !tr069.tr069_enable })}
            ></Checkbox>
        </div>,
        <div>
            {!tr069.tr069_enable ? null :
                <React.Fragment>

                    <Input id='acs-url' label='URL ACS'
                        name="acs-url"
                        value={tr069.acs.acs_url}
                        onChange={(e) =>
                            setTr069({ ...tr069, acs: { ...tr069.acs, acs_url: e.target.value.trim() } })
                        }
                        collapse={!tr069.tr069_enable}
                        validators={[common.required, isValidURLWithPort]}
                    ></Input>

                    <Input id='acs-username' label='Nome de usuário do ACS'
                        name="acs-username"
                        value={tr069.acs.acs_username}
                        onChange={(e) =>
                            setTr069({ ...tr069, acs: { ...tr069.acs, acs_username: e.target.value } })
                        }
                        collapse={!tr069.tr069_enable}
                        validators={[
                            common.required, common.nonASCII
                        ]}
                    ></Input>

                    <Input id='acs-pass' label='Senha do ACS'
                        name="acs-pass"
                        type='password'
                        value={tr069.acs.acs_password}
                        onChange={(e) =>
                            setTr069({ ...tr069, acs: { ...tr069.acs, acs_password: e.target.value.trim() } })
                        }
                        collapse={!tr069.tr069_enable}
                        validators={[
                            common.required, common.nonASCII,
                            { fn: common.size, params: { min: 1, max: 30 } },
                        ]}
                    ></Input>

                    <Checkbox
                        label="Habilitar Notificação Programada"
                        id='tr069-inform'
                        value={tr069.acs.tr069_inform}
                        toggleFn={() => {
                            if (tr069.acs.tr069_inform) {
                                setTr069({ ...tr069, acs: { ...tr069.acs, tr069_inform: false, tr069_interval: 0 } })
                            } else {
                                setTr069({ ...tr069, acs: { ...tr069.acs, tr069_inform: true } })
                            }
                        }}
                    ></Checkbox>
                    {
                        !tr069.acs.tr069_inform ? null :

                            <Input id='acs-interval' label='Intervalo de notificação programada'
                                name="acs-interval"
                                value={tr069.acs.tr069_interval}
                                onChange={handleNotifyInterval}
                                validators={[
                                    common.required, validateNotifyInterval
                                ]}
                            ></Input>
                    }
                    {<Checkbox
                        label="Solicitar conexão"
                        id='tr069-conn'
                        value={tr069.connection.enabled}
                        toggleFn={() => {
                            setTr069({ ...tr069, connection: { ...tr069.connection, enabled: !tr069.connection.enabled } })
                        }}
                    ></Checkbox>}

                    {!tr069.connection.enabled ? null :

                        <React.Fragment>
                            <Input id='conn-user' label='Usuário da solicitação de conexão'
                                name="conn-user"
                                value={tr069.connection.conreq_name}
                                onChange={(e) =>
                                    setTr069({ ...tr069, connection: { ...tr069.connection, conreq_name: e.target.value } })
                                }
                                validators={[
                                    common.required, common.nonASCII
                                ]}
                            ></Input>

                            <Input id='conn-pass' label='Senha da solicitação de conexão'
                                name="conn-pass"
                                type='password'
                                value={tr069.connection.conreq_pw}
                                onChange={(e) =>
                                    setTr069({ ...tr069, connection: { ...tr069.connection, conreq_pw: e.target.value.trim() } })
                                }
                                validators={[
                                    common.required, common.nonASCII,
                                    { fn: common.size, params: { min: 1, max: 30 } },
                                ]}
                            ></Input>

                            <Input id='conn-port' label='Porta da solicitação de conexão'
                                name="conn-port"
                                value={tr069.connection.conreq_port}
                                maxLength='5'
                                onChange={(e) => {
                                    if (isNaN(e.target.value)) return
                                    setTr069({ ...tr069, connection: { ...tr069.connection, conreq_port: Number(e.target.value) } })
                                }}
                                validators={[
                                    common.required,
                                    validateconnPort,
                                    network.port
                                ]}

                            ></Input>

                            <Input id='conn-path' label='Endereço da solicitação de conexão'
                                name="conn-path"
                                value={tr069.connection.conreq_path}
                                onChange={(e) =>
                                    setTr069({ ...tr069, connection: { ...tr069.connection, conreq_path: e.target.value } })
                                }
                                validators={[
                                    common.required, common.nonASCII
                                ]}
                            ></Input>

                        </React.Fragment>

                    }
                    <Checkbox
                        label="Ativar STUN"
                        id={'enable-stun'}
                        value={tr069.stun.enabled}
                        toggleFn={() => {
                            setTr069({ ...tr069, stun: { ...tr069.stun, enabled: !tr069.stun.enabled } })
                        }}
                    ></Checkbox>
                    {
                        !tr069.stun.enabled ? null :
                            <React.Fragment>
                                <Input id='stun' label='Endereço de Servidor STUN'
                                    name="stun-addr"
                                    value={tr069.stun.stun_server_addr}
                                    onChange={(e) =>
                                        setTr069({ ...tr069, stun: { ...tr069.stun, stun_server_addr: e.target.value } })
                                    }
                                    validators={[
                                        common.required, common.nonASCII
                                    ]}
                                ></Input>


                                <Input id='stun' label='Porta do Servidor STUN'
                                    name="stun-port"
                                    value={tr069.stun.stun_server_port}
                                    maxLength='5'
                                    onChange={(e) =>
                                        setTr069({ ...tr069, stun: { ...tr069.stun, stun_server_port: Number(e.target.value) } })
                                    }
                                    validators={[
                                        common.required, network.port
                                    ]}
                                ></Input>

                                <div className='upgrade-selector'>

                                    <label>
                                        <UploadIcon size='30'></UploadIcon>
                                        <span>{file ? file.name : 'Selecione o certificado CA'}</span>
                                        <input id='file-upload'
                                            type='file'
                                            onChange={async (e) => {
                                                const file = handleFileSelected(e);
                                                e.target.value = null;
                                                setFile(file);
                                                const text = await readFileAsync(file);
                                                setTr069({ ...tr069, ca_cert: text })
                                            }}
                                        ></input>
                                    </label>
                                </div>

                            </React.Fragment>
                    }
                </React.Fragment>
            }
        </div >
    ]
}