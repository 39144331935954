export const RadioOpMode = {
    A: 0,
    B: 1,
    G: 2,
    N: 3,
    BGN: 4,
    AC: 5,
    ANAC: 6
}

export const RadioChannelExtension = {
    UPPER: 0,
    LOWER: 1
}

export const RadioFrequency = {
    _2_4GHz: 0,
    _5GHz: 1
}

export const RadioBandwidth = {
    _20MHz: 0,
    _40MHz: 1,
    _80MHz: 2,
    _160MHz: 3
}

export const WirelessMode = {
    AP: 0,
    CLIENT: 1,
}

export const WirelessCipher = {
    NONE: 0,
    WEP: 1,
    WPA: 2,
    WPA2: 3,
    WPA_WPA2: 4,
    WPA3: 5,
    WPA2_WPA3: 6
}

export const WirelessCrypto = {
    NONE: 0,
    TKIP: 1,
    AES: 2,
    TKIP_AES: 3,
}

export function channelExtension(base) {
    let extension = []

    if (base >= 5) {
        extension.push({ value: 1, text: 'Abaixo' })
    }
    if (base <= 9) {
        extension.push({ value: 0, text: 'Acima' })
    }

    return extension
}


export function getDefaultFreqConfig(radio5GHzBandwidth) {
    let config = {
        _2_4GHz: {
            channels: [...Array(14).keys()].map(x => ({ value: x, text: x === 0 ? "Automático" : x.toString() })),
            bandwidth: ["20MHz", "20/40MHz"].map((v, i) => ({ value: i, text: v.toString() })),
            extension: ["Acima", "Abaixo"].map((v, i) => ({ value: i, text: v.toString() })),
        },
        _5GHz: {
            channels: [0, 36, 40, 44, 48, 149, 153, 157, 161]
                .map(v => ({ value: v, text: v === 0 ? "Automático" : v.toString() })),
            bandwidth: ["20MHz", "20/40MHz", "20/40/80MHz"].map((v, i) => ({ value: i, text: v.toString() }))
        }
    }

    if (radio5GHzBandwidth === RadioBandwidth._20MHz) {
        //  let i = config._5GHz.channels.length - 1
        config._5GHz.channels.push({ value: 165, text: "165" })
    }

    return config
}