import { toInt } from "../../utils/iputils"

const MIN_IP4_MTU = 68
const MAX_IP4_MTU = 1500

const MAX_PPPOE_MTU = 1492

const IP4_ERROR_MESSAGE = 'Endereço inválido'
const NETMASK_ERROR_MESSAGE = 'Máscara inválida'
const MAC_ERROR_MESSAGE = 'Endereço MAC inválido'

const MAX_PORT_VALUE = 65535

const NetworkValidators = {

    ip4: (value) => {

        if(value === '')
            return ''

        let regex = RegExp('^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$')

        if(!regex.test(value))
            return IP4_ERROR_MESSAGE

        if(value.substring(0,3) === '127' )
            return IP4_ERROR_MESSAGE

        if(value.substring(0,2) === '0.' )
            return IP4_ERROR_MESSAGE

        let splitedValue = value.split('.')
        if(!splitedValue[2].replaceAll('0', '') && !splitedValue[3].replaceAll('0', ''))
            return IP4_ERROR_MESSAGE

        for (let i = 0; i < splitedValue.length; i++) {
            let subValue = splitedValue[i]
            if (subValue.length > 1 && subValue.charAt(0) === '0')
                return IP4_ERROR_MESSAGE
        }

        if(value === '255.255.255.255')
            return IP4_ERROR_MESSAGE

        return ''

    },

    ip6: (value) => {
        let regex = RegExp('(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))')

        if(!regex.test(value))
            return IP4_ERROR_MESSAGE

        return ''
    },

    mac: (value) => {
        let regex = RegExp('^(([A-Fa-f0-9]{2}[:]?){5}[A-Fa-f0-9]{2}[,]?)+$')

        if(!regex.test(value))
            return MAC_ERROR_MESSAGE
        
        let mac = value.split(":").join("")

        if(mac === 'ffffffffff' || mac === '0000000000')
            return MAC_ERROR_MESSAGE
           
        return ''
    },

    netmask: (value) => {

        let regex = RegExp(`^(((255.){3}(255|254|252|248|240|224|192|128|0+))|((255.){2}(255|254|252|248|240|224|192|128|0+).0)|((255.)(255|254|252|248|240|224|192|128|0+)(.0+){2})|((255|254|252|248|240|224|192|128|0+)(.0+){3}))$`)

        if(!regex.test(value))
            return NETMASK_ERROR_MESSAGE

        if(value === '0.0.0.0' || value === '255.255.255.255')
            return NETMASK_ERROR_MESSAGE

        let splitedValue = value.split('.')
        for (let i = 0; i < splitedValue.length; i++) {
            let subValue = splitedValue[i]
            if (subValue.length > 1 && subValue.charAt(0) === '0')
                return NETMASK_ERROR_MESSAGE
        }

        return ''

    },

    mtu: (value) => {

        if(value < MIN_IP4_MTU || value > MAX_IP4_MTU)
            return `O valor da MTU deve estar entre ${MIN_IP4_MTU} e ${MAX_IP4_MTU}`

        return ''
    },

    pppoeMtu: (value) => {

        if(value < MIN_IP4_MTU|| value > MAX_PPPOE_MTU)
            return `O valor da MTU deve estar entre ${MIN_IP4_MTU} e ${MAX_PPPOE_MTU}`

        return ''
    },

    port: (value) => {
        if(value < 1 || value > 65535){
            return `Portas devem ter valores entre 1 e ${MAX_PORT_VALUE}`
        }

        return ''
    },

    sameNetwork: (ipCheck, {network, ip}) => {
        let ipOctets = ip.split('.')
        let ipCheckOctets = ipCheck.split('.')
        let netmaskOctets = network.split('.')

        ipOctets = ipOctets.map((value) => { return Number(value) })
        let ipInt = 0;
        for (let i = 0; i < 4; i++)
            ipInt += ipOctets[i] << (24 - 8 * i)

        ipCheckOctets = ipCheckOctets.map((value) => { return Number(value) })
        let ipCheckInt = 0;
        for (let i = 0; i < 4; i++)
            ipCheckInt += ipCheckOctets[i] << (24 - 8 * i)

        netmaskOctets = netmaskOctets.map((value) => { return Number(value) })
        let netInt = 0;
        for (let i = 0; i < 4; i++)
            netInt += netmaskOctets[i] << (24 - 8 * i)

        let ipNet = ipInt & netInt
        let ipCheckNet = ipCheckInt & netInt

        return ipNet === ipCheckNet ? '' : 'Este endereço não pertence à rede'
    },

    ipGreaterThan: (value, {checkAddress}) => {
        if (!value || !checkAddress) return ''

        let inputAddress = toInt(value)
        let checkAddr = toInt(checkAddress)

        if(inputAddress < checkAddr){
            return 'O endereço final não pode ser menor que o inicial.'
        }

        return ''
    },

    ipLessThan: (value, {checkAddress}) => {
        if (!value || !checkAddress) return ''

        let inputAddress = toInt(value)
        let checkAddr = toInt(checkAddress)

        if(inputAddress > checkAddr){
            return 'O endereço inicial não pode ser maior que o final.'
        }

        return ''
    },

    broadcastAddress: (value, {netmask}) => {

        let address = toInt(value)
        let netmaskInt = toInt(netmask)

        if (~netmaskInt === 1) return ''

        let check = address & ~netmaskInt

        if(check === ~netmaskInt){
            return 'Este endereço não pode ser utilizado'
        }

        return ''
    },

    identificatorAddress: (value, {netmask}) => {
        let address = toInt(value)
        let netmaskInt = toInt(netmask)

        if (~netmaskInt === 1) return ''

        let check = address & ~netmaskInt

        if(check === 0){
            return 'Este endereço não pode ser utilizado'
        }

        return ''
    },

    hostname: async(value) => {
        const re = /^[A-Za-z0-9.:\-_]{0,}$/

        if (!re.exec(value)) {
            return 'Contém caracteres inválidos'
        }

        return ''
    }

}

export default NetworkValidators