import React from 'react'

import MaintenanceIcon from './maintenance-icon'

import './maintenance.css'

export default function MaintenancePage() {

    return <div id='maintenance-page'>
        <MaintenanceIcon></MaintenanceIcon>
        <div id='maintenance-text'>
            <h2>Estamos em manutenção para que o Remotize fique ainda melhor. <br />
            Isso vai demorar algumas horas, combinado?</h2>
        </div>
    </div>
}