import React, { useContext, useEffect, useState } from 'react'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Button from '../../../components/button/button'
import Input from '../../../components/input/input'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import DefaultStatusModals from '../../../components/modal/default-status-modals'

import './lgpd.css'

export default function LGPDAdminPage() {

    const [terms, setTerms] = useState(null)
    const [privacy, setPrivacy] = useState(null)
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)

    useEffect(() => {

        header.title.set('LGPD')

        // eslint-disable-next-line
    }, [])

    const submit = async(documentType) => {

        setSaving(true)

        let file = documentType === 'terms_of_use' ? terms : privacy

        let result = await backend.upload(`lgpd/documents/${documentType}`, file)

        setSaving(false)

        if(result.status === backendStatus.SUCCESS){
            setSuccess(true)
            return
        }

        setError(true)
    }

    const dismissModal = () => {
        setSuccess(false)
        setError(false)
        setSaving(false)
    }

    return <div id='lgpd-admin-page' className='page'>

        <DefaultStatusModals
            saving={saving}
            success={success}
            error={error}
            continueFn={dismissModal}
        ></DefaultStatusModals>

        <section>

            <div className='subtitle'>
                Termos de uso
            </div>

            <form id='terms-of-use-form' onSubmit={(e) => {
                e.preventDefault()
                submit('terms_of_use')
            }}>

                <Input id='terms-of-use'
                    className='itb-input'
                    type='file'
                    label='Termos de uso'
                    onChange={(e) => setTerms(e.target.files[0])}
                >
                </Input>

                <Button text='Enviar'></Button>

            </form>

        </section>

        <section>

            <div className='subtitle'>
                Política de privacidade
            </div>

            <form id='privacy-form' onSubmit={(e) => {
                e.preventDefault()
                submit('privacy_policy')
            }}>

                <Input id='privacy'
                    className='itb-input'
                    type='file'
                    label='Política de privacidade'
                    onChange={(e) => setPrivacy(e.target.files[0])}
                >
                </Input>

                <Button text='Enviar'></Button>

            </form>

        </section>

    </div>
}