import React, { useEffect, useContext, useState } from 'react'

import { MenuContext } from '../components/menu/menu-state'
import { BackendContext } from '../backend/backend'

import isElectron from './server/is-electron'
import { useHistory, useLocation } from 'react-router-dom'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

import Loading from '../components/loading/loading'

const isGLogin = window.location.href.includes('glogin') || isElectron()

export const FirebaseContext = React.createContext()

export default function Firebase({children}) {

    const [authenticated, setAuthenticated] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [firebaseUser, setFirebaseUser] = useState(null)
    const backend = useContext(BackendContext)
    const location = useLocation()
    const history = useHistory()
    const menu = useContext(MenuContext)

    useEffect(() => {

        if(isGLogin || isElectron())
            return

        if(firebase.apps.length === 0) {
            var firebaseConfig = {
                apiKey: "AIzaSyB0lzGtIiWlSlp3EwxY5dtIA2vICUGbQfU",
                authDomain: "remotize-f33d8.firebaseapp.com",
                databaseURL: "https://remotize-f33d8.firebaseio.com",
                projectId: "remotize-f33d8",
                storageBucket: "remotize-f33d8.appspot.com",
                messagingSenderId: "287007444250",
                appId: "1:287007444250:web:b9062397929f576666b0a3",
                measurementId: "G-2V73CT6BTF"
            };

            firebase.initializeApp(firebaseConfig);

            firebase.analytics();

            setInitialized(true)

            let refreshInterval = null

            firebase.auth().onAuthStateChanged(async(user) => {

                if (history.location.pathname === '/faq')
                    return

                if(!user){
                    clearInterval(refreshInterval)
                    history.push('/login')
                    return
                }

                setFirebaseUser(user)

                let token = await user.getIdToken(true)

                if(user.providerData.length !== 0 && user.providerData[0].providerId === "google.com") {
                    localStorage.setItem('user_id', user.providerData[0].uid)
                }else{
                    localStorage.setItem('user_id', user.uid)
                }

                localStorage.setItem('username', user.displayName)
                menu.username.set(user.displayName)
                menu.avatar.set(user.photoURL)
                backend.setAuthInfo({token: token})

                setTimeout(() => setAuthenticated(true))

                refreshInterval = runRefreshTokenInterval(user)

            })

        }

        // eslint-disable-next-line
    },[])

    const runRefreshTokenInterval = (user) => {

        return setInterval(async() => {
            let token = await user.getIdToken(true)
            backend.setAuthInfo({token: token})
        }, 1800000)
    }

    const getToken = async() => {
        return await firebaseUser.getIdToken()
    }

    const signOut = async() => {
        await firebase.auth().signOut()
        setAuthenticated(false)
    }

    let isLogin = location.pathname === '/login'
    let isFaq = location.pathname === '/faq'

    return isGLogin || isElectron() ? children : <FirebaseContext.Provider value={{
                initialized: initialized,
                signOut: signOut,
                getToken: getToken
            }}>
                {!authenticated && !isLogin && !isFaq ? <Loading show={true}></Loading> : children}
           </FirebaseContext.Provider>
}
