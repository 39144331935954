import React, { useEffect, useState } from 'react'
import Input from '../../../components/input/input';
import Checkbox from '../../../components/checkbox/checkbox';
import common from '../../../components/form/validators/common'
import InfoPopup from '../../../components/info-popup/info-popup'
import NetworksIcon from '../../../components/icons/networks-icon'
import Select from '../../../components/select/select'
import { channelExtension, getDefaultFreqConfig } from '../../../app/pages/devices/management/bifrost/wifi/wifi-constants.js'
import { RadioBandwidth } from '../devices/management/tr069/wifi/wifi-constants';

const WIRELESS_CIPHER = {
    NONE: 0,
    WEP: 1,
    WPA: 2,
    WPA2: 3,
    WPA_WPA2: 4
}

const WIRELESS_CRYPTO = {
    NONE: 0,
    TKIP: 1,
    AES: 2,
    TKIP_AES: 3
}

const getChannelExtension = (channel, current) => {
    var result = channelExtension(channel)

    if (result.length === 1) {
        return { index: result[0].value, lock: true }
    }

    return { index: current, lock: false }
}

export default function ProfileWireless({ wlan, setWlan, radio, setRadio, editedProfile, mesh, errors, setErrors }) {

    const [lockFieldExtensionChannel, setLockFieldExtensionChannel] = useState(false)
    const [showFieldExtensionChannel, setShowFieldExtensionChannel] = useState(false)
    const [defaultFreqValues, setDefaultFreqValues] = useState(getDefaultFreqConfig(RadioBandwidth._20MHz))

    useEffect(() => {

        let isChannelAutomatic = (parseInt(radio.freq_2_4G.channel) !== 0)
        let isbandwidthCompatible = (parseInt(radio.freq_2_4G.bandwidth) === 1)
        setShowFieldExtensionChannel(isChannelAutomatic && isbandwidthCompatible)

        let config = getDefaultFreqConfig(radio.freq_5G.bandwidth)
        setDefaultFreqValues({ ...config })

    }, [radio])

    useEffect(() => {
        if (editedProfile && !editedProfile?.wireless?.useCompatibilityMode) {
            wlan.cipher = editedProfile.wireless.cipher
            wlan.crypto = editedProfile.wireless.crypto
            wlan.key = editedProfile.wireless.key
            wlan.ssid = editedProfile.wireless.ssid

            wlan.freq_2_4G.cipher = editedProfile.wireless.cipher
            wlan.freq_2_4G.crypto = editedProfile.wireless.crypto
            wlan.freq_2_4G.key = editedProfile.wireless.key
            wlan.freq_2_4G.ssid = editedProfile.wireless.ssid

            wlan.freq_5G.cipher = editedProfile.wireless.cipher
            wlan.freq_5G.crypto = editedProfile.wireless.crypto
            wlan.freq_5G.key = editedProfile.wireless.key
            wlan.freq_5G.ssid = editedProfile.wireless.ssid
            setWlan({ ...wlan })

        }

    }, [])

    function handle2_4GhzSSIDInput(e) {

        let value = e.target.value
        wlan.freq_2_4G.ssid = value
        wlan.freq_2_4G.cipher = WIRELESS_CIPHER.WPA2
        wlan.freq_2_4G.crypto = WIRELESS_CRYPTO.AES

        if (wlan.enableDualBand) {
            wlan.freq_5G.ssid = value
            wlan.freq_5G.cipher = WIRELESS_CIPHER.WPA2
            wlan.freq_5G.crypto = WIRELESS_CRYPTO.AES
        }

        setWlan({ ...wlan })
    }


    function handle5GhzSSIDInput(e) {

        let value = e.target.value
        wlan.freq_5G.ssid = value
        wlan.freq_5G.cipher = WIRELESS_CIPHER.WPA2
        wlan.freq_5G.crypto = WIRELESS_CRYPTO.AES
        setWlan({ ...wlan })
    }

    function handle2_4GHzKeyInput(e) {

        let value = e.target.value
        wlan.freq_2_4G.key = value

        if (wlan.enableDualBand) {
            wlan.freq_5G.key = value
        }

        setWlan({ ...wlan })
    }

    function handle5GHzKeyInput(e) {

        let value = e.target.value
        wlan.freq_5G.key = value
        setWlan({ ...wlan })
    }

    function handleMacInSSID() {
        let value = !wlan.freq_5G.MacInSSID
        wlan.freq_2_4G.MacInSSID = value
        wlan.freq_5G.MacInSSID = value
        wlan.MacInSSID = value
        setWlan({ ...wlan })
    }

    function handleDualband() {
        if (!wlan.enableDualBand) {
            wlan.freq_5G.ssid = wlan.freq_2_4G.ssid
            wlan.freq_5G.key = wlan.freq_2_4G.key
            wlan.freq_5G.cipher = wlan.freq_2_4G.cipher
            wlan.freq_5G.crypto = wlan.freq_2_4G.crypto
        }

        wlan.enableDualBand = !wlan.enableDualBand
        setWlan({ ...wlan })
    }

    function tempWirelessWithoutSpaces(value) {
        if (value.charAt(0) === ' ' || value.charAt(value.length - 1) === ' ')
            return 'Este campo não pode começar ou terminar com espaços'

        return ''
    }

    function handleEnableConfigureRadio() {
        radio.enableConfigureRadio = !radio?.enableConfigureRadio
        setRadio({ ...radio })
    }

    function handle2_4GHzChannelInput(e) {
        let channelExtension = getChannelExtension(e.target.value, radio.freq_2_4G.extension)

        radio.freq_2_4G.extension = channelExtension.index
        setLockFieldExtensionChannel(channelExtension.lock)

        radio.freq_2_4G.channel = parseInt(e.target.value)
        radio.freq_2_4G.configuredChannel = parseInt(e.target.value)
        setRadio({ ...radio })
    }

    function handle2_4GHzbandwidthInput(e) {
        radio.freq_2_4G.bandwidth = parseInt(e.target.value)
        setRadio({ ...radio })
    }

    function handle2_4GHzExtensionChannelInput(e) {
        radio.freq_2_4G.extension = parseInt(e.target.value)
        setRadio({ ...radio })
    }

    function handle5GHzChannelInput(e) {
        radio.freq_5G.channel = parseInt(e.target.value)
        radio.freq_5G.configuredChannel = parseInt(e.target.value)
        setRadio({ ...radio })
    }

    function handle5GHzbandwidthInput(e) {
        radio.freq_5G.bandwidth = parseInt(e.target.value)
        setRadio({ ...radio })
    }

    return [

        (!wlan.enableDualBand) ?

            <div className="wireless-configuration">
                <div key={`wireless-configuration-2_4Ghz`} className='form-section-wireless-configuration-2_4Ghz'>

                    <div className='dashboard-subtitle'>
                        <NetworksIcon size='20'></NetworksIcon>
                        <label>Rede 2.4 GHz</label>
                    </div>

                    <Input id='ssid-2_4Ghz'
                        label='Nome da rede'
                        value={wlan.freq_2_4G.ssid}
                        name='ssid-2_4Ghz'
                        onChange={handle2_4GhzSSIDInput}
                        validators={[
                            common.required,
                            common.nonASCII,
                            tempWirelessWithoutSpaces,
                            { fn: common.size, params: { min: 1, max: (wlan.freq_2_4G.MacInSSID ? 24 : 29) } }
                        ]}
                        key='wlan-0-ssid'
                    ></Input>

                    <Input id='key-2_4Ghz'
                        label='Senha da rede'
                        value={wlan.freq_2_4G.key}
                        name='key-2_4Ghz'
                        onChange={handle2_4GHzKeyInput}
                        validators={[common.required, common.nonASCII, { fn: common.size, params: { min: 8, max: 63 } }]}
                        key='wlan-0-key'
                    ></Input>
                </div>

                <div key={`wireless-configuration-5Ghz`} className='form-section-wireless-configuration-5Ghz'>
                    <div className='dashboard-subtitle'>
                        <NetworksIcon size='20'></NetworksIcon>
                        <label>Rede 5 GHz</label>
                    </div>

                    <Input id='ssid-5Ghz'
                        label='Nome da rede'
                        value={wlan.freq_5G.ssid}
                        name='ssid-5Ghz'
                        onChange={handle5GhzSSIDInput}
                        validators={[
                            common.required,
                            common.nonASCII,
                            tempWirelessWithoutSpaces,
                            { fn: common.size, params: { min: 1, max: (wlan.freq_5G.MacInSSID ? 24 : 29) } }
                        ]}
                        key='wlan-1-ssid'
                    ></Input>

                    <Input id='key-5Ghz'
                        label='Senha da rede'
                        value={wlan.freq_5G.key}
                        name='key-1-5Ghz'
                        onChange={handle5GHzKeyInput}
                        validators={[common.required, common.nonASCII, { fn: common.size, params: { min: 8, max: 63 } }]}
                        key='wlan-1-key'
                    ></Input>
                </div>
            </div> :
            <div className="wireless-configuration">
                <div key={`wireless-configuration-dual`} className='form-section-wireless-configuration-dual'>

                    <div className='dashboard-subtitle'>
                        <NetworksIcon size='20'></NetworksIcon>
                        <label>Rede em modo dualband</label>
                    </div>

                    <Input id='ssid-dual'
                        label='Nome da redes'
                        value={wlan.freq_2_4G.ssid}
                        name='ssid-dual'
                        onChange={handle2_4GhzSSIDInput}
                        validators={[
                            common.required,
                            common.nonASCII,
                            tempWirelessWithoutSpaces,
                            { fn: common.size, params: { min: 1, max: (wlan.freq_2_4G.MacInSSID ? 24 : 29) } }
                        ]}
                        key='wlan-0-ssid-dual'
                    ></Input>

                    <Input id='key-dual'
                        label='Senha da rede'
                        value={wlan.freq_2_4G.key}
                        name='key-dual'
                        onChange={handle2_4GHzKeyInput}
                        validators={[common.required, common.nonASCII, { fn: common.size, params: { min: 8, max: 63 } }]}
                        key='wlan-0-key-dual'
                    ></Input>
                </div>
            </div>,
        <div id='different-ssid-wifi' className='with-info'>
            <Checkbox id='enable-dual-band'
                label='Separar rede dualband'
                value={!wlan.enableDualBand}
                toggleFn={() => {
                    handleDualband()
                }}
                key='enable-dual-band'
                disabled={mesh.enabled}
            ></Checkbox>
            <InfoPopup
                message={<div >
                    {mesh.enabled ?
                        <React.Fragment>
                            Esta opção deve permanecer <b>desativada</b> para o funcionamento da rede Mesh.<br />
                            Caso queira desfazer a rede Mesh, acesse o menu Mesh.
                        </React.Fragment> :
                        <React.Fragment>
                            Ao habilitar esta opção, será possível definir nomes de rede distintos para as
                            frequências de 2.4GHz e 5GHz. Dessa forma, as redes Wi-Fi aparecerão em itens
                            distintos na lista e poderão ter seus parâmetros editados separadamente.
                        </React.Fragment>}
                </div>}
            ></InfoPopup>
        </div>,

        <Checkbox id='mac-in-ssid'
            label='Adicionar parte do MAC ao SSID'
            value={wlan.freq_5G.MacInSSID}
            toggleFn={() => {
                handleMacInSSID()
            }}
            key='wlan-5-mac-in-ssid'
        ></Checkbox>,
        <div id='different-ssid-wifi' className='with-info'>
            <Checkbox id='enable-config-radio'
                label='Configurar Radio'
                value={radio?.enableConfigureRadio}
                toggleFn={handleEnableConfigureRadio}
                key='different-ssid-wifi'
            // disabled={mesh.enabled}
            ></Checkbox>
            {mesh.enabled && (
                <InfoPopup
                    message={<div >

                        <React.Fragment>
                            Esta opção deve permanecer <b>desativada</b> para o funcionamento da rede Mesh.<br />
                            Caso queira desfazer a rede Mesh, acesse o menu Mesh.
                        </React.Fragment>

                    </div>}
                ></InfoPopup>)
            }

        </div>
        ,
        (radio?.enableConfigureRadio) ?
            <>
                <div key={`radio-configuration`} className='form-section-radio-configuration'>
                    <div className='radio-cofiguration-2_4GHz'>
                        <div className='dashboard-subtitle'>
                            <NetworksIcon size='20'></NetworksIcon>
                            <label>Rede 2.4 GHz</label>
                        </div>

                        <Select id={`radio-0-channel-select`}
                            name={`radio-0-channel-name`}
                            label='Canal'
                            value={radio.freq_2_4G.channel}
                            options={defaultFreqValues._2_4GHz.channels}
                            onChange={(e) => handle2_4GHzChannelInput(e)}
                        ></Select>

                        <Select id={`radio-0-bandwidth-select`}
                            name={`radio-0-bandwidth-name`}
                            label='Largura da banda'
                            value={radio.freq_2_4G.bandwidth}
                            options={defaultFreqValues._2_4GHz.bandwidth}
                            onChange={(e) => handle2_4GHzbandwidthInput(e)}
                        ></Select>

                        {
                            (showFieldExtensionChannel) ?
                                (lockFieldExtensionChannel) ?
                                    <Input
                                        label='Canal de extensão'
                                        id='radio-0-extension'
                                        value={defaultFreqValues._2_4GHz.extension[radio.freq_2_4G.extension]["text"]}
                                        disabled={true}
                                    ></Input> :
                                    <Select id={`radio-0-extension-channel-select`}
                                        name={`radio-0-extension-channel-name`}
                                        label='Canal de extenção'
                                        value={radio.freq_2_4G.extension}
                                        options={defaultFreqValues._2_4GHz.extension}
                                        onChange={(e) => handle2_4GHzExtensionChannelInput(e)}
                                    ></Select>
                                : <></>
                        }
                    </div>

                    <div className='radio-cofiguration-5GHz'>
                        <div className='dashboard-subtitle'>
                            <NetworksIcon size='20'></NetworksIcon>
                            <label>Rede 5 GHz</label>
                        </div>

                        <Select id={`radio-1-channel-select`}
                            name={`radio-1-channel-name`}
                            label='Canal'
                            value={radio.freq_5G.channel}
                            options={defaultFreqValues._5GHz.channels}
                            onChange={(e) => handle5GHzChannelInput(e)}
                        ></Select>

                        <Select id={`radio-1-channel-select`}
                            name={`radio-1-channel-name`}
                            label='Largura da Banda'
                            value={radio.freq_5G.bandwidth}
                            options={defaultFreqValues._5GHz.bandwidth}
                            onChange={(e) => handle5GHzbandwidthInput(e)}
                        ></Select>
                    </div>
                </div>
                <div className='no-managed'> A função Configurar Radio está disponível somente a partir da versão 1.27.13.
                    Para as versões de firmware recomendamos que mantenha sempre seu parque atualizado com a última versão de firmware
                    disponível na plataforma, acessando a aba Atualização.
                </div>
            </> : <></>
    ]
}