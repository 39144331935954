import React, { useContext, useEffect, useState } from 'react'
import { backendStatus } from '../../../../../../backend/backend'
import Button from '../../../../../../components/button/button'
import InfoLine from '../../../../../../components/info-line/info-line'
import Loading from '../../../../../../components/loading/loading'
import DefaultStatusModals from '../../../../../../components/modal/default-status-modals'
import Modal, { ModalTypes } from '../../../../../../components/modal/modal'
import { DeviceContext } from '../../manage-page'

import './maintenance.css'

const actions = {
    REBOOT: 1,
    RESET: 2
}

const DEVICE_FETCH_INTERVAL = 60000

function composeTime(value) {
    let days = Math.floor(value / 86400)
    value = value % 86400
    let hours = Math.floor(value / 3600)
    value = value % 3600
    let minutes = Math.floor(value / 60)
    value = Math.floor(value % 60)
    let text = (days > 0) ? days + ' dia(s), ' : ''
    text += (text || hours > 0) ? hours + ':' : ''
    text += (minutes < 10 ? '0' : '') + minutes + ':'
    text += (value < 10 ? '0' : '') + value
    return text
}

export default function MaintenancePage() {

    const device = useContext(DeviceContext)

    const [confirm, setConfirm] = useState({id: 0, status:false})
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [deviceResource, setDeviceResource] = useState(null)

    useEffect(() => {

        fetchDevice()

        let deviceInterval = setInterval(fetchDevice, DEVICE_FETCH_INTERVAL)

        return () => clearInterval(deviceInterval)

        // eslint-disable-next-line
    }, [])

    const fetchDevice = async() => {

        let result = await device.retrieveResource('device')
        if(result.status === backendStatus.SUCCESS){
            setDeviceResource(result.content[0])
        }

    }

    const confirmAction = async(actionID) => {
        setConfirm({id: actionID, status:true})
    }

    const sendAction = async(actionID) => {

        setSaving(true)

        let result = await device.createResource('action', {actionID: actionID})

        setSaving(false)

        if(result.status !== backendStatus.SUCCESS){
            setError(true)
            return
        }

        setSuccess(true)

    }

    const dismissModals = () => {
        setSuccess(false)
        setSaving(false)
        setError(false)
        setConfirm({id: 0, status: false})
    }

    const continueFn = (confirmed) => {

        if(confirmed){
            sendAction(confirm.id)
        }

        setConfirm({id: 0, status: false})
    }

    return <div className='maintenance-page'>

        <DefaultStatusModals
            success={success}
            saving={saving}
            error={error}
            continueFn={dismissModals}
            successText='O comando foi enviado com sucesso'
            savingText='Aguarde...'
            errorText='Houve um erro ao executar a ação'
        ></DefaultStatusModals>

        <Modal id='confirm-modal'
            show={confirm.status}
            title='Executar ação'
            type={ModalTypes.CONFIRM_WARNING}
            content={<div>
                <br></br>
                A ação será executada no dispositivo e ele será reiniciado.
                <br></br>
                <br></br>
                <b>Deseja prosseguir com a execução?</b>
            </div>}
            confirmText='Sim, desejo prosseguir'
            dismissText='Não, desejo cancelar'
            onDismissClick={continueFn}
        ></Modal>

        <div className='device-uptime'>
            <InfoLine label='Tempo ativo do sistema'
                value={deviceResource ? composeTime(deviceResource.uptime) : <Loading show={true} mini></Loading>}
             ></InfoLine>
        </div>

        <div className='card'>
            <div className='subtitle'>Reiniciar</div>
            <span className='centered'>O dispositivo será reiniciado.</span>
            <Button text='Reiniciar' onClick={() => confirmAction(actions.REBOOT)}></Button>
        </div>

        <div className='card'>
            <div className='subtitle'>Aplicar configurações padrão</div>
            <span>O dispositivo será restaurado para o perfil de configurações padrão associado.</span>
            <Button text='Restaurar configurações' onClick={() => confirmAction(actions.RESET)}></Button>
        </div>

    </div>
}