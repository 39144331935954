import React, { useState, useEffect, useContext } from 'react'
import { BackendContext } from '../../../backend/backend'
import Loading from '../../../components/loading/loading'
import InfoLine from '../../../components/info-line/info-line'
import { DiscreteColorLegend, RadialChart } from 'react-vis'
import okIcon from '../../../images/tick.svg'
import warnIcon from '../../../images/error.svg'
import { UIEventsContext } from '../../uievents-context'

const colorPalette = ['#62BE6E', '#799866', '#BD7F75']

const normalizeData = (model) => {

    let data = []

    for(let version of model.versions){
        data.push({
            angle: version.count,
            label: `${version.version} (${version.count})`,
            color: version.color
        })
    }

    return data
}

const normalizeDataLegend = (model) => {

    let data = []

    for(let version of model.versions){
        data.push({
            title: `${version.version} (${version.count})`,
            color: version.color,
            strokeWidth: 14,
        })
    }

    return data
}


export default function UpdateStats({update, firmwares, tour}) {

    const [stats, setStats] = useState(null)

    const backend = useContext(BackendContext)
    const uievents = useContext(UIEventsContext)

    useEffect(() => {

        fetchStats()

        uievents.setHandler('devices', fetchStats)

        // eslint-disable-next-line
    }, [])

    useEffect(() =>  {

        fetchStats()

        // eslint-disable-next-line
    }, [tour])

    const getRandomColor = () => {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    }

    const getVersionChartColor = (index) => {
        if(index >= colorPalette.length){
            return getRandomColor()
        }

        return colorPalette[index]
    }

    const fetchStats = async() => {

        let result = await backend.retrieveFresh("/devices/brief")

        var models = !tour ? result.content.models : require('./models_mock.json')
        var total = !tour ? result.content.total : models.length

        for(let model of models){
            model.versions.sort(function(a, b){
                return (a.count > b.count) ? -1 : 1
            })
        }

        models = models.map(obj => ({...obj, versions: obj.versions.map((v, i) => ({...v, color: getVersionChartColor(i)}))}))

        setStats({total: total, models: models})
    }

    const getActiveVersion = (model) => {
        for(let product of update.versions)
            if(model === product.model)
                return product.version

        return '0.0.0'
    }

    const getLatestVersion = (model) => {
        for(let firmware of firmwares)
            if(firmware.model === model)
                return firmware.version
    }

    const getVersionIcon = (model) => {

        let active = getActiveVersion(model)
        let latest = getLatestVersion(model)

        if(active === latest)
            return <img alt='versão ok' src={okIcon} width='17px'></img>

        return <img alt='existe uma nova versão' src={warnIcon} width='17px'></img>
    }

    const composeModelCard = (model) => {

        return <div className='model-stats-container' key={model.model}>
                <div className='model-stats' key={model.model}>

                <div className='subtitle'>{model.model}</div>

                <InfoLine label='Versão ativa' value={
                    <div className='active-version'>
                        {getActiveVersion(model.model)} {getVersionIcon(model.model)}
                    </div>
                }></InfoLine>

                <InfoLine label='Dispositivos' value={model.total}></InfoLine>
                
                <div className='model-stats-chart-container'>
                    <RadialChart
                        className='donut-chart'
                        data={normalizeData(model)}
                        height={160}
                        width={150}
                        innerRadius={70}
                        radius={35}
                        padAngle={0.04}
                        colorType={"literal"}
                    />
                    <DiscreteColorLegend items={normalizeDataLegend(model)} className='donut-chart-legend' />
                </div>
            </div>
        </div>
    }


    return stats ? <div id="update-stats">

        <div className='update-info-container'>
        {
            stats.models.map((model) => {

                return composeModelCard(model)

            })
        }


        </div>

    </div> : <Loading show={true}></Loading>
}