import React, { useEffect, useContext } from 'react'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import DownloadApps from '../../components/download-apps'

export default function DownloadsPage() {

    const header = useContext(MainHeaderContext)

    useEffect(() => {
        header.title.set('Downloads')
        // eslint-disable-next-line 
    }, [])

    return <DownloadApps></DownloadApps>
}