import React, { useEffect, useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import './profiles.css'
import ProfileAdd from './profile-add';
import ProfileList from './profile-list';
import { MainHeaderContext } from '../../../components/main-header/main-header-state';
import ProfileEditConfirmation from './edit-confirmation';


export default function ProfileMainPage({history}) {

    const header = useContext(MainHeaderContext)

    useEffect(() => {
        header.title.set('Gerenciamento de configurações')

    // eslint-disable-next-line 
    }, [])

    return <div id='profiles-page'>
        <Switch>
            <Route path='/profiles/list' component={ProfileList}></Route>
            <Route path='/profiles/confirm*' component={ProfileEditConfirmation}></Route>
            <Route path='/profiles/add*' component={ProfileAdd}></Route>
            <Route path='/profiles' component={ProfileList}></Route>
        </Switch>
    </div>
}