import React from 'react';
import './custom-toast.css'

export const CustomToastContainer = ({
    hasToasts,
    placement,
    ...props
  }) => {

  return(<div {...props} className="toast-container">
        </div>)
}