import React, { useContext, useEffect, useRef, useState } from 'react'
import { backendStatus } from '../../../../../../backend/backend'
import Form from '../../../../../../components/form/form'
import Loading from '../../../../../../components/loading/loading'
import DefaultStatusModals from '../../../../../../components/modal/default-status-modals'
import { DeviceContext } from '../../manage-page'
import InMeshAccount from '../../tr069/inmesh/inmesh-tr'
import router_img from '../../../../../../images/router.png'
import gf1200_img from '../../../../../../images/gf1200.png'
import wifiber121ac_img  from '../../../../../../images/Perspectiva_front.png'
import wifiber1200R_img from '../../../../../../images/ONT_low_cost_frontal_leds_on.png'
import wifiber120ac from '../../../../../../images/120ac.png'
import w51200f_img from '../../../../../../images/W5-1200F_frontal_LED.png'
import w51200g_img from '../../../../../../images/W5_1200G_front_alternativa.png'

import './inmesh.css'

export default function InMeshManagementPage() {

    const [inmesh, setInMesh] = useState(null)
    const [controller, setControllerEnabled] = useState(null)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const device = useContext(DeviceContext)
    const inmeshTimer = useRef(null)
    const mounted = useRef(true)
    const vis = require('vis-network/standalone');

    useEffect(() => {

        fetchInMesh()
        return () => {
            mounted.current = false
            clearTimeout(inmeshTimer.current)
        }

        // eslint-disable-next-line
    }, [])

    const getDeviceImage = (deviceName)=>{
        if (deviceName.includes('GF1200')){
            return gf1200_img
        }else if(deviceName.includes('121AC')){
            return wifiber121ac_img 
        }else if(deviceName.includes('1200R')){
            return wifiber1200R_img
        }else if (deviceName.includes('120AC')){
            return wifiber120ac
        }else if (deviceName.includes('W5-1200F')){
            return w51200f_img
        }else if (deviceName.includes('W5-1200G')){
            return w51200g_img
        }else{
            return router_img
        }
    }

    const getRssi = (deviceRssi) =>{
        if (deviceRssi===-1){
            return "Cabo"
        }else{
            return `${deviceRssi}dbm`
        }
    }

    const fetchInMesh = async() => {
        let result = await device.retrieveResource('inmesh')
        if(result.status !== backendStatus.SUCCESS){
            if(mounted.current)
                inmeshTimer.current = setTimeout(fetchInMesh, 5000)
            return
        }
        setInMesh(result.content)
        setControllerEnabled(result.content.enabled)
        if (result.content.enabled) {

            let devices = [{
                id: 'master',
                label: "Controller",
                shape: 'image',
                image: getDeviceImage(result.content.device_name),
                size:50,
                brokenImage: router_img,
                title: "Nome: " + result.content.device_name
                    + "\n IP: " + result.content.controllerIp
                    + "\n MAC: " + result.content.mac_address
            }]

            let topology = [{}]

            let child = result.content.neighbors
            child.map((el, i) => {
                 devices.push({
                    id: i,
                    shape: 'image',
                    image: getDeviceImage(el.name),
                    size:50,    
                    brokenImage: router_img,
                    //label: "Agent",
                    title: "Nome: " + el.name
                        + "\n IP: " + el.ip
                        + "\n MAC: " + el.mac
                        + "\n RSSI: " + getRssi(el.rssi)
                        + "\n Conexão: " + el.backhaul_link
                })
                topology.push({
                    from: "master", to: i, font: { align: "top" }, color: 'black',label:el.backhaul_link
                })
                let childLevel1 = el.neighbors
                if (childLevel1) {
                    childLevel1.map((el, j) => {
                        devices.push({
                            id: `${i}+${j}`,
                            shape: 'image',
                            image: getDeviceImage(el.name),
                            brokenImage: router_img,
                            size:50, 
                            //label: "Agent",
                            title: "Nome: " + el.name
                                + "\n IP: " + el.ip
                                + "\n MAC: " + el.mac
                                + "\n RSSI: " + getRssi(el.rssi)
                                + "\n Conexão: " + el.backhaul_link
                        })
                        topology.push({
                            from: i, to: `${i}+${j}`, font: { align: "top" }, color: 'black',label:el.backhaul_link
                        })
                        let childLevel2 = el.neighbors
                        if (childLevel2) {
                            childLevel2.map((el, k) => {
                                devices.push({
                                    id: `${i}+${j}+${k}`,
                                    shape: 'image',
                                    image: getDeviceImage(el.name),
                                    brokenImage: router_img,
                                    size:50, 
                                    //label: "Agent",
                                    title: "Nome: " + el.name
                                        + "\n IP: " + el.ip
                                        + "\n MAC: " + el.mac
                                        + "\n RSSI: " + getRssi(el.rssi)
                                        + "\n Conexão: " + el.backhaul_link
                                })
                                topology.push({
                                    from: `${i}+${j}`, to: `${i}+${j}+${k}`, font: { align: "top" }, color: 'black',label:el.backhaul_link
                                })
                                let childLevel3 = el.neighbors
                                if (childLevel3) {
                                    childLevel3.map((el, l) => {
                                        devices.push({
                                            id: `${i}+${j}+${k}+${l}`,
                                            shape: 'image',
                                            image: getDeviceImage(el.name),
                                            brokenImage: router_img,
                                            size:50,
                                           // label: "Agent",
                                            title: "Nome: " + el.name
                                                + "\n IP: " + el.ip
                                                + "\n MAC: " + el.mac
                                                + "\n RSSI: " + getRssi(el.rssi)
                                                + "\n Conexão: " + el.backhaul_link
                                        })
                                        topology.push({
                                            from: `${i}+${j}+${k}`, to: `${i}+${j}+${k}+${l}`, font: { align: "top" }, color: 'black',label:el.backhaul_link
                                        })
                                        let childLevel4 = el.neighbors
                                        if (childLevel4) {
                                            childLevel4.map((el, m) => {
                                                devices.push({
                                                    id: `${i}+${j}+${k}+${l}+${m}`,
                                                    shape: 'image',
                                                    image: getDeviceImage(el.name),
                                                    brokenImage: router_img,
                                                   // label: "Agent",
                                                    size:50,
                                                    title: "Nome: " + el.name
                                                        + "\n IP: " + el.ip
                                                        + "\n MAC: " + el.mac
                                                        + "\n RSSI: " + getRssi(el.rssi)
                                                        + "\n Conexão: " + el.backhaul_link
                                                })
                                                topology.push({
                                                    from: `${i}+${j}+${k}+${l}`, to: `${i}+${j}+${k}+${l}+${m}`, font: { align: "top" }, color: 'black',label:el.backhaul_link
                                                })
                                                let childLevel5 = el.neighbors
                                                if (childLevel5) {
                                                    childLevel5.map((el, n) => {
                                                        devices.push({
                                                            id: `${i}+${j}+${k}+${l}+${m}+${n}`,
                                                            shape: 'image',
                                                            image: getDeviceImage(el.name),
                                                            size:50,
                                                            brokenImage: router_img,
                                                            //label: "Agent",
                                                            title: "Nome: " + el.name
                                                                + "\n IP: " + el.ip
                                                                + "\n MAC: " + el.macs
                                                                + "\n RSSI: " + getRssi(el.rssi)
                                                                + "\n Conexão: " + el.backhaul_link
                                                        })
                                                        topology.push({
                                                            from: `${i}+${j}+${k}+${l}+${m}`, to: `${i}+${j}+${k}+${l}+${m}+${n}`, font: { align: "top" }, color: 'black',label:el.backhaul_link
                                                        })
                                         return "" })
                                                }    
                                    return"" })
                                        }
                          return ""})
                                }

                   return ""})
                        }
            return ""})
                }
   return ""})
            let nodes = new vis.DataSet(devices);
            let edges = new vis.DataSet(topology)

            var container = document.getElementById("mynetwork");
            if(result.content.neighbors.length!==0){
                container.style.height="500px"
            }else{
                container.style.height="300px"
            }
            var data = {
                nodes: nodes,
                edges: edges,
            };
            var options = {
                interaction: { dragNodes: false, dragView: false, zoomView: false, selectable: false },
                layout: {
                    randomSeed: undefined,
                    hierarchical: {
                        enabled: true,
                        direction: "LR",
                        sortMethod: "directed",
                    }
                }

            };
            if (result.content.enabled===true) {
                var network = new vis.Network(container, data, options);
                if (network) {
                    console.log("Diagram constructor OK")
                }

            }
        }
    }
    

    const handleError = (result) => {
        console.error('Error:', result.status, result.content)
        setSaving(false)
        setError(true)
    }

    const handleResult = (result) => {

        if (result.status !== backendStatus.SUCCESS) {
            handleError(result)
            return
        }

        setSaving(false)
        setSuccess(true)

    }
    const saveInmesh = async () => {
        setSaving(true)

        inmesh.id = 'inmesh-1'

        let result = await device.updateResource('inmesh', inmesh)

        if (result.status !== backendStatus.SUCCESS) {
            setSaving(false)
            setError(true)
            return
        }
        result = await device.apply()

        handleResult(result)

        window.location.reload()

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSuccess(false)
        setSaving(false)
        setError(false)
    }

    return <div className='inmesh-management-page'>
       {controller===true && <div id="mynetwork"></div>}
        <Form id='inmesh-configuration-form'
            onSubmit={saveInmesh}
        >
            <DefaultStatusModals
                success={success}
                error={error}
                saving={saving}
                continueFn={dismissModal}
            ></DefaultStatusModals>


            {!inmesh ? <Loading show={true}></Loading> :
                <div className='inmesh-info'>

                    {!inmesh ? null :

                        <div className='inmesh-flex-container'>
                            <InMeshAccount
                                inmesh={inmesh}

                                setInMesh={setInMesh}

                            ></InMeshAccount>
                        </div>

                    }
                </div>

            }
        </Form>

    </div>
}