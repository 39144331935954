import React from 'react'
import './headerbig.css'
import logo from './intelbras-brand-complete.svg'

export default function HeaderBig(props){

    return (
        <div id='header-big'>
           <img src={logo} alt='Intelbras'></img> 
        </div>
    )
}