import React, { useEffect, useContext, useState } from 'react';
import { BackendContext, backendStatus } from '../../../backend/backend';
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import NoConnectionIcon from '../../../components/icons/no-connection'
import isElectron from '../../server/is-electron';
import Axios from 'axios';
import './connection-status.css'

export default function ConnectionStatus() {
    const [online, setOnline] = useState(false)
    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)
    let connectionInverval = null

    useEffect(() => {
        header.hidden.set(false)
        header.navigation.set(false)
        header.title.set('Problemas de conexão')

        if(isElectron()){
            checkConnection()
        }

        return () => {
            clearInterval(connectionInverval)
        }

        // eslint-disable-next-line 
    }, [])

    function updateStatus(status) {
        if(status !== online){
            setOnline(status)
            onConnectionChange(status)
        }
    }

    const onUnauthorized = () => {
        const { ipcRenderer } = window.require('electron');
        ipcRenderer.send('online-status-changed', true)
    }

    function checkConnection() {
        connectionInverval = setInterval(async function() {
            
            try {
                let response = await Axios.get(process.env.REACT_APP_LOGIN_URL)
                if(response.status !== 200){
                    updateStatus(false)
                    return
                }

                backend.setOnUnauthorized(onUnauthorized)
                response = await backend.retrieve('status')

                if(response.status === backendStatus.SUCCESS){
                    updateStatus(true)
                }

            } catch (error) {
                updateStatus(false)
            }
        }, 5000);
    }

    const onConnectionChange = (conncetion) => {
        const { ipcRenderer } = window.require('electron');
        ipcRenderer.send('online-status-changed', conncetion)
    }

    return(
        <div>
            <h1>Sem conexão com o servidor Remotize!</h1>
            
            <div className={online ? "normal" : "warning"}>
                Status de conexão: {online ? "Online" : "Offline"}
            </div>

            <div className='no-connection-icon'>
                <NoConnectionIcon size='256' color='#5f7278'></NoConnectionIcon>
            </div>            
        </div>
    )
}