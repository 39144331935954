import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Form from '../../../components/form/form'
import Checkbox from '../../../components/checkbox/checkbox'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Loading from '../../../components/loading/loading'
import { permissionsDiff, intelbrasDefaultPermissions } from './permissions-utils'

export default function AppPermissions() {

    const [permissions, setPermissions] = useState(null)
    const [ispPermissions, setIspPermissions] = useState(null)
    const [autoAccept, setAutoAccept] = useState(null)

    const backend = useContext(BackendContext)
    const history = useHistory()
    const params = useParams()

    const translateKey = (key) => {
        switch(key){
            case 'network':
                return 'Rede'
            case 'security':
                return 'segurança'
            case 'tools':
                return 'Ferramentas'
            case 'devices':
                return 'Lista de dispositivos'
            case 'config':
                return 'Configurações'
            case 'wireless':
                return 'Wi-Fi'
            case 'ssid':
                return 'Nome da rede'
            case 'password':
                return 'Senha da rede'
            case 'radio':
                return 'Radio'
            case 'wan':
                return 'Internet (WAN)'
            case 'lan':
                return 'Rede local (LAN)'
            case 'port_redirect':
                return 'Redirecionamento de portas'
            case 'dmz':
                return 'Host DMZ'
            case 'site_survey':
                return 'Site survey'
            case 'list':
                return 'Listar'
            case 'update':
                return 'Atualização de firmware'
            case 'user_password':
                return 'Credenciais de acesso'
            case 'reboot':
                return 'Reiniciar'
            case 'restore':
                return 'Aplicar padrão de fábrica'
            case 'management':
                return 'Acesso remoto'
            default:
                return 'Não mapeado'
        }
    }

    useEffect(() => {

        fetchIspPermissions()

        if(!isDefault())
            fetchUserPermissions()

        // eslint-disable-next-line
    }, [])

    const isDefault = () => {
        return  getIDParam() === 'default'
    }

    const getIDParam = () => {
        return params.id.split("&")[0]
    } 

    const fetchUserPermissions = async() => {

        let result = await backend.retrieveFresh(`users/associations/${getIDParam()}/permissions`)

        if(result.status === backendStatus.SUCCESS){
            delete result.content['auto_accept']
            setPermissions(result.content)
        }
    }

    const fetchIspPermissions = async() => {

        let result = await backend.retrieveFresh('permissions')

        if(result.status === backendStatus.SUCCESS){
            setAutoAccept(result.content.auto_accept)

            if(isDefault()){
                delete result.content['auto_accept']
                setPermissions(result.content)
                return
            }

            setIspPermissions(result.content)
        }
    }

    const submit = () => {

        let basePermissions = isDefault() ? intelbrasDefaultPermissions : ispPermissions

        let diff = permissionsDiff(basePermissions, {...permissions, auto_accept: autoAccept})

        let url = isDefault() ? 'permissions' : `users/associations/${getIDParam()}/permissions`

        let result = backend.update(url, diff)
        if(result.status === backend.SUCCESS){
            //TODO: handle success
            history.goBack()
            return
        }

        //TODO: handle errors
        console.log(result)
        alert('Ocorreu um erro')
    }

    return !permissions ? <Loading show={true}></Loading> : <div id='app-permissions-edit-page'>

        <div className='subtitle'>
            {`Editar permissões ${isDefault() ? 'padrão' : ''} do aplicativo`}
            <span>Marque as opções que deseja liberar no aplicativo</span>
        </div>

        <Form onSubmit={submit}>
            {
                Object.keys(permissions).map((key) => {

                    let section = permissions[key]

                    return <section key={key}>
                        <label className='section-title'>{translateKey(key)}</label>

                        {

                            Object.keys(section).map((sectionKey) => {
                                return typeof section[sectionKey] === 'object' ? Object.keys(section[sectionKey]).map((valueKey) => {
                                    return <Checkbox key={valueKey} id={valueKey}
                                            label={`${translateKey(sectionKey)} - ${translateKey(valueKey)}`}
                                            value={permissions[key][sectionKey][valueKey]}
                                            toggleFn={() => {
                                                setPermissions(prev => {
                                                    prev[key][sectionKey][valueKey] = !prev[key][sectionKey][valueKey]
                                                    return {...prev}
                                                })
                                            }}
                                           ></Checkbox>
                                })

                                :

                                <Checkbox key={sectionKey} id={sectionKey}
                                    label={translateKey(sectionKey)}
                                    value={section[sectionKey]}
                                    toggleFn={() => {
                                        setPermissions(prev => {
                                            prev[key][sectionKey] = !prev[key][sectionKey]
                                            return {...prev}
                                        })
                                    }}
                                ></Checkbox>
                            })
                        }
                    </section>
                })
            }
        </Form>

    </div>
}