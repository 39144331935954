import React, { useContext } from 'react'

import './toggle.css'
import { FormContext } from '../form/form'
import InfoPopup from '../info-popup/info-popup'

export default function Toggle({value, toggleFn, id, label, disabled, description, tooltip}){

    const formContext = useContext(FormContext)

    return (
        <div className={`toggle-group ${label ? 'full-width' : ''}  ${label && description ? 'large' : ''}
            ${disabled ? 'disabled' : ''}`}>

            {label ?
            <div className='label-group'>
                <div className='label-container'>
                    <label>{label}</label>
                    {tooltip ?
                    <InfoPopup message={tooltip}></InfoPopup>
                    : null}
                </div>
                {description ?
                <p>{description}</p> : null}
            </div> : ''}
            
            <div id={id} className={value ? 'toggle is-true' : 'toggle'} onClick={(e) => {
                if(disabled)
                    return
                if(formContext)
                    formContext.setPristine(false)
                toggleFn(e)
            }}>
                <div className='toggle-rail'></div>
                <div className='toggle-circle'></div>
            </div>

        </div>
    )
}