import React, { useEffect, useState } from 'react'
import Select from '../../src/components/select/select'

export function getDeviceName(model) {

    if (model === 'GF1200')
        return 'GF 1200'

    return model
}

let devices = {
    'GF1200': 0,
    'W5-1200F': 1,
    'Bifrost mock': 2,
    'W4-300F': 3,
    'WiFiber 121 AC': 4,
    'ONT 121 W': 5,
    'Twibi': 6,
    'W5-1200G': 7,
    'Huawei EG8145V5': 8,
    'Huawei EG8141A5': 9,
    'Huawei HG8546M': 10,
    'ONU R1': 11,
    'WiFiber 120 AC': 12,
    'WiFiber 1200 R': 13,
    'W5-2100G': 14,
    'Twibi Force Plug': 15,
    'Twibi Force AX': 16,
    'WiFiber AX 1800': 17,
    'WiFiber AX 1800V': 18,
    'W6-1500': 19,
    'Twibi Force Voice': 20,
    'Twibi Force Plug Giga': 21,
    'EX-1500': 22,
    'W5-1200GS': 23,
    'W4-300S': 24,
    'WiFiber AX 3000V': 25,
    'Fiberhome HG6145F3': 26,
    'Fiberhome HG6145F': 27,
    'Fiberhome HG6145D2': 28,
    'SR1041E': 29,
    'RX1500': 30,
    'RX3000': 31,
}

function getDeviceOptions() {
    let options = []

    for (const [key] of Object.entries(devices)) {
        options.push({ value: key, text: key })
    }

    return options
}

export default function RemotizeDevices({ model, setModel }) {
    const [currentDevice, setCurrentDevice] = useState(devices[model])

    useEffect(() => {
        setCurrentDevice(model)
    }, [model])


    return (

        <Select id='device-profile'
            label='Modelo'
            value={currentDevice}
            onChange={(e) => {
                setModel(e.target.value)
                setCurrentDevice(e.target.value)
            }}
            options={getDeviceOptions()}
        ></Select>)
}
