import { BifrostAPI } from './bifrost/bifrost_api'
import Device from './device'

export default class BifrostDevice extends Device {

    constructor(device, backend) {
        super(device, backend)
        this.api = new BifrostAPI(backend)
    }

    isDualBand() {
        return this.data.model !== 'W4-300F' && this.data.model !== 'W4-300S'
    }
}