import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import './profiles.css'
import List from '../../../components/list/list';
import Modal, { ModalTypes } from '../../../components/modal/modal';
import ProfileViewer from './profile-viewer';
import EditIcon from '../../../components/icons/edit'
import RemoveIcon from '../../../components/icons/remove'
import { MainHeaderContext } from '../../../components/main-header/main-header-state';
import { BackendContext, backendStatus } from '../../../backend/backend';
import ProfileTour from './profile-tour';
import { UserContext } from '../../user-context';
import { RemotizeType } from '../signup/signup';
import SearchIcon from '../../../components/icons/search-icon';
import CopyIcon from '../../../components/icons/copy-icon'
import Form from '../../../components/form/form'
import Input from '../../../components/input/input'
import Button from '../../../components/button/button'
import CommonValidators from '../../../components/form/validators/common';
import Pager from '../../../components/pager/pager';

async function fetchProfiles(backend, setProfiles, pager, setPager, filter) {

    let result = await backend.retrieveFresh(`profiles?pages=y&pagesize=${pager.size}&pagenumber=${pager.number}&name=${filter}`)
    pager.total = result.headers.get('x-profiles-total')

    setPager({...pager})
    setProfiles(result.content)
}

export default function ProfileList() {

    const [profiles, setProfiles] = useState([])
    const [selected, setSelected] = useState(null)
    let [confirm, setConfirm] = useState({confirm: false, id: ''})
    const [copyName, setCopyName] = useState({show: false, profile: null})
    const [showError, setShowError] = useState(false)
    const [profileFilter, setProfileFilter] = useState('')
    const [pager, setPager] = useState({size: 12, number:1, total: 0})

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const history = useHistory()
    const [user,] = useContext(UserContext)

    useEffect(() => {

        header.title.set('Gerenciamento de configurações')
        fetchProfiles(backend, setProfiles, pager, setPager, profileFilter)
        header.backRoute.set('/')

    // eslint-disable-next-line
    }, [])

    useEffect(() => {

        fetchProfiles(backend, setProfiles, pager, setPager, profileFilter)

        // eslint-disable-next-line
    }, [profileFilter])

    let confirmDelete = async(confirmedDelete) => {

        setConfirm({...confirm,confirm:false})

        if(confirmedDelete){

            await backend.delete(`profiles/${confirm.id}`)

            setProfiles(profiles.filter(function(p){
                return p.id !== confirm.id
            }))

            setSelected(null)
        }
    }

    const getProfile = (name) => {

        if(name.includes('(Padrão)')){
            name = name.split('(Padrão)')[0].trim()
        }

        profiles.forEach((profile) => {

            if(name === profile.name){
                setSelected(profile)
            }
        })

    }

    const getCopyIcon = (profile) => {
        return (
            <span className={`itb-input-edit-icon clickable`}
                onClick={(e) => {

                        e.stopPropagation();

                        setCopyName({show: true, profile: {
                            ...profile,
                            name: `Clone de ${profile.name}`,
                            isDefault: false
                        }})
                    }
                }
            >
                {<CopyIcon size={24}></CopyIcon> }
            </span>
        )
    }

    const getEditIcon = (profile) => {

        let isIntelbras = profile.name === "Intelbras"

        return (
            <span className={`itb-input-edit-icon clickable ${isIntelbras ? 'disabled' : ''}`}
                onClick={isIntelbras ? undefined : () => {
                        history.push({pathname: '/profiles/confirm', state: {profile: profile}})
                    }
                }
            >
                {<EditIcon size="24"></EditIcon>}
            </span>
        )
    }

    const getRemoveIcon = (profile) => {

        let isIntelbras = profile.name === "Intelbras"

        return (
            <span className={`itb-input-edit-icon clickable ${isIntelbras ? 'disabled' : ''}`}
                onClick={isIntelbras ? undefined : async() => {
                        setConfirm({confirm: true, id: profile.id})
                    }
                }
            >
                {<RemoveIcon size="24"></RemoveIcon>}
            </span>
        )
    }

    const getViewIcon = () => {

        return (
            <span className='itb-input-edit-icon clickable'>
                {<SearchIcon size='20px'></SearchIcon>}
            </span>
        )
    }


    const getProfileName = (profile) => {

        return `${profile.name}${profile.isDefault ? ' (Padrão)' : ''}`
    }

    const getColumns = () => {

        let columns = [{header:'Nome', size: '400px'}]

        if(user.type === RemotizeType.ADMIN){
            columns.push({header:'Duplicar', size: '70px', align: 'center'})
            columns.push({header:'Editar', size: '70px', align: 'center'})
            columns.push({header:'Remover', size: '85px', align: 'center'})
        }else{
            columns.push({header:'Visualizar', size: '120px', align: 'center'})
        }

        return columns
    }

    const getLines = () => {

        let ordered = profiles.sort((a, b) => {

            if(b.name === 'Intelbras')
                return 1
            if(b.isDefault)
                return 1
            if(a.isDefault)
                return -1

            return 0
        })

        let filtered = ordered.filter(p => p.name.includes(profileFilter))

        let lines = []
        filtered.forEach((p) => {

            let line = [getProfileName(p)]

            if(user.type === RemotizeType.ADMIN){
                line.push(getCopyIcon(p))
                line.push(getEditIcon(p))
                line.push(getRemoveIcon(p))
            }else{
                line.push(getViewIcon(p))
            }

            lines.push(line)
        })

        return lines
    }

    const checkName = async (name) => {

        let result = await backend.create('profiles/exists', {name: name})

        if(result.status === backendStatus.SUCCESS){
            return 'Este nome já existe'
        }

        return ''
    }

    const copyModalContinue = async() => {

        setCopyName({...copyName, show: false})

        delete copyName.profile.id

        let result = await backend.create('profiles', copyName.profile)

        if(result.status !== backendStatus.SUCCESS){
            setShowError(true)
            return
        }

        fetchProfiles(backend, setProfiles, pager, setPager, profileFilter)

    }

    const copyModal = () => {

        return(
            <Modal
                show={copyName.show}
                title='Copiar perfil'
                content={<div style={{width: '350px'}}>
                    Escolha um nome para o novo perfil.

                    <Form id='copy-profile-form'
                        submitText='Criar cópia'
                        buttonId='copy-name-create'
                        onSubmit={copyModalContinue}
                    >

                        <Input id='copy-name'
                            label='Nome'
                            value={copyName.profile.name}
                            onChange={e => {
                                copyName.profile.name = e.target.value
                                setCopyName({...copyName, profile: {...copyName.profile}})
                            }}
                            validators={[CommonValidators.required, checkName, CommonValidators.nonASCII]}
                        ></Input>

                        <Button id='copy-name-cancel'
                            text='Cancelar'
                            outlined='true'
                            onClick={e => {
                                e.stopPropagation()
                                setCopyName({show: false, profile: null})
                            }}
                        ></Button>
                    </Form>

                </div>}
            ></Modal>
        )
    }

    const errorModal = () => {
        return <Modal id='error-modal'
            show={showError}
            type={ModalTypes.ERROR}
            content='Houve um erro ao criar o perfil.'
            dismissText='Entendi'
            onDismissClick={() => setShowError(false)}
        ></Modal>
    }

    const changePage = async(pageNumber) => {

        pager.number = pageNumber
        fetchProfiles(backend, setProfiles, pager, setPager, profileFilter)
        setPager({...pager})
    }

    const hasMoreThanOnePage = () => {
        return pager.total / pager.size > 1
    }

    return <React.Fragment>

        {errorModal()}

        {copyName.show && copyModal()}

        <Modal
            show={confirm.confirm}
            title='Excluir perfil de configurações'
            content={<div style={{width: '420px'}}>
                Ao excluir este perfil, ele será removido dos roteadores que estiverem com ele
                associado e serão configurados com o perfil padrão. Esta ação não pode ser desfeita.
                <br></br>
                <br></br>
                <b>Tem certeza que deseja excluir o perfil?</b>
            </div>}
            onDismissClick={confirmDelete}
            type={ModalTypes.CONFIRM_WARNING}
            dismissText='Não, quero manter'
            confirmText='Sim, quero excluir'
        ></Modal>

        <div className='profile-add-button' onClick={() => history.push({pathname: '/profiles/add', state: null})}>
            <div className='profile-add-icon'>+</div>
            <label>Adicionar novo perfil</label>
        </div>

        <div className='profile-search'>
            <Input id='profile-search-input'
                label='Buscar perfil'
                placeholder='Digite o termo de busca'
                value={profileFilter}
                onChange={e => setProfileFilter(e.target.value)}
            ></Input>
        </div>

        <div className='profiles-content'>
            <div className='profiles-scroll-area profile-list'>
                {

                profiles.length === 0 ? '' : <List
                    columns={getColumns()}
                    lines={getLines()}
                    onClick={(item) => {getProfile(item[0])}}
                ></List>

                }

                {hasMoreThanOnePage() && <Pager
                    pageNumber={pager.number}
                    pageSize={pager.size}
                    totalElements={pager.total}
                    pageChangeFn={changePage}
                ></Pager>}

            </div>

            <div className='profiles-scroll-area'>
                {selected ? <ProfileViewer profile={selected}></ProfileViewer> : <div className='profile-info-text'>
                    Selecione um perfil para ver seu resumo
                </div>}
            </div>

        </div>

        <ProfileTour/>

    </React.Fragment>
}
