import React from 'react'

import windowsLogo from './windows.png'
import linuxLogo from './linux.png'
import GatewayLogo from './gateway.png'

import './download-apps.css'

const downloads = process.env.REACT_APP_DOWNLOADS_ENDPOINT

export default function DownloadApps({infoText}){

    return <div id='download-apps'>

        <div className='info-text'>{infoText ? infoText : 'Faça o download dos nossos aplicativos para desktop:'}</div>

        <a href={`${downloads}/desktop-apps/windows.zip`} className='download-button'>
            <img id='windows-logo' alt='windows' src={windowsLogo}></img>
        </a>

        <a href={`${downloads}/desktop-apps/linux.zip`} className='download-button'>
            <img id='linux-logo' alt='linux' src={linuxLogo}></img>
        </a>

        <a href="https://remotize.intelbras.com.br/manual/#51" className='download-button'>
            <img id='linux-logo' alt='linux' src={GatewayLogo}></img>
        </a>

    </div>
}