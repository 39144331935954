import React from 'react'
import Checkbox from '../../../../../../components/checkbox/checkbox';
import Loading from '../../../../../../components/loading/loading';
import NetworksIcon from '../../../../../../components/icons/networks-icon'

import './wan.css'

export default function LanBind({wanIndex,wanLanIndex,wanLanList,setWanLanList}) {


   const lansBind = () =>{
    return wanLanList[wanIndex].lan_list?.map((el, idx)=>{

        var port_id = el.lan_id
        if(port_id.indexOf('LAN') > -1 && port_id.indexOf('WLAN')===-1){
            var port = port_id.replace("_", " ")
            var port_number = port_id.slice(-1)
            return (
            <div className='lanInterface'>
                <Checkbox
                    id={'lan-bind-toggle'+port_number}
                    label={port}
                    value={wanLanList[wanIndex].lan_list[idx].enabled} 
                    toggleFn={() => {
                        wanLanList[wanIndex].lan_list[idx].enabled = !wanLanList[wanIndex].lan_list[idx].enabled
                        setWanLanList([...wanLanList])
                    }}
                ></Checkbox>
            </div>)
        }
        return ""
    })
   }
   const wlansBind = () =>{
    return wanLanList[wanIndex].lan_list?.map((el, idx)=>{
        var port_id = el.lan_id
        const wifiLabel = () => {if(port_id==="WLAN_0"){return("Wifi 5GHz")}else{return("Wifi 2.4GHz")}}
        if (port_id.indexOf('WLAN') > -1){
            var port_number = port_id.slice(-1)
            return (
                <div className='wlan-checkboxes'>
                    <Checkbox
                        id={'wan-bind-toggle'+port_number}
                        label={wifiLabel()}
                        value={wanLanList[wanIndex].lan_list[idx].enabled} 
                        toggleFn={() => {
                            wanLanList[wanIndex].lan_list[idx].enabled = !wanLanList[wanIndex].lan_list[idx].enabled
                            setWanLanList([...wanLanList])
                        }}
                    ></Checkbox>
                </div>
            )
        }
        return ""
    })
   }
    return (

        !wanLanList ? <Loading show={true}></Loading> :
            wanLanList[0].lan_list === null ?  null:
            <React.Fragment>  
                <div className='wan-card'>
                    <div className='dashboard-subtitle'>
                        <NetworksIcon size='25'></NetworksIcon>
                        <label>Mapeamento de Portas</label>
                    </div>
                    <div className='lan-bind'>
                        <div className='lans'>
                            {lansBind()}
                        </div>
                        <div className='wlans'>
                            {wlansBind()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
    )
}