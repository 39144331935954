import React, { useContext, useEffect } from 'react'
import Menu from '../components/menu/menu'
import MenuEntry from '../components/menu/menu-entry'

import DashboardIcon from '../components/icons/dashboard-icon'
import CogIcon from '../components/icons/cog'
import UploadIcon from '../components/icons/upload'
import DownloadIcon from '../components/icons/download'
import HelpIcon from '../components/icons/help-icon'

import Loading from '../components/loading/loading'

import { useHistory } from 'react-router-dom'
import { MenuContext } from '../components/menu/menu-state'
import { UserContext } from './user-context'
import { isGLogin } from './glogin'
import AppIcon from '../components/icons/app-icon'
import PeopleGroupIcon from '../components/icons/people-group-icon'
import { RemotizeType } from './pages/signup/signup'
import { FirebaseContext } from './firebase'

const DISABLE_APP = process.env.REACT_APP_DISABLE_APP

export default function RemotizeMenu() {

    let history = useHistory()
    let menu = useContext(MenuContext)
    const [user,] = useContext(UserContext)
    const firebase = useContext(FirebaseContext)
    
    useEffect(() => {

        menu.username.set(localStorage.getItem('username'))
        // eslint-disable-next-line 
    }, [])

    const logout = async() => {

        await firebase.signOut()

        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"

        let loginRoute = isGLogin ? '/glogin/logout' : '/login'

        history.push(loginRoute)
    }

    return !user ? <Loading></Loading> : <Menu onLogout={logout}>

            <MenuEntry IconComponent={DashboardIcon} iconSize='20px'
                id='menu-devices'
                label='Dispositivos' description='Veja seus dispositivos'
                onClick={ () => history.push('/') }
            ></MenuEntry>

            { !user.superuser ? <MenuEntry IconComponent={UploadIcon} iconSize='22px'
                id='menu-update'
                label='Atualização' description='Atualize seus dispositivos'
                onClick={ () => history.push('/update') }
            ></MenuEntry> : null }

            { !user.superuser ? <MenuEntry IconComponent={CogIcon} iconSize='22px'
                id='menu-profiles'
                label='Gerenciamento de configurações' description='Gerencie seus perfis de configurações'
                onClick={ () => history.push('/profiles/list') }
            ></MenuEntry> : null }

            { user.type === RemotizeType.ADMIN ? <MenuEntry IconComponent={PeopleGroupIcon} iconSize='22px'
                id='menu-accounts'
                label='Gerenciamento de contas' description='Gerencie o acesso de seus colaboradores'
                onClick={ () => history.push('/accounts') }
            ></MenuEntry> : null }

            { user.superuser ? <MenuEntry IconComponent={DashboardIcon} iconSize='22px'
                id='menu-firmwares'
                label='Firmwares' description='Gerencie versões de firmware'
                onClick={ () => history.push('/firmwares') }
            ></MenuEntry> : ''}

            { user.superuser ? <MenuEntry IconComponent={PeopleGroupIcon} iconSize='22px'
                id='menu-lgpd'
                label='LGPD' description='Envie termos de uso e consentimento'
                onClick={ () => history.push('/lgpdadmin') }
            ></MenuEntry> : ''}


            <MenuEntry IconComponent={DownloadIcon} iconSize='21'
                id='menu-downloads'
                label='Downloads' description='Faça o download dos aplicativos'
                onClick={ () => history.push('/downloads') }
            ></MenuEntry>

            {DISABLE_APP ? null : <MenuEntry IconComponent={AppIcon} iconSize='21'
                id='menu-app'
                label='Aplicativo' description='Gerencie as permissões de acesso do aplicativo'
                onClick={ () => history.push('/app') }
            ></MenuEntry>}

            <MenuEntry IconComponent={HelpIcon} iconSize='20px'
                id='menu-help'
                label='Ajuda' description='Tire suas dúvidas a respeito do remotize'
                subEntries={[
                    <MenuEntry IconComponent={HelpIcon} iconSize='20px'
                        id='menu-faq'
                        label='FAQ' description='Perguntas frequentes'
                        onClick={ () => window.open('/#/faq', '_blank') }
                    ></MenuEntry>,
                    <MenuEntry IconComponent={HelpIcon} iconSize='20px'
                        id='menu-manual'
                        label='Manual' description='Manual online'
                        onClick={ () => window.open('https://remotize.intelbras.com.br/manual', '_blank') }
                        isAnchor
                    ></MenuEntry>
                ]}
            ></MenuEntry>

        </Menu>


}