import React, { useEffect, useContext, useState, useRef } from 'react'

import SelectSearch from '../../../components/select-search/select-search'
import Button from '../../../components/button/button'
import Modal, { ModalTypes } from '../../../components/modal/modal';
import Loading from '../../../components/loading/loading'
import InfoLine from '../../../components/info-line/info-line'
import CloseIcon from '../../../components/icons/close'
import { BackendContext, backendStatus } from '../../../backend/backend';
import { profileStatus, deviceStatus, assocStatus, canBeManaged, profileIsApplying } from './devices-page'


import { useToasts } from 'react-toast-notifications'
import Input from '../../../components/input/input'
import NoConnectionIcon from '../../../components/icons/no-connection';
import RouterButton from '../../../components/router-button/router-button';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../user-context';
import { RemotizeType } from '../signup/signup';
import DeviceFactory from '../../apis/device-factory'

const PROFILE_STATUS_CONFIRMED = 2

function getProfileStatus(status) {

    switch(status){
        case 0:
            return 'Sem perfil'
        case 1:
            return 'Associando'
        case 2:
            return 'Confirmado'
        case 4:
            return 'Reaplicar'
        default:
            break;
    }
}

function getProfileOptions(profiles){
    let names = profiles.map((profile) => {
        return {value: profile.id, text: profile.name}
    })

    return names
}

export default function DeviceDetails({device, setDevice, onOverlayClick, onProfileChanged, profiles, onSubmit, onDeviceRemoved}){

    const [pristine, setPristine] = useState(true)
    const [confirm, setConfirm] = useState({confirm: false, id: ''})
    const [warnAutoApply, setWarnAutoApply] = useState({confirm: false, id: ''})

    const { addToast } = useToasts()

    const backend = useContext(BackendContext)
    const history = useHistory()
    const [user,] = useContext(UserContext)

    const profileTimer = useRef(null)

    const updateDeviceProfile = async() => {

        let result = await backend.retrieveFresh(`devices/${device.deviceid}/profile`)

        let notify = device.profile.stauts !== result.content.status

        device.profile = result.content

        if(device.profile.status === profileStatus.CONFIRMED){

            if(notify)
                addToast('Perfil associado com sucesso', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 1500,
                })

            setDevice({...device})
            return
        }

        profileTimer.current = setTimeout(() => {updateDeviceProfile()}, 2000)
    }

    let confirmRemoveAssociate = async(confirmed) => {

        if(confirmed){
            let result = await backend.delete(`devices/associate_request/${confirm.id}`)

            if(result.status === backendStatus.SUCCESS){
                addToast(`Associação do dispositivo ${confirm.id} removida com sucesso`, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 5000,
                })

                onDeviceRemoved()
            }
        }

        setConfirm({confirm: false, id: ""})
    }

    useEffect(() => {
        if(device.profile.status === profileStatus.ASSOCIATING){
            updateDeviceProfile()
        }
        return () => {
            clearTimeout(profileTimer.current)
        }

        // eslint-disable-next-line 
    }, [])

    const isConfirmed = ({status, assoc_status}) => {
        return status !== deviceStatus.WAITING && assoc_status === assocStatus.ASSOCIATED
    }

    const isError = ({assoc_status}) => {
        return assoc_status === assocStatus.NOT_ASSOCIATED
    }

    const getProfile = (profileid) => {
        for(let profile of profiles) {

            if (profile.id === profileid)
                return profile
        }
    }

    const handleSelectProfile = (value) => {

        let profileid = value
        let profile = getProfile(profileid)

        if(profile.autoApply){
            setWarnAutoApply({confirm: true, id: profileid})
            return
        }

        setPristine(false)
        device.profile.profileid = profileid
        setDevice({...device})
    }

    const proceedWithProfileSelection = (confirmed) => {

        if(!confirmed) {
            setWarnAutoApply({confirm: false, id: ''})
            return
        }

        setPristine(false)
        device.profile.profileid = warnAutoApply.id
        setDevice({...device})
        setWarnAutoApply({confirm: false, id: ''})
    }

    return(
    <div className='device-details-overlay' onClick={onOverlayClick}>


        <div className='device-details' onClick={(e) => e.stopPropagation()}>

            <Modal
                title='Perfil autoaplicável'
                show={warnAutoApply.confirm}
                content={<div style={{width: '400px'}}>
                    Ao salvar, as configurações deste perfil serão aplicadas imediatamente no roteador,
                    modificando todas as configurações atuais.
                    <br></br>
                    <br></br>
                    <b>Deseja selecionar este perfil?</b>
                </div>}
                onDismissClick={proceedWithProfileSelection}
                type={ModalTypes.CONFIRM_WARNING}
                dismissText='Não, obrigado'
                confirmText='Sim, quero selecionar'
            ></Modal>

            {!isConfirmed(device) && !isError(device) ? <div id='device-details-waiting'>
                <div className='subtitle'>Aguardando confirmação</div>
                <NoConnectionIcon size={50}></NoConnectionIcon>
                Este dispositivo ainda não concluiu o processo de associação à sua conta Remotize.
                Se a associação não for confirmada em alguns minutos, você pode verificar:
                <ul>
                    <li>Se o dispositivo possui conexão à internet</li>
                    <li>Se o MAC da LAN do dispositivo foi digitado corretamente</li>
                    <li>Se, ao reiniciar o dispositivo, o processo é concluído</li>
                    <li>Se, ao remover o dispositivo (botão abaixo) e reassociá-lo, o processo é concluído</li>
                </ul>
                Caso o problema persista, contate o suporte técnico.
            </div> : null}

            {isError(device) ? <div id='device-details-error'>
                <div className='subtitle'>Erro na associação</div>
                <NoConnectionIcon size={50}></NoConnectionIcon>
                Este dispositivo não foi associado à sua conta Remotize devido às credenciais fornecidas. Você pode verificar:
                <ul>
                    <li>Se o MAC da LAN do dispositivo foi digitado corretamente</li>
                    <li>Se as credenciais de acesso do dispositivo foram alteradas</li>
                    <li>Se, ao remover o dispositivo (botão abaixo) e reassociá-lo, o processo é concluído</li>
                </ul>
                Caso o problema persista, contate o suporte técnico.
            </div> : null}


            {isConfirmed(device) ? <div id='device-details-container'>

                <div className='device-details-close' onClick={onOverlayClick}>
                    <CloseIcon size='15px'></CloseIcon>
                </div>

                <div className='device-details-title'>
                    {`${device.model} - ${device.deviceid}`}
                </div>

                <InfoLine label='Versão de firmware' value={device.fw_version}></InfoLine>

                <InfoLine label='Status' value={device.status === deviceStatus.ONLINE ? 'Online' : 'Offline'}></InfoLine>

                <div className='profile-status'>
                    <InfoLine label='Status do perfil padrão' value={getProfileStatus(device.profile.status)}></InfoLine>
                    {profileIsApplying(device) && <Loading show={true} mini></Loading>}
                </div>

                {DeviceFactory.isTR069(device.model) ?
               null:
               <div className='profile-select'>
                <SelectSearch id='device-profile'
                    label='Novo perfil de padrão de fábrica'
                    placeholder='Pesquise pelo nome do perfil'
                    value={device.profile.profileid}
                    onSelect={handleSelectProfile}
                    options={getProfileOptions(profiles)}
                ></SelectSearch>
                </div>}

                <Input id='device-clientid'
                    label='ID do cliente'
                    value={device.clientid || ''}
                    onChange={(e) => {
                        setPristine(false)
                        device.clientid = e.target.value
                        setDevice({...device})
                    }}
                ></Input>

                {device.fw_version.includes("1.0.4") || device.fw_version.includes("1.0.3") || device.fw_version.includes("1.1.0") ?

                <div className='info-text'>
                    Atualize seu dispositivo para a última versão disponível para que o perfil seja associado com sucesso.
                </div>

                : null}

                <div className='device-apply-button'>

                    <Button text='Salvar'
                        disabled={pristine && device.profile.status === PROFILE_STATUS_CONFIRMED}
                        onClick={() => {
                            onSubmit(device)
                            setPristine(true)
                        }}
                    ></Button>

                </div>
            </div>:null}

            {canBeManaged(device) && !DeviceFactory.isGateway(device.model)?
                <RouterButton
                    id='manage-button'
                    label='Gerenciar dispositivo'
                    onClick={() => history.push(`/manage/${device.deviceid}/wan`)}
                ></RouterButton>
            :
                <div className='no-managed'> A função gerenciar dispositivos está disponível apenas para as versões mais recentes de firmware,
                    recomendamos que mantenha sempre seu parque atualizado com a última versão de firmware disponível na
                    plataforma, acessando a aba Atualização.
                </div>}

            <div className='device-remove-associate'>

                <div className='device-remove-associate-container'>
                    <Modal
                        title='Remover dispositivo'
                        show={confirm.confirm}
                        content={<div style={{width: '400px'}}>
                            Ao remover o dispositivo <b>{confirm.id}</b>, você não poderá mais gerenciá-lo de forma centralizada.
                            <br></br>
                            <br></br>
                            <b>Tem certeza que deseja remover o dispositivo?</b>
                        </div>}
                        onDismissClick={confirmRemoveAssociate}
                        type={ModalTypes.CONFIRM_WARNING}
                        dismissText='Não, quero manter'
                        confirmText='Sim, quero remover'
                    ></Modal>

                    {user.type === RemotizeType.ADMIN || isError(device) ? [
                        <div className='info-text' key='info-1'>
                        Utilize o botão abaixo se quiser remover este dispositivo da sua conta Remotize. Lembramos que você
                        poderá realizar a associação novamente em outro momento, caso desejado.
                        </div>,
                        <Button text='Remover Associação' key='info-2'
                            onClick={() => setConfirm({confirm: true, id: device.deviceid})}
                            isError='true'
                        ></Button>
                     ] : null}
                </div>
            </div>
        </div>
    </div>)

}