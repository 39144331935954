import React, { useContext } from 'react'

import './radio.css'
import { FormContext } from '../form/form'

export default function RadioButton({value, toggleFn, id, label, name, disabled, validateOnChange = false}){

    const formContext = useContext(FormContext)

    return (
        <div className={`radio-group ${label ? 'full-width' : ''} ${disabled ? 'disabled' : ''}`}>

            <input className='itb-radio'
                id={id || name}
                name={name || id}
                value={value}
                checked={value}
                onChange={async (e) => {
                    if(disabled){
                        return
                    }
                    if(formContext)
                        formContext.setPristine(false)
                    await toggleFn(e)
                    if (validateOnChange)
                        await formContext.runValidators()
                }}
                type='radio'
            >
            </input>

            {label ? <label htmlFor={id || name}>{label}</label> : ''}

        </div>
    )
}