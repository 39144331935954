import React, { useState, useContext } from 'react'
import Input from '../../../components/input/input';
import Button from '../../../components/button/button'
import RemotizeDevices from '../../remotize-devices'

import { BackendContext, backendStatus } from '../../../backend/backend'
import DefaultStatusModals from '../../../components/modal/default-status-modals'

import './firmware.css'

export default function MandatoryVersionUpdate() {

    const [mandatoryVersion, setMandatoryVersion] = useState('')
    const [model, setModel] = useState('GF1200')

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const backend = useContext(BackendContext)

    const submit = async(e) => {
        e.preventDefault()
        
        setLoading(true)

        let result = await backend.create('mandatory_update_version', {
            model: model,
            version: mandatoryVersion
        })

        if(result.status === backendStatus.SUCCESS){
            setLoading(false)
            setSuccess(true)
            return
        }

        setLoading(false)
        setError(true)
    }

    const continueFn = () => {
        setLoading(false)
        setSuccess(false)
        setError(false)
    }

    return <div className='mandatory-update-box'>

        <DefaultStatusModals
            success={success}
            saving={loading}
            error={error}
            successText='Versão enviada com sucesso'
            savingText='Aguarde enquanto a versão é salva'
            errorText='Houve um erro ao salvar o versão'
            continueFn={continueFn}
        ></DefaultStatusModals>

        <br></br>
        <form className='mandatory-version-update-form' onSubmit={submit}>
            <div className='subtitle'>Atualização obrigatória</div>

            <Input id='mandatory-version'
                label='Versão'
                placeholder='1.0.0'
                value={mandatoryVersion}
                onChange={(e) => setMandatoryVersion(e.target.value)}
            >
            </Input>

            <RemotizeDevices
                model={model}
                setModel={setModel}
            ></RemotizeDevices>

            <Button text='Salvar'></Button>
        </form>
        
    </div>
}