import { backendStatus } from "../../backend/backend"
import BifrostManagePage from "../pages/devices/management/bifrost/bifrost-manage-page"
export default class Device {

    constructor(device) {
        this.data = device
        this.api = null
    }

    getManagementPage() {
        return <BifrostManagePage></BifrostManagePage>
    }

    async createResource(resourceName, data) {
        return await this.api.create(this.data.deviceid, resourceName, data)
    }

    async retrieveResource(resourceName) {

        let result = await this.api.retrieve(this.data.deviceid, resourceName)

        if(result && result.status !== backendStatus.SUCCESS) {
            console.error('error:', result.content)
        }

        return result
    }

    async updateResource(resourceName, data) {
        return await this.api.update(this.data.deviceid, resourceName, data)
    }

    async deleteResource(resourceName, data) {
        return await this.api.delete(this.data.deviceid, resourceName, data)
    }

    async apply() {

        return await this.api.apply(this.data.deviceid)
    }

}