import React, { useContext, useEffect, useState } from 'react'
import { MainHeaderContext } from '../components/main-header/main-header-state'
import LoginPage from './pages/login/login-page'
import DevicesPage from './pages/devices/devices-page'
import AssociateDevice from './pages/associate_device/associate-device'
import UpdatePage from './pages/update/update-page'
import ProfilesPage from './pages/profiles/profile-main-page'
import UploadFwPage from './pages/firmware/uploadfw-page'
import AppPermissionsPage from './pages/app-permissions/app-permissions-page'
import Loading from '../components/loading/loading'
import isElectron from './server/is-electron'

import { Switch, Route, useHistory, useLocation } from 'react-router-dom'

import { ToastProvider } from 'react-toast-notifications'
import { DismissAllToast } from '../components/custom-toast/custom-toast'
import { CustomToastContainer } from '../components/custom-toast/custom-toast-container'

import './main-app.css'
import RemotizeMenu from './remotize-menu'
import Notifications from '../components/notifications/notifications'
import Firebase from './firebase'
import GLogin from './glogin'
import SignUPPage from './pages/signup/signup'
import User, { UserContext } from './user-context'
import DownloadsPage from './pages/downloads/downloads-page'
import FaqPage from './pages/faq/faq-page'
import { BackendContext, backendStatus } from '../backend/backend'
import { NotificationsContext } from '../components/notifications/notifications-state'
import ConnectionStatus from './pages/connection_status/connection-status'
import BlockedAccount from './pages/blocked-account/blocked-account'
import AccountsPage from './pages/accounts/accounts-page'
import LGPDAdminPage from './pages/lgpd/lgpd-admin'
import LGPDConsentPage from './pages/lgpd/lgpd-consent'
import MyAccountWidget from './pages/accounts/my-account-widget'
import MyAccountPage from './pages/accounts/my-account-page'
import DeviceManagePage from './pages/devices/management/manage-page'
import UIEvents, { UIEventsContext } from './uievents-context'
import { MenuContext } from '../components/menu/menu-state'
import LoginOAuthPage from './pages/login/login-oauth-page'

import MaintenancePage from './pages/maintenance/maintenance-page'

const MENU_BREAK_MIN_WIDTH = 1400
function getClasses(header){

    return header.hidden.value    ? '' :
           header.extended.value  ? 'with-header-extended' : 
                                    'with-header'
}

export function isOAuth(history) {
    return history.location.pathname.includes('auth')
}


function MainComponents() {

    const headerState = useContext(MainHeaderContext)
    const backend =  useContext(BackendContext)
    const notificationsState = useContext(NotificationsContext)
    const uievents = useContext(UIEventsContext)
    const menu = useContext(MenuContext)
    const location = useLocation()

    const [user, setUser] = useContext(UserContext)

    const [bodyWidth, setBodyWidth] = useState(document.body.clientWidth)

    const [bypassRemotizeMaintenance, setBypassRemotizeMaintenance] = useState(true)

    const deleteNotification = async(id) => {

        await backend.delete(`notifications/${id}`)
        fetchNotifications()

    }

    const fetchNotifications = async() => {

        let notifications = await backend.retrieveFresh('notifications')

        if(notifications.status !== backendStatus.SUCCESS)
            return

        if(notifications.content.length === 0)
            notificationsState.open.set(false)

        if(notificationsState.notifications.value.length !== notifications.content.length){
            notificationsState.notifications.set(notifications.content)
        }
    }

    useEffect(() => {
        const fetchMaintenanceBypass = async() => {
            let userid = user.username
            let result = await backend.retrieveFresh(`maintenance/bypass/${userid}`)
            if(result.status === backendStatus.SUCCESS){
                setBypassRemotizeMaintenance(result.content.bypass_authorization)
            }
        }

        fetchMaintenanceBypass()
    }, [bypassRemotizeMaintenance])

    useEffect(() => {
        uievents.setHandler('notifications', fetchNotifications)
        uievents.setHandler('user', fetchUser)

        if(document.body.clientWidth >= MENU_BREAK_MIN_WIDTH && location.pathname !== "/glogin")
            menu.fixed.set(true)

        window.addEventListener('resize', () => {
            setBodyWidth(document.body.clientWidth)
        })

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(bodyWidth <= MENU_BREAK_MIN_WIDTH) {
            if(menu.fixed.value)
                menu.fixed.set(false)
        }
        if(bodyWidth > MENU_BREAK_MIN_WIDTH) {
            if(!menu.fixed.value)
                menu.fixed.set(true)
        }
        // eslint-disable-next-line
    }, [bodyWidth])

    const fetchUser = async() => {
        let result = await backend.retrieveFresh(`users/${user.id}`)
        if(result.status === backendStatus.SUCCESS){
            setUser(result.content)
        }  
    }

    useEffect(() => {
        if(!user) return

        if(!isElectron()){
            fetchNotifications()
        }

        // eslint-disable-next-line
    }, [user])

    if(!bypassRemotizeMaintenance) {
        return !user ? <Loading></Loading> : <ToastProvider components={{ Toast: DismissAllToast, ToastContainer: CustomToastContainer }}>

            <div id='main-app' className={getClasses(headerState)}>

                <RemotizeMenu></RemotizeMenu>
                <Notifications onRemove={deleteNotification}></Notifications>
                <MyAccountWidget></MyAccountWidget>

                <Switch>
                    <Route path='/' component={isElectron() ? AssociateDevice : 
                        bypassRemotizeMaintenance ? DevicesPage : MaintenancePage}></Route>
                    
                </Switch>

            </div>
        </ToastProvider> 
    }
    
    return !user ? <Loading></Loading> : <ToastProvider components={{ Toast: DismissAllToast, ToastContainer: CustomToastContainer }}>

        <div id='main-app' className={getClasses(headerState)}>

            <RemotizeMenu></RemotizeMenu>
            <Notifications onRemove={deleteNotification}></Notifications>
            <MyAccountWidget></MyAccountWidget>

            <Switch>
                <Route path='/myaccount*' component={MyAccountPage}></Route>
                <Route path='/myaccount*' component={MyAccountPage}></Route>
                <Route path='/consent*' component={LGPDConsentPage}></Route>
                <Route path='/lgpdadmin*' component={LGPDAdminPage}></Route>
                <Route path='/connection-status' component={ConnectionStatus}></Route>
                <Route path='/glogin*' component={GLogin}></Route>
                <Route path='/downloads*' component={DownloadsPage}></Route>
                <Route path='/update*' component={UpdatePage}></Route>
                <Route path='/profiles*' component={ProfilesPage}></Route>
                <Route path='/firmwares*' component={UploadFwPage}></Route>
                <Route path='/signup*' component={SignUPPage}></Route>
                <Route path='/app*' component={AppPermissionsPage}></Route>
                <Route path='/manage/:id' component={DeviceManagePage}></Route>
                <Route path='/blocked*' component={BlockedAccount}></Route>
                <Route path='/accounts*' component={AccountsPage}></Route>
                <Route path='/' component={isElectron() ? AssociateDevice : DevicesPage}></Route>
                
            </Switch>

        </div>
    </ToastProvider> 

}

function MainEntry(){

    return(
        <User>
            <UIEvents>
                <MainComponents></MainComponents>
            </UIEvents>
        </User>
    )

}

export default function MainApp() {
    const backend =  useContext(BackendContext)
    const history = useHistory()

    useEffect(() => {
        backend.setOnUnauthorized(() => {
            history.push("/login")
        })

        // eslint-disable-next-line 
    }, [])

    return(
    <React.Fragment>
        {!isOAuth(history) && <Firebase>
            <Switch>
                <Route path='/login' component={LoginPage}></Route>
                <Route path='/' component={MainEntry}></Route>
            </Switch>
        </Firebase>}
        <Route path='/auth' component={LoginOAuthPage}></Route>
        <Route path='/faq' component={FaqPage}></Route>
    </React.Fragment>)
}