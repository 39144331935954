import React, { useEffect, useRef } from 'react'
import { backendStatus, BackendContext } from "../../../../../../backend/backend"
import { useContext, useState } from "react"


import "../../../../../../../node_modules/react-vis/dist/style.css"
import { XYPlot, LineSeries, XAxis, YAxis, AreaSeries, ChartLabel } from 'react-vis'
import Loading from '../../../../../../components/loading/loading'
import { getTraffic } from '../../manage-page'

const FECTH_INTERVAL = 5000
const MAX_SAMPLES = 60
const RETRY_TIME = 2000

export default function WanStatistics({deviceid, wan, withIpv6}) {

    const [statistics, setStatistics] = useState({rx:0, tx:0, unity_size: 10000, time: 0})
    const [traffic, setTraffic] = useState(null)
    const [peak, setPeak] = useState({download:0, upload:0})
    const [uploadSeries, setUploadSeries] = useState([])
    const [downloadSeries, setDownloadSeries] = useState([])

    const backend = useContext(BackendContext)
    const statsTimer = useRef(null)
    const mounted = useRef(true)

    useEffect(() => {

        fetchStatistics()

        return () =>{
            mounted.current = false
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(mounted.current)
            statsTimer.current = setTimeout(fetchStatistics, FECTH_INTERVAL)

        return () => {
            clearTimeout(statsTimer.current)
        }

        // eslint-disable-next-line
    }, [statistics])

    useEffect(() => {

        if(!traffic) return

        uploadSeries.push(traffic.upload)
        downloadSeries.push(traffic.download)

        if(uploadSeries.length > MAX_SAMPLES){
            uploadSeries.shift()
            downloadSeries.shift()
        }

        setUploadSeries([...uploadSeries])
        setDownloadSeries([...downloadSeries])

        // eslint-disable-next-line
    }, [traffic])

    const fetchStatistics = async() => {

        if(wan.interfaceID === 'new') return

        let result = await backend.retrieveFresh(
            `deviceproxy/${deviceid}/interface_statistics/${wan.interfaceID}`
        )

        if(result.status !== backendStatus.SUCCESS){
            console.log('Error fetching statistics:', result.content)
            if(mounted.current)
                statsTimer.current = setTimeout(fetchStatistics, RETRY_TIME)
            return
        }

        defineTraffic(result.content)

    }

    const defineTraffic = (received)  => {

        let upload = getTraffic(
                received.tx - statistics.tx,
                received.unity_size,
                received.time - statistics.time
            )

        let download = getTraffic(
                received.rx - statistics.rx,
                received.unity_size,
                received.time - statistics.time
            )

        if(upload < 0 || download < 0) return

        let newTraffic = {
            upload: upload,
            download: download,
        }

        if(newTraffic.download > peak.download){
            setPeak({...peak, download: newTraffic.download})
        }

        if(newTraffic.upload > peak.upload){
            setPeak({...peak, upload: newTraffic.upload})
        }

        setStatistics(received)
        setTraffic(newTraffic)
    }

    const getData = (series) => {

        return series.map((value, index) => {
            return {x: index, y: Number(value)}
        })
    }

    const getTick = (v) => {

        let newTime = new Date()
        newTime.setMilliseconds(newTime.getMilliseconds() + FECTH_INTERVAL*v)

        let hours = newTime.getHours()
        let minutes = newTime.getMinutes()
        let seconds = newTime.getSeconds()

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }

    if(!traffic)
        return <Loading show={true}></Loading>

    let container = document.getElementById('management-wan')

    return <div id='device-wan-statistics'>

        <div className='subtitle'>Tráfego</div>

        <div className='chart'>
            <XYPlot height={180} width={container.clientWidth - 720}>
                <XAxis tickFormat={getTick} tickTotal={5} tickPadding={18}></XAxis>
                <YAxis tickPadding={10}></YAxis>
                <LineSeries data={getData(uploadSeries)} strokeWidth={1} color='green'></LineSeries>
                <AreaSeries data={getData(uploadSeries)} opacity={.2} color='green'></AreaSeries>
                <LineSeries data={getData(downloadSeries)} strokeWidth={1} color='darkcyan'></LineSeries>
                <AreaSeries data={getData(downloadSeries)} opacity={.2} color='darkcyan'></AreaSeries>
                <ChartLabel
                    text={"Hora"}
                    includeMargin={false}
                    xPercent={0.98}
                    yPercent={1.18}
                    style={{ textAnchor: 'right' }}
                />
                <ChartLabel
                    text={"Mbps"}
                    includeMargin={false}
                    xPercent={-0.007}
                    yPercent={0.1}
                    style={{ transform: 'rotate(-90)',textAnchor: 'middle' }}
                />

            </XYPlot>
        </div>

        <div className='legend'>
            <div className='series download'>
                <label>Download:</label>
                <span>{traffic.download.toFixed(2)} Mbps</span>
                <label>Pico:</label>
                <span>{peak.download.toFixed(2)} Mbps</span>
            </div>
            <div className='series upload'>
                <label>Upload:</label>
                <span>{traffic.upload.toFixed(2)} Mbps</span>
                <label>Pico:</label>
                <span>{peak.upload.toFixed(2)} Mbps</span>
            </div>
        </div>

    </div>
}