
const CnpjUtils = {

    isValidValue: (e) => {

        let value = e.target.value

        for(let c of value){
            if(isNaN(c) && c !== '.' && c !== '/' && c !== '-')
                return false
        }

        return true
    },

    applyMask: (cnpj) => {

        cnpj = cnpj.replace(/\.|\/|-/gi, '')

        let masked = ''

        for(let i in cnpj){
            masked += cnpj[i]
            if(i === '1' || i === '4')
                masked += '.'
            if(i === '7')
                masked += '/'
            if(i === '11')
                masked += '-'
        }

        return masked
    },

    removeMask: (cnpj) => {

        return cnpj.replace(/\.|\/|-/gi, '')
    }

}

export default CnpjUtils