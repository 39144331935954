import React, { useContext } from 'react'

import './checkbox.css'
import { FormContext } from '../form/form'

export default function Checkbox({value, toggleFn, id, label, name, disabled, validateOnChange = false, clearErrors = false}){

    const formContext = useContext(FormContext)

    return (
        <div className={`checkbox-group ${label ? 'full-width' : ''} ${disabled ? 'disabled' : ''}`}>

            <input className='itb-checkbox'
                id={id || name}
                name={name || id}
                value={value}
                checked={value}
                onChange={async (e) => {
                    if(disabled)
                        return
                    if(formContext) {
                        formContext.setPristine(false)

                        if (clearErrors)
                            formContext.clearErrors()
                    }
                    await toggleFn(e)
                    if (formContext && validateOnChange)
                        await formContext.runValidators()
                }}
                type='checkbox'
            >
            </input>

            <label className='after-input' htmlFor={id || name}>
                {label ? label : ''}
            </label>


        </div>
    )
}