import React, { useEffect, useState, forwardRef } from 'react'
import Joyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride';
import HelpIcon from '../../../components/icons/wizard'
import './update-tour.css'

const joyLocale = {back: 'Voltar', close: 'Fechar', last: 'Entendido', next: 'Próximo', skip: 'Pular'}

export default function UpdateTour({setTour}){
    const [dontShowAgain, setDontShowAgain] = useState(false)
    const [updateTour, setUpdateTour] = useState(false)

    useEffect(() => {
        let update_tour = localStorage.getItem('update_tour')
        
        if((update_tour === 'true' || update_tour === null)){
            setUpdateTour(true)
            setTour(true)
        } else {
            setTour(false)
        }

        // eslint-disable-next-line
    }, [])

    const DontShowAgain = () => {
        return( <div className='dont-show'>
                    <input 
                        type="checkbox"
                        checked={dontShowAgain}
                        onChange={(e) => {setDontShowAgain(!dontShowAgain)}}>
                    </input>
                    <label className='dont-show-label'>
                        Não mostrar este guia novamente
                    </label>
                </div>
        )
    }

    const steps = [
        {
            content: <div>
                        <p>
                            Esta é a pagina de Atualização.
                        </p>
                        <p>
                            Nela você poderá gerenciar todos os dispositivos do seu parque,
                            além de configurar atualização em massa e atualização automática controlada
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'center',
            target: 'body',
            title: 'Atualização',
        },
        {
            content: <div>
                        <p>
                            A atualização em massa corresponde na atualização automática de todos os dispositivos associados.
                        </p>
                        <p>
                            Essa atualização acontece durante a janela de horário configurada.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'center',
            target: 'body',
            title: 'Atualização em Massa',
        },
        {
            content: <div>
                        <p>
                            A atualização automática controlada serve para validar uma versão antes da atualização em massa.
                        </p>
                        <p>
                            Alguns dispositivos selecionados serão atualizados automaticamente, assim que uma nova versão ficar disponível e
                            permanecerão com essa versão por um determinado período configurável.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'center',
            target: 'body',
            title: 'Atualização Automática Controlada',
        },
        {
            content: <div>
                        <p>
                            Aqui serão apresentados todos dispositivos associados, organizados por modelo e versão.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'left',
            target: '#update-overview',
            title: 'Visão Geral',
        },
        {
            content: <div>
                        <p>
                            Aqui ficarão disponíveis as configurações de atualização em massa e atualização automática controlada.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#update-configs',
            title: 'Configurações de Atualização',
        },
        {
            content: <div>
                        <p>
                            Aqui poderá ser configurada a janela de atualização automática.
                        </p>
                        <p>
                            Este será o período em que os dispositivos serão atualizados automaticamente.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#update-time-window',
            title: 'Período de Atualização',
        },
        {
            content: <div>
                        <p>
                            Aqui serão listados todos os modelos associados, juntamente com as últimas versões disponíveis e as possíveis ações.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#list',
            title: 'Lista de Modelos e Versões',
        },
        {
            content: <div>
                        <p>
                            A ação "Ativar" faz com que a versão fique ativa.
                        </p>
                        <p>
                            Com isso, todos os dispositivos associados serão atualizados na próxima janela de atualização.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#update-action-active',
            title: 'Ativar Versão',
        },
        {
            content: <div>
                        <p>
                            A ação "Parar" faz com que a validação seja interrompida.
                        </p>
                        <p>
                            Com isso, a versão ficará disponível para validação novamente.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#update-action-stop',
            title: 'Parar Validação',
        },
        {
            content: <div>
                        <p>
                            Caso a validação seja interrompida, a ação "Reiniciar" faz com que o período de validação da versão seja reiniciado.
                        </p>
                        <p>
                            Com isso, a versão passará novamente pelo período de validação.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#update-action-restart',
            title: 'Reiniciar período de validação',
        },
        {
            content: <div>
                        <p>
                            Esta opção permite que todos os novos dispositivos associados sejam atualizados imediatamente,
                            ignorando a janela de atualização.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#update-2',
            title: 'Atualização Imediata para Novos Dispositivos',
        },
        {
            content: <div>
                        <p>
                            Esta opção permite realizar atualização automática controlada em dispositivos específicos.
                        </p>
                        <p>
                            Os dispositivos selecionados serão atualizados assim que uma nova versão ficar disponível
                            e esta versão ficará em período de validação.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#update-3',
            title: 'Atualização Automática Controlada',
        },
        {
            content: <div>
                        <p>
                            Este campo define o tempo em que as versões ficarão em período de validação.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#auto-update-days-input',
            title: 'Tempo de Validação da Atualização Automática Controlada',
        },
        {
            content: <div>
                        <p>
                            Neste botão você pode adicionar os dispositivos para o período de teste.
                        </p>
                        <p>
                            A busca deve ser feita utilizando o MAC.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#add-update-device',
            title: 'Adicionar Dispositivos para Atualização Automática Controlada',
        },
        {
            content: <div>
                        <p>
                            Aqui será lista de todos os dispositivos selecionados para o período de teste.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '#update-device-search-list',
            title: 'Lista de Dispositivos para Atualização Automática Controlada',
        },
        {
            content: <div>
                        <p>
                            Aqui será mostrado o status da atualização automática controlada.
                        </p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'left',
            target: '#update-validating-section',
            title: 'Atualização Automática Controlada',
        },
    ]

    const handleTourCallback = (data) => {
        const { action, status, type } = data;

        if([ACTIONS.CLOSE].includes(action)){
            setTour(false)
            if(dontShowAgain){
                localStorage.setItem('update_tour', false)
            }

            setUpdateTour(false)
        }

        if([STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
            [EVENTS.TOUR_END].includes(type)) {

            if(dontShowAgain){
                localStorage.setItem('update_tour', false)
            }

            setTour(false)
            setUpdateTour(false)
        }

        setSpotlightPosition()
    };

    const handleShowTour = () => {
        setUpdateTour(true)
        setDontShowAgain(false)

        localStorage.setItem('update_tour', true)
    }

    const emptyBeacon = forwardRef((props, ref) => {
        return <div ref={ref}></div>;
    });

    const setSpotlightPosition = () => {
        const spotlight = document.querySelector('.react-joyride__spotlight')
        if (spotlight) {
            const offset = document.documentElement.scrollTop
            const current = parseInt(spotlight.style.top)
            spotlight.style.top = `${offset + current}px`
        }
    }

    return(
        <div>
            <div className="help-icon" onClick={handleShowTour}>
                <HelpIcon title={"Executar o guia de ajuda"}></HelpIcon>
            </div>

            {!updateTour ? null :
            <div>
                <Joyride
                    steps={steps}
                    callback={handleTourCallback}
                    continuous={true}
                    run={true}
                    styles={{
                        options: {
                            primaryColor: '#00a335',
                            zIndex: 10000,
                        },
                    }}
                    locale={joyLocale}
                    showSkipButton={true}
                    disableOverlayClose={true}
                    disableCloseOnEsc={true}
                    beaconComponent={emptyBeacon}
                    showProgress={true}
                />

            </div> }
        </div>
        
    )
}