import React from 'react'
import Checkbox from '../../../../../../components/checkbox/checkbox';
import common from '../../../../../../components/form/validators/common';
import network from '../../../../../../components/form/validators/network';
import Input from '../../../../../../components/input/input';
import Loading from '../../../../../../components/loading/loading';
import WanDNS from './wan-dns';

export default function WanDHCP({ _interface, setInterface, wan, setWan, wanIndex, isRFO }) {

    return (

        !_interface ? <Loading show={true}></Loading> :

            <React.Fragment>

                <Input id='wan-dhcp-mtu'
                    name='mtu'
                    label='MTU'
                    value={_interface.mtu}
                    onChange={(event) => {
                        let value = event.target.value

                        if (isNaN(value)) return

                        _interface.mtu = Number(value)
                        setInterface({ ..._interface })
                    }}
                    validators={[common.required, network.mtu]}
                ></Input>

                {
                    <Checkbox
                        id={'wan-dhcp-toggle5'}
                        label='Obter DNS automaticamente'
                        value={wan[wanIndex].dns_v4.automatic}
                        toggleFn={() => {
                            wan[wanIndex].dns_v4.automatic = !wan[wanIndex].dns_v4.automatic
                            setWan([...wan])
                        }}
                    ></Checkbox>
                }
                {WanDNS(_interface, wan, setWan, wanIndex)}

            </React.Fragment>
    )
}