import React, { useEffect, useState } from 'react'
import Input from '../../../components/input/input';
import common from '../../../components/form/validators/common'
import network from '../../../components/form/validators/network'
import Checkbox from '../../../components/checkbox/checkbox';
import { toInt } from '../../../components/utils/iputils'

function validateDhcpNetwork(ipDhcp, params){

    if(ipDhcp === '')
        return ''

    let message = network.sameNetwork(ipDhcp, params)

    if(message !== ''){
        return 'Este endereço não pertence à rede LAN especificada'
    }

    return ''
}

function LeaseTimeValidator(target){

    let value = parseInt(target)

    if(value <= 0 || value > 86400) {//480 minutos default
        return 'O tempo de release deve estar entre 1 minuto e 86400 minutos'
    }

    return ''
}

export default function PofileDhcp({dhcp, setDhcp, lan, setExternalError}) {

    const [rangeError, setRangeError] = useState('')

    useEffect(() => {

        validateDhcpRange()

        // eslint-disable-next-line
    }, [dhcp.rangeStart, dhcp.rangeEnd])

    useEffect(() => {

        setExternalError(rangeError !== '')

        // eslint-disable-next-line 
    }, [rangeError])

    function validateDhcpRange() {

        setRangeError('')

        let start =  toInt(dhcp.rangeStart)
        let end =  toInt(dhcp.rangeEnd)

        if(start >= end)
            setRangeError('O endereço inicial deve ser menor que o final')

    }
    
    const handleLeaseTime = (e) => {

        let value = e.target.value

        if(isNaN(value)) return

        value = Number(value)
        if(e.target.value === ''){
            value = 0
        }
        
        setDhcp({...dhcp, dhcpLeaseTime: value})
    }


    return [

        <Checkbox
            id='dhcp-enabled'
            label='Habilitado'
            value={dhcp.enabled}
            toggleFn={() => {setDhcp({...dhcp, enabled: !dhcp.enabled})}}
            key='dhcps-1'
        ></Checkbox>,

        <Input id='dhcp-range-start'
            collapse={!dhcp.enabled}
            label='Endereço inicial'
            value={dhcp.rangeStart}
            name='dhcp-range-start'
            onChange={(e) => {
                setDhcp({...dhcp, rangeStart: e.target.value})}
            }
            validators={[
                common.required,
                network.ip4,
                {fn: validateDhcpNetwork, params:{ip: lan.ip, network: lan.netmask}},
            ]}
            key='dhcps-2'
            errorMessage={rangeError}
        ></Input>,

        <Input id='dhcp-range-end'
            collapse={!dhcp.enabled}
            label='Endereço final'
            value={dhcp.rangeEnd}
            name='dhcp-range-end'
            onChange={(e) => {
                setDhcp({...dhcp, rangeEnd: e.target.value})}
            }
            validators={[
                common.required,
                network.ip4,
                {fn: validateDhcpNetwork, params:{ip: lan.ip, network: lan.netmask}},
            ]}
            key='dhcps-3'
            errorMessage={rangeError}
        ></Input>,

        <Input id='wan-dhcpLeaseTime'
            name='dhcp.dhcpLeaseTime'
            label='Tempo de Lease (minutos)'
            value={dhcp.dhcpLeaseTime}
            onChange={handleLeaseTime}
            validators={[common.required,LeaseTimeValidator]}
            key='dhcp-6'
        ></Input>,

        <Input id='dhcp-dns1'
            collapse={!dhcp.enabled}
            label='DNS 1'
            value={dhcp.dns1}
            name='dhcp-dns1'
            onChange={(e) => setDhcp({...dhcp, dns1: e.target.value})}
            validators={[common.required, network.ip4]}
            key='dhcps-4'
        ></Input>,

        <Input id='dhcp-dns2'
            collapse={!dhcp.enabled}
            label='DNS 2'
            value={dhcp.dns2}
            name='dhcp-dns2'
            onChange={(e) => setDhcp({...dhcp, dns2: e.target.value})}
            validators={[network.ip4]}
            key='dhcps-5'
        ></Input>,

    ]
}