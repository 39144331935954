import TR069ManagePage from '../pages/devices/management/tr069/tr069-manage-page'
import { TR069API } from './tr069/tr069_api'
import Device from './device'

export default class TR069Device extends Device {

    constructor(device, backend) {
        super(device, backend)
        this.api = new TR069API(backend)
    }

    getManagementPage() {
        return <TR069ManagePage></TR069ManagePage>
    }

    isDualBand() {
        return this.data.model !== 'ONT 121 W'
    }

}