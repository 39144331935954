import React from 'react'

export default function SignalIcon({size, signal_level, moderate_signal_threshold=-50, bad_signal_threshold=-70}){

    const getSignalColor = (signal_level) => {
        if(signal_level > moderate_signal_threshold){
            return '#00994C'
        }
        if(signal_level >= bad_signal_threshold && signal_level <= moderate_signal_threshold){
            return '#FFE600'
        } else {
            return '#FF0000'
        }
    }

    return (<svg 
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="signal"
                role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                width={size}
                height={size}>
                <path 
                    fill={getSignalColor(signal_level)} 
                    d="M216 288h-48c-8.84 0-16 7.16-16 16v192c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V304c0-8.84-7.16-16-16-16zM88 384H40c-8.84 0-16 7.16-16 16v96c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16v-96c0-8.84-7.16-16-16-16zm256-192h-48c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm128-96h-48c-8.84 0-16 7.16-16 16v384c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V112c0-8.84-7.16-16-16-16zM600 0h-48c-8.84 0-16 7.16-16 16v480c0 8.84 7.16 16 16 16h48c8.84 0 16-7.16 16-16V16c0-8.84-7.16-16-16-16z">
                </path>
            </svg>)
}