export const getElementPosition = (elem, offset = {x: 0, y: 0}) => {
    var position = elem.getBoundingClientRect()

    var docElem = document.documentElement
    var body = document.body

    var scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop
    var scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft

    var clientTop = docElem.clientTop || body.clientTop || 0
    var clientLeft = docElem.clientLeft || body.clientLeft || 0

    var top  = position.top +  scrollTop - clientTop + offset.x
    var left = position.left + scrollLeft - clientLeft + offset.y

    return {x: top, y: left}
}