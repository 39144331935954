import React, { useEffect, useState, useContext, useMemo } from 'react'
import Input from '../../../components/input/input';

import ProfileWanDhcp from './profile-wan-dhcp';
import ProfileWanPPPoE from './profile-wan-pppoe';
import ProfileWanStatic from './profile-wan-static';
import ProfileLan from './profile-lan';
import ProfileWlan from './profile-wireless';
import ProfileMesh from './profile-mesh';
import ProfileRemoteAccess from './profile-remote-access';
import ProfileRouterAccess from './profile-router-access';

import DefaultModals from '../../../components/modal/default-status-modals'
import { MainHeaderContext } from '../../../components/main-header/main-header-state';
import { BackendContext, backendStatus } from '../../../backend/backend'
import Form from '../../../components/form/form'
import FormSegment from '../../../components/form/form-segment'
import FormStepsIndicator from '../../../components/form/form-steps-indicator'
import common from '../../../components/form/validators/common'
import Checkbox from '../../../components/checkbox/checkbox';

import constants from './profile-contants'

import defaults from './profile-default-values'
import ProfileDhcp from './profile-dhcp';
import ProfileTR069 from './profile-tr069';
import { toInt, toString } from '../../../components/utils/iputils';
import { UserContext } from '../../user-context';
import { RemotizeType } from '../signup/signup';
import Modal, { ModalTypes } from '../../../components/modal/modal';
import Select from '../../../components/select/select'
let {
    WAN_MODE,
    EASY_MESH_TYPE,
    defaultWan,
    defaultLan,
    defaultWlan,
    defaultRadio,
    defaultBandSteering,
    defaultRemoteAccess,
    defaultRouterAccess,
    defaultDhcpServer,
    defaultFactoryDefault,
    defaultMesh,
    defaultSystem,
    defaultTr069
} = defaults



async function fetchProfiles(backend, setProfiles) {

    let result = await backend.retrieveFresh('profiles?idsonly=y')

    if (result.status !== backendStatus.SUCCESS)
        return

    setProfiles(() => {
        return result.content
    })

}

function getModeOptions() {

    return [
        { value: WAN_MODE.DHCP, text: 'DHCP' },
        { value: WAN_MODE.STATIC, text: 'Estático' },
        { value: WAN_MODE.PPPOE, text: 'PPPoE' }
    ]
}

export default function ProfileAdd({ history }) {
    const [isDefault, setIsDefault] = useState(false)
    const [autoApply, setAutoApply] = useState(false)
    const [profiles, setProfiles] = useState([])
    const [name, setName] = useState('')
    const [wan, setWan] = useState({ ...defaultWan })
    const [lan, setLan] = useState({ ...defaultLan })
    const [wlan, setWlan] = useState({ ...defaultWlan })
    const [radio, setRadio] = useState({ ...defaultRadio })
    const [bandSteering, setBandSteering] = useState({ ...defaultBandSteering })
    const [remoteAccess, setRemoteAccess] = useState({ ...defaultRemoteAccess })
    const [routerAccess, setRouterAccess] = useState({ ...defaultRouterAccess })
    const [dhcp, setDhcp] = useState({ ...defaultDhcpServer })
    const [mesh, setMesh] = useState({ ...defaultMesh })
    const [system, setSystem] = useState({ ...defaultSystem })
    const [tr069, setTr069] = useState({ ...defaultTr069 })
    const [factoryDefault, setFactoryDefault] = useState({ ...defaultFactoryDefault })

    const [externalError, setExternalError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [editedProfile] = useState(
        history.location.state ? history.location.state.profile : null
    )
    const [devicesCount,] = useState(history.location.state ? history.location.state.count : 0)
    const [confirmEdit, setConfirmEdit] = useState(false)

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const [user,] = useContext(UserContext)

    const [segments, setSegments] = useState({
        name: true,
        wan: false,
        lan: false,
        wifi: false,
        remote: false,
        router: false,
        dhcp: false,
        mesh: false,
        tr069: false
    })

    useEffect(() => {
        fetchProfiles(backend, setProfiles)
        if (editedProfile) {
            if (!editedProfile.routerAccess) {
                editedProfile.routerAccess = { username: 'admin', password: 'admin' }
            }

            setIsDefault(editedProfile.isDefault ? true : false)
            setAutoApply(false)// issue 439  will only be used when creating the profile

            setName(editedProfile.name)
            setWan(editedProfile.wan)
            setLan(editedProfile.lan)
            setWlan(editedProfile.wireless)
            setRemoteAccess(editedProfile.remoteWebAccess)
            setRouterAccess(editedProfile.routerAccess)
            setDhcp(editedProfile.dhcp)
            setMesh(editedProfile.mesh)
            setSystem(editedProfile.system)
            setTr069(editedProfile.tr069)
            setRadio(editedProfile.radio)
            setBandSteering(editedProfile.bandSteering)

            if (editedProfile.factoryDefault.pressedTime !== 0)
                setFactoryDefault(editedProfile.factoryDefault)

        }

        header.backRoute.set('/profiles/list')

        let segment = history.location.state ? history.location.state.segment : null
        if (segment) {
            for (let key of Object.keys(segments)) {
                segments[key] = segment === key
            }
            setSegments({ ...segments })
        }


        return () => {
            wan.pppoe.username = ''
            wan.pppoe.password = ''
            wan.mtu = constants.MAX_PPPOE_MTU

            setWan(prev => { return { ...prev, pppoe: wan.pppoe } })
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if (editedProfile) return

        setDhcp({ ...dhcp, dns1: lan.ip })

        let ip = toInt(lan.ip)
        let netInt = toInt(lan.netmask)
        let maxIpInt = (ip & netInt) + (~netInt)

        let newStart = toString(ip + 1)
        let newEnd = toString(maxIpInt - 1)

        setDhcp({ ...dhcp, dns1: lan.ip, rangeStart: newStart, rangeEnd: newEnd })

        // eslint-disable-next-line
    }, [lan])

    const isSegmentOnly = () => {
        return history.location.state && history.location.state.segment
    }

    const checkResetTime = async (reset) => {

        let value = parseInt(reset)

        if (value <= 0 || value > 3600) {
            return 'O tempo de reset deve estar entre 1 segundo e 3600 segundos'
        }

        return ''
    }

    const checkName = async (name) => {
        if ((editedProfile && editedProfile.name === name) || !segments.name) {
            return ''
        }

        for (let index = 0; index < profileOff.length; index++) {
            const element = profileOff[index];
            if (name === element.name) {
                return 'Este nome já existe'
            }
        }

        return ''
    }


    const confirmModal = () => {
        return <Modal id='confirm-edit-modal'
            type={ModalTypes.CONFIRM_WARNING}
            title='Confirme a edição'
            show={confirmEdit}
            content={
                <div>
                    Lembre-se, esta ação terá impacto sobre <b>{devicesCount}</b> dispositivo{devicesCount > 1 ? 's ' : ' '}
                    e não poderá ser revertida.
                    <br></br>
                    <br></br>
                    <b>Deseja prosseguir?</b>
                </div>
            }
            dismissText='Não, quero voltar'
            confirmText='Sim, estou ciente'
            onDismissClick={confirmContinue}
        ></Modal>
    }

    const confirmContinue = (confirm) => {

        setConfirmEdit(false)

        if (!confirm) {
            history.push("/profiles/list")
            return
        }

        send()
    }

    const submit = async () => {

        if (devicesCount > 0) {
            setConfirmEdit(true)
            return
        }

        send()
    }

    const send = async () => {

        if (wan.mode === WAN_MODE.DHCP || wan.mode === WAN_MODE.STATIC) {
            wan.pppoe.username = ''
            wan.pppoe.password = ''
            wan.pppoe.server = ''
            wan.pppoe.service = ''
            wan.pppoe.mtu = constants.MAX_PPPOE_MTU
        }

        if (wan.mode === WAN_MODE.DHCP) {
            wan.static.ip = ''
            wan.static.netmask = ''
            wan.static.gateway = ''
            wan.static.dns1 = ''
            wan.static.dns2 = ''
            wan.static.dns3 = ''
        }

        
        if (mesh.enabled && mesh?.type === EASY_MESH_TYPE.AGENT) {
            system.operationMode = 1
        }else{
            system.operationMode = 0
        }
        
        if (!wlan?.useCompatibilityMode) {
            wlan.useCompatibilityMode = true
        }
        if (wlan.enableDualBand !== bandSteering.enabled) {
             bandSteering.enabled = wlan.enableDualBand
        }
        setSaving(true)
        let action = editedProfile ? 'update' : 'create'
        await backend[action](editedProfile ? 'profiles/' + editedProfile.id : 'profiles', {
            isDefault: isDefault,
            autoApply: autoApply,
            name: name,
            wan: wan,
            lan: lan,
            wireless: wlan,
            radio: radio,
            bandSteering: bandSteering,
            remoteWebaccess: remoteAccess,
            routerAccess: routerAccess,
            dhcp: dhcp,
            mesh: mesh,
            factoryDefault: factoryDefault,
            system: system,
            tr069: tr069
        },
        )

        setSaving(false)
        setSuccess(true)
    }

    const continueFn = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
        history.push('/profiles/list')
    }

    const hideButton = () => {

        if (!history.location.state || !history.location.state.segment) {
            return !segments.router
        }


        return false
    }
    const PROFILESOFF = useMemo(() => {
        return profiles
    }, [profiles])

    const profileOff = PROFILESOFF;

    return <div id='profile-add-page'>

        <DefaultModals
            success={success}
            saving={saving}
            error={error}
            continueFn={continueFn}
        ></DefaultModals>

        {confirmModal()}

        <Form id='profile-form' onSubmit={submit} hideButton={hideButton()} disableButton={externalError}>
            <FormSegment
                segmentID={1}
                active={segments.name}
                nextLabel='InMesh'
                nextClicked={() => setSegments({ ...segments, name: false, mesh: true })}
                title='Geral'
                disableNavigation={isSegmentOnly()}
            >
                <Input id='profile-name'
                    label='Nome do perfil'
                    value={name}
                    placeholder='Novo perfil'
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    validators={[common.required, checkName, common.nonASCII]}
                ></Input>

                <Input id='reset-time'
                    label='Tempo de Reset (segundos)'
                    value={factoryDefault.pressedTime}
                    onChange={(e) => {
                        let value = e.target.value

                        if (isNaN(value)) return

                        if (e.target.value === '') {
                            value = 0
                        }

                        factoryDefault.pressedTime = parseInt(value)
                        setFactoryDefault({ ...factoryDefault })
                    }}
                    validators={[common.required, checkResetTime]}
                ></Input>

                {user.type === RemotizeType.ADMIN ? <Checkbox id='profile-default'
                    label='Usar como padrão'
                    value={isDefault}
                    toggleFn={() => setIsDefault(!isDefault)}
                >
                </Checkbox> : null}

                {user.type === RemotizeType.ADMIN ? <div className={`default-profile-info open`}>
                    Este padrão será associado automaticamente à todo novo dispositivo que você associar à sua conta ou que ainda
                    não possua um perfil associado.
                </div> : null}

                {/* <Checkbox id='profile-auto-apply'
                    label='Aplicar automaticamente'
                    value={autoApply}
                    toggleFn={() => setAutoApply(!autoApply)}
                >
                </Checkbox>
                <div className={`default-profile-info open`}>
                As configurações deste perfil de padrão de fábrica serão aplicadas automaticamente após a associação
                ao dispositivo e sempre que houver edição do perfil no menu Gerenciamento de configurações.
                </div> */}

            </FormSegment>
            <FormSegment
                segmentID={2}
                active={segments.mesh}
                previousLabel='Perfil'
                previousClicked={() => setSegments({ ...segments, name: true, mesh: false })}
                nextLabel={mesh?.enabled && mesh?.type === EASY_MESH_TYPE.AGENT ? 'Acesso ao roteador' : 'Internet'}
                nextClicked={mesh?.enabled && mesh?.type === EASY_MESH_TYPE.AGENT ?
                    () => setSegments({ ...segments, mesh: false, router: true }) :
                    () => setSegments({ ...segments, mesh: false, wan: true })}
                title='InMesh'
                disableNavigation={isSegmentOnly()}
            >
                {ProfileMesh({ mesh: mesh, setMesh: setMesh, wlan: wlan, setWlan: setWlan, radio: radio, setRadio: setRadio, editedProfile: editedProfile })}
            </FormSegment>


            <FormSegment
                segmentID={3}
                active={segments.wan}
                previousLabel='InMesh'
                previousClicked={() => setSegments({ ...segments, mesh: true, wan: false })}
                nextLabel='LAN'
                nextClicked={() => setSegments({ ...segments, lan: true, wan: false })}
                title='Internet'
                disableNavigation={isSegmentOnly()}
            >

                <Select id='wan-mode-selector'
                    label='Modo de operação'
                    value={wan.mode}
                    options={
                        getModeOptions()
                    }
                    onChange={(e) => {
                        wan.mode = Number(e.target.value)
                        setWan({ ...wan })
                    }}
                    clearErrors={true}
                ></Select>

                <Checkbox
                    label="Habilitar UPnP"
                    id={'enable-upnp'}
                    value={system.upnp}
                    toggleFn={() => {
                        setSystem({ ...system, upnp: !system.upnp })
                    }}
                ></Checkbox>
                {
                    wan.mode === WAN_MODE.DHCP ? ProfileWanDhcp({ wan: wan, setWan: setWan }) :

                        wan.mode === WAN_MODE.PPPOE ? ProfileWanPPPoE({ wan: wan, setWan: setWan }) :

                            wan.mode === WAN_MODE.STATIC ? ProfileWanStatic({ wan: wan, setWan: setWan, lan: lan }) : ''
                }

            </FormSegment>

            <FormSegment
                segmentID={4}
                active={segments.lan}
                previousLabel='WAN'
                previousClicked={() => setSegments({ ...segments, wan: true, lan: false })}
                nextLabel='Servidor DHCP'
                nextClicked={() => setSegments({ ...segments, dhcp: true, lan: false })}
                title='LAN'
                disableNavigation={isSegmentOnly()}
            >

                {ProfileLan({ lan: lan, setLan: setLan, dhcp: dhcp, setDhcp: setDhcp, wan: wan, active: segments.lan })}

            </FormSegment>
            <FormSegment
                segmentID={5}
                active={segments.dhcp}
                previousLabel='LAN'
                previousClicked={() => setSegments({ ...segments, lan: true, dhcp: false })}
                nextLabel='Wi-Fi'
                nextClicked={() => setSegments({ ...segments, wifi: true, dhcp: false })}
                title='Servidor DHCP'
                disableNavigation={isSegmentOnly()}
            >
                {ProfileDhcp({ dhcp: dhcp, setDhcp: setDhcp, lan: lan, setExternalError: setExternalError })}

            </FormSegment>

            <FormSegment
                segmentID={6}
                active={segments.wifi}
                previousLabel='Servidor DHCP'
                previousClicked={() => setSegments({ ...segments, dhcp: true, wifi: false })}
                nextLabel='Acesso remoto'
                nextClicked={() => setSegments({ ...segments, remote: true, wifi: false })}
                title='Wi-Fi'
                disableNavigation={isSegmentOnly()}
            >

                {ProfileWlan({ wlan: wlan, setWlan: setWlan, radio: radio, setRadio: setRadio, editedProfile: editedProfile, mesh: mesh })}

            </FormSegment>

            <FormSegment
                segmentID={7}
                active={segments.remote}
                previousLabel='Wi-Fi'
                previousClicked={() => setSegments({ ...segments, wifi: true, remote: false })}
                nextLabel='TR-069'
                nextClicked={() => setSegments({ ...segments, tr069: true, remote: false })}
                title='Acesso remoto'
                disableNavigation={isSegmentOnly()}
            >

                {ProfileRemoteAccess({ remoteAccess: remoteAccess, setRemoteAccess: setRemoteAccess })}

            </FormSegment>

            <FormSegment
                segmentID={8}
                active={segments.tr069}
                previousLabel='Acesso remoto'
                previousClicked={() => setSegments({ ...segments, remote: true, tr069: false })}
                nextLabel='Acesso ao roteador'
                nextClicked={() => setSegments({ ...segments, router: true, tr069: false })}
                title='TR-069'
                disableNavigation={isSegmentOnly()}
            >
                {ProfileTR069({ tr069, setTr069: setTr069, remoteAccess: remoteAccess })}
            </FormSegment>

            <FormSegment
                segmentID={9}
                active={segments.router}
                previousLabel={mesh?.enabled && mesh.type === 1 ? 'InMesh' : 'TR-069'}
                previousClicked={() => setSegments(mesh?.enabled && mesh.type === 1 ?
                    { ...segments, mesh: true, router: false } : { ...segments, tr069: true, router: false })}
                // nextLabel={mesh?.enabled && mesh.type === 1 ? '' : 'Servidor DHCP'}
                // nextClicked={() => setSegments({ ...segments, dhcp: true, router: false })}
                title='Acesso ao roteador'
                disableNavigation={isSegmentOnly()}
            >

                {ProfileRouterAccess({ routerAccess: routerAccess, setRouterAccess: setRouterAccess })}

            </FormSegment>


            {!isSegmentOnly() ? <FormStepsIndicator stepsStatus={segments}></FormStepsIndicator> : null}

        </Form>

    </div>
}