import React from 'react'

export default function CogIcon({size, color, usePng}){

    if(usePng) {
        let icon = require('./images/ic-config.png')
        return <img alt='' src={icon} width={size} height={size}></img>
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero" stroke={color}>
                    <g>
                        <path d="M28.363 14.225l-2.915-.795c-.034-.012-.097-.07-.121-.129l-.771-1.835c-.014-.031-.01-.115.004-.143l1.511-2.602c.18-.31.141-.733-.093-1.007-.016-.018-.39-.454-.722-.782-.33-.331-.774-.705-.79-.72-.264-.224-.718-.268-1.02-.098l-2.63 1.478c-.014.005-.106.023-.174-.008l-1.857-.765c-.033-.014-.093-.077-.102-.105l-.791-2.983c-.085-.303-.395-.731-.823-.731h-2.074c-.429 0-.74.424-.825.725l-.803 2.926c-.012.039-.074.115-.133.141l-1.86.77c-.012.006-.14.003-.146 0L8.6 6.076c-.3-.172-.752-.13-1.018.09-.019.016-.46.385-.788.715-.34.33-.717.77-.736.79-.227.271-.267.693-.091 1.004l1.495 2.6c.016.03.019.115-.005.173l-.78 1.84c-.014.03-.073.086-.108.098l-2.914.783c-.352.09-.632.43-.652.814L3 17.05c.016.358.292.7.64.796l2.915.795c.032.012.094.07.118.13l.774 1.84c.014.031.01.113-.004.14L5.93 23.354c-.179.31-.14.732.094 1.008.016.018.39.451.727.795.33.318.766.689.782.702.263.227.719.27 1.018.098l2.63-1.475c.018-.009.114-.025.18.004l1.86.77c.032.013.09.074.101.102l.786 2.944c.087.331.42.698.822.698h2.075c.399 0 .732-.363.825-.69l.807-2.91c.012-.034.07-.103.133-.13l1.857-.768c.013-.005.14-.002.148 0l2.622 1.494c.301.17.753.13 1.016-.09.019-.016.46-.383.803-.72.324-.326.702-.76.72-.78.233-.273.273-.696.096-1.005l-1.497-2.603c-.015-.032-.017-.117.008-.175l.775-1.837c.014-.033.077-.09.108-.1l2.925-.78c.346-.094.623-.431.646-.811L29 15.02c-.01-.358-.286-.7-.637-.796z" transform="translate(-631 -458) translate(631 458)"/>
                        <path d="M15.999 11.5c-1.196 0-2.323.464-3.173 1.308-.853.848-1.325 1.977-1.326 3.18-.003 1.203.463 2.336 1.313 3.192.854.852 1.988 1.32 3.192 1.32 1.198 0 2.324-.464 3.172-1.308.851-.85 1.32-1.98 1.323-3.182.003-1.203-.464-2.334-1.311-3.182-.852-.857-1.985-1.328-3.19-1.328z" transform="translate(-631 -458) translate(631 458)"/>
                    </g>
                </g>
            </g>
        </svg>
    )

}