import React from 'react'

export default function NoConnectionIcon({size, color}){
    return (
        <svg fill={color} height={size} viewBox="0 0 64 64" width={size} xmlns="http://www.w3.org/2000/svg">
            <path d="m59 2h-54a3 3 0 0 0 -3 3v54a3 3 0 0 0 3 3h54a3 3 0 0 0 3-3v-54a3 3 0 0 0 -3-3zm-54 2h54a1 1 0 0 1 1 1v5h-56v-5a1 1 0 0 1 1-1zm54 56h-54a1 1 0 0 1 -1-1v-47h56v47a1 1 0 0 1 -1 1z"/>
            <path d="m7 8h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z"/>
            <path d="m12 8h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z"/>
            <path d="m17 8h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z"/>
            <path d="m32 18a18 18 0 1 0 18 18 18.021 18.021 0 0 0 -18-18zm0 34a16 16 0 1 1 16-16 16.019 16.019 0 0 1 -16 16z"/>
            <path d="m39.07 48.31a1 1 0 0 1 -.707-.293l-6.363-6.363-6.363 6.363a1 1 0 0 1 -1.414 0l-4.24-4.24a1 1 0 0 1 0-1.414l6.363-6.363-6.363-6.363a1 1 0 0 1 0-1.414l4.24-4.24a1 1 0 0 1 1.414 0l6.363 6.363 6.363-6.363a1 1 0 0 1 1.414 0l4.24 4.24a1 1 0 0 1 0 1.414l-6.363 6.363 6.363 6.363a1 1 0 0 1 0 1.414l-4.24 4.24a1 1 0 0 1 -.707.293zm-7.07-9.07a1 1 0 0 1 .707.293l6.363 6.367 2.83-2.83-6.363-6.363a1 1 0 0 1 0-1.414l6.363-6.363-2.83-2.83-6.363 6.363a1 1 0 0 1 -1.414 0l-6.363-6.363-2.83 2.83 6.363 6.363a1 1 0 0 1 0 1.414l-6.363 6.363 2.83 2.83 6.363-6.363a1 1 0 0 1 .707-.297z"/>
        </svg>
    )
}