import React, { useEffect, useState, forwardRef } from 'react'
import Joyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride';
import HelpIcon from '../../../components/icons/wizard'
import './profiles.css'

const joyLocale = {back: 'Voltar', close: 'Fechar', last: 'Entendido', next: 'Próximo', skip: 'Pular'}

export default function ProfileTour(){
    const [dontShowAgain, setDontShowAgain] = useState(false)
    const [profileTour, setProfileTour] = useState(false)

    useEffect(() => {
        let profile_tour = localStorage.getItem('profile_tour')
        
        if((profile_tour === 'true' || profile_tour === null)){
            setProfileTour(true)
        }

    }, [])

    const DontShowAgain = () => {
        return( <div className='dont-show'>
                    <input 
                        type="checkbox"
                        checked={dontShowAgain}
                        onChange={(e) => {setDontShowAgain(!dontShowAgain)}}>
                    </input>
                    <label className='dont-show-label'>
                        Não mostrar este guia novamente
                    </label>
                </div>
        )
    }

    const steps = [
        {
            content: <div>
                        <p>Esta é a pagina para criação e alterações de perfis de padrão de fábrica.</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'center',
            target: 'body',
            title: 'Padrão de fábrica',
        },
        {
            content: <div>
                        <p>Para iniciar o processo de criação de um perfil, basta você clicar no botão <strong>Adicionar novo perfil</strong> e você será redirecionao à página de criação para preencher os dados necessários.</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'bottom',
            target: '.profile-add-button',
            title: 'Criação de novos perfis',
        },
        {
            content: <div>
                        <p>Aqui serão listados todos os perfis criados em sua conta, você poderá editar, remover ou visualizar informações de cada perfil.</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'right',
            target: '.profile-list',
            title: 'Listagem dos perfis',
        },
        {
            content: <div>
                        <p>Todas as informações relacionadas ao seu perfil serão apresentadas nesta coluna</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'left',
            target: '.profile-info-text',
            title: 'Informações de perfil',
        },
        {
            content: <div>
                        <p>Após a criação do perfil, para associá-lo a um dispositivo, basta navegar até a página de 
                            dispositivos, selecionar o dispositivo desejado e associar o novo perfil de padrão de 
                            fábrica.</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'center',
            target: 'body',
            title: 'Associação de perfil',
        }
    ]

    const handleTourCallback = (data) => {
        const { action, status, type } = data;

        if([ACTIONS.CLOSE].includes(action)){
            if(dontShowAgain){
                localStorage.setItem('profile_tour', false)
            }

            setProfileTour(false)
        }

        if([STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
            [EVENTS.TOUR_END].includes(type)) {

            if(dontShowAgain){
                localStorage.setItem('profile_tour', false)
            }

            setProfileTour(false)
        }
    };

    const handleShowTour = () => {
        setProfileTour(true)
        setDontShowAgain(false)

        localStorage.setItem('profile_tour', true)
    }

    const emptyBeacon = forwardRef((props, ref) => {
        return <div ref={ref}></div>;
    });

    return(
        <div>
            <div className="help-icon" onClick={handleShowTour}>
                <HelpIcon title={"Executar o guia de ajuda"}></HelpIcon>
            </div>

            {!profileTour ? null :
            <div>
                <Joyride
                    steps={steps}
                    callback={handleTourCallback}
                    continuous={true}
                    run={true}
                    styles={{
                        options: {
                            primaryColor: '#00a335',
                            zIndex: 10000,
                        },
                    }}
                    locale={joyLocale}
                    showSkipButton={true}
                    disableOverlayClose={true}
                    disableCloseOnEsc={true}
                    beaconComponent={emptyBeacon}
                />

            </div> }
        </div>
        
    )
}