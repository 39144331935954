import React from 'react'

import Input from '../../../components/input/input'
import common from '../../../components/form/validators/common'
import constants from './profile-contants'
import Checkbox from '../../../components/checkbox/checkbox';
import WanDNS from './profile-wan-dns'

function validatePPPoEMTU(value){


    if(value < constants.MIN_PPPOE_MTU || value > constants.MAX_PPPOE_MTU)
        return `O MTU deve estar entre ${constants.MIN_PPPOE_MTU} e ${constants.MAX_PPPOE_MTU}`
        
    return ''
}

export default function ProfileWanPPPoE({wan, setWan}) {

    return [

        <Input id='pppoe-username'
            name='pppoe.username'
            label='Usuário'
            value={wan.pppoe.username}
            onChange={(e) => {
                let value = e.target.value
                wan.pppoe.username = value
                setWan({...wan})
            }}
            validators={[common.nonASCII, common.required, {fn: common.size, params: {min: 1, max: 128}}]}
            key='ppp-1'
        ></Input>,

        <Input id='pppoe-password'
            name='pppoe.password'
            label='Senha'
            value={wan.pppoe.password}
            onChange={(e) => {
                let value = e.target.value
                wan.pppoe.password = value
                setWan({...wan})
            }}
            validators={[common.nonASCII, common.required, {fn: common.size, params: {min: 1, max: 128}}]}
            key='ppp-2'
        ></Input>,

        <Input id='pppoe-mtu'
            name='pppoe.mtu'
            label='MTU'
            value={wan.pppoe.mtu}
            onChange={(e) => {

                if(isNaN(e.target.value)) return

                let value = Number(e.target.value)

                if(value > constants.MAX_PPPOE_MTU + 100000) return

                wan.pppoe.mtu = value
                setWan({...wan})
            }}
            validators={[validatePPPoEMTU]}
            key='ppp-3'
        ></Input>,        
        
        <Checkbox name='wan-automatic-dns'
            label='Obter DNS automaticamente'
            value={wan?.dns_v4.automatic}
            toggleFn={() => {
                setWan({...wan, dns_v4: {...wan?.dns_v4, automatic: !wan?.dns_v4.automatic}})
            }}
        ></Checkbox>,
        <div>
            {WanDNS(wan, setWan)}
        </div>
        ]
}