import React, { useEffect } from 'react'
import Input from '../../../components/input/input';
import common from '../../../components/form/validators/common'
import network from '../../../components/form/validators/network'
import IPUtils, { toInt, toString } from '../../../components/utils/iputils';



export default function ProfileLan({lan, setLan, wan, dhcp, setDhcp, active}) {

    useEffect(() => {
        if(active) adjustDHCP()

        // eslint-disable-next-line
    }, [lan, active])


    function adjustDHCP() {

        let net = IPUtils.extractNetwork(lan.ip, lan.netmask)

        let netInt = toInt(net)
        let startInt = netInt + 2
        let start = toString(startInt)

        let netmaskInt = toInt(lan.netmask)
        let endInt = netInt | ~netmaskInt
        endInt -= 1
        let end = toString(endInt)

        dhcp.rangeStart = start
        dhcp.rangeEnd = end
        dhcp.dns1 = lan.ip

        setDhcp({...dhcp})

    }

    function handleIPInput(e){

        let value = e.target.value
        lan.ip = value
        setLan({...lan})
    }

    function handleNetmask(e){

        let value = e.target.value
        lan.netmask = value
        setLan({...lan})
    }

    function validateWithWan() {
        if(network.sameNetwork(lan.ip, {network: lan.netmask, ip: wan.static.ip}) === '')
            return 'Este endereço está em conflito com a WAN'

        return ''
    }

    return [

        <Input id='lan-ip'
            label='Endereço IP'
            value={lan.ip}
            name='ip'
            onChange={handleIPInput}
            validators={[common.required, network.ip4, validateWithWan]}
            key='lan-1'
        ></Input>,

        <Input id='lan-netmask'
            label='Máscara de sub-rede'
            value={lan.netmask}
            name='netmask'
            onChange={handleNetmask}
            validators={[common.required, network.netmask]}
            key='lan-2'
        ></Input>

    ]
}