import React, { useState } from 'react';
import SiteSurveyCard from '../site-survey-card/site-survey-card'
import { getElementPosition } from '../utils/element-position'
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, ChartLabel, LabelSeries, AreaSeries } from 'react-vis';
import './site-survey-line-chart.css'

const Frequencies_2_4Ghz = [2412, 2417, 2422, 2427, 2432, 2437, 2442, 2447, 2452, 2457, 2462, 2467, 2472]
const Frequencies_5Ghz = [5180, 5200, 5220, 5240, 5745, 5765, 5785, 5805, 5825]
const Frequencies_5Ghz_Points = [5140, 5150, 5160, 5170, 5180, 5190, 5200, 5210, 5220, 5230, 5240, 5250, 5260, 5270, 5280, 5290, 5300, 5685, 5695, 5705, 5715, 5725, 5735, 5745, 5755, 5765, 5775, 5785, 5795, 5805, 5815, 5825, 5835, 5845]

export const bandwidth = {
    _20MHz: 0,
    _40MHz: 1,
    _80MHz: 2,
    _160MHz: 3
}

export const extension = {
    UPPER: 0,
    LOWER: 1
}

export const bandwidthIntValue = [20, 40, 80, 160]

export const bandwidthString = [
    '20MHz','20/40MHz', '20/40/80MHz', '20/40/80/160MHz'
]

export function frequencyToChannel(freq, is5Ghz = false) {
    if(is5Ghz){
        /** In 5 GHz all channels are mapped to a index from 0..14 */
        var currentFreq = Frequencies_5Ghz_Points[freq]

        let index = Frequencies_5Ghz.indexOf(currentFreq)
        if(index < 0) {
            return (freq % 2) === 0 ? '' : '...'
        }

        let initial = Frequencies_5Ghz[0]
        return ((Frequencies_5Ghz[index] - initial) / 5) + 36
    }

    let initial = Frequencies_2_4Ghz[0]
    return ((freq - initial) / 5) + 1
}

export function channelFrequency(channel) {

    if(channel <= 13){
        let initial = Frequencies_2_4Ghz[0]
        return initial + (channel - 1) * 5
    }

    if(channel >= 36){
        let initial = Frequencies_5Ghz[0]
        return initial + (channel - 36) * 5
    }

    return 0
}

export function channelExtension(base) {
    let extension = []

    if(base >= 5){
        extension.push({value: 1, text: base - 4})
    }
    if(base <= 9){
        extension.push({value: 0, text: base + 4})
    }

    return extension
}

export default function SiteSurveyLineChart({ siteSurvey, is5Ghz }) {
    const [hint, setHint] = useState(null)

    const getExtensionChannel = (channel, extension) => {
        let extensionList = channelExtension(channel)

        for(let extensionAvailable of extensionList){
            if(extensionAvailable.value === extension){
                return extensionAvailable.text
            }
        }

        return channel
    }

    const get5GhzIndexArray = () => {
        var list = [];
        for (var i = 0; i <= Frequencies_5Ghz_Points.length-1; i++) {
            list.push(i);
        }
        return list
    }

    const closestIndex = (value, array) => {
        var closest = array.reduce(function(prev, curr) {
            return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        });

        return array.indexOf(closest)
    }

    const getLineSeries = (ssid) => {
        let freq = channelFrequency(ssid.channel)

        var startX = 0
        var middleX = 0
        var endX = 0

        if(ssid.bandwidth === bandwidth._40MHz) {
            let extensionChannel = getExtensionChannel(ssid.channel, ssid.extension)

            middleX = (freq + channelFrequency(extensionChannel))/2

            if(ssid.extension === extension.UPPER) {
                startX = freq - 10
                endX = channelFrequency(extensionChannel) + 10
            } else {
                startX = channelFrequency(extensionChannel) - 10
                endX = freq + 10
            }
        } else {
            startX = freq - (bandwidthIntValue[ssid.bandwidth] / 2)
            middleX = freq
            endX = freq + (bandwidthIntValue[ssid.bandwidth] / 2)
        }

        if(is5Ghz){
            /** In 5 GHz all channels are mapped to a index from 0..14 */
            startX = closestIndex(startX, Frequencies_5Ghz_Points)
            middleX = closestIndex(middleX, Frequencies_5Ghz_Points)
            endX = closestIndex(endX, Frequencies_5Ghz_Points)
        }

        return [
            {x: startX, y: -100},
            {x: middleX, y: ssid.signal_level},
            {x: endX, y: -100},
        ]
    }

    const getSiteSurveyDataChart = () => {
        return siteSurvey.filter((ssid) => {
            return (ssid.channel > 13) === is5Ghz
        }).map((ssid, key) => {
            let freq = channelFrequency(ssid.channel)

            if (ssid.bandwidth === bandwidth._40MHz) {
                freq = (freq + channelFrequency(getExtensionChannel(ssid.channel, ssid.extension))) / 2
            }

            if(is5Ghz){
                /** In 5 GHz all channels are mapped to a index from 0..14 */
                freq = closestIndex(freq, Frequencies_5Ghz_Points)
            }

            return [<LineSeries
                        data={getLineSeries(ssid)}
                        color={ssid.color}
                        opacity={0.3}
                        curve={'curveMonotoneX'}
                        strokeWidth={3}
                    />,
                    <AreaSeries 
                        data={getLineSeries(ssid)}
                        color={ssid.color}
                        opacity={0.3}
                        curve={'curveMonotoneX'}
                        onSeriesMouseOut={(event)=>{
                            setHint(null)
                        }}
                        onSeriesMouseOver={(event)=>{
                            if(hint !== null && hint.value.mac === ssid.mac) return
                            const hintHeight = 150
                            const labelHeight = 12
                            let offset = {x: -hintHeight - labelHeight, y: 0}
                            setHint({...getElementPosition(event.event.target, offset), ...{value: ssid}})
                        }}/>,
                    <LabelSeries
                        data={[{x: freq, y: ssid.signal_level, label: ssid.ssid}]}
                        labelAnchorX={'middle'}
                        labelAnchorY={'auto'}
                        style={{fontSize: 12, fontWeight: 'bold'}}
                    />]
        })
    }

    return <React.Fragment>
        {hint ? <div className='ss-hint' style={{top: `${hint.x}px`, left: `${hint.y}px`}}>
            <SiteSurveyCard
                Id={`survey-chart-hint`}
                Ssid={hint.value.ssid}
                Channel={hint.value.channel}
                Mac={hint.value.mac}
                Cipher={hint.value.cipher}
                Protocol={hint.value.op_mode}
                SignalLevel={hint.value.signal_level}
                key={'hint'}
            ></SiteSurveyCard>
        </div> : null}
        <div className='site-survey-chart'>
            <XYPlot
                width={1000}
                height={500}
                margin={{left: 150, right: 80, top: 10, bottom: 80}}>
                <XAxis tickSize={10} tickFormat={v => `${frequencyToChannel(v, is5Ghz)}`} tickValues={is5Ghz ? get5GhzIndexArray() : Frequencies_2_4Ghz} />
                <YAxis />
                <HorizontalGridLines />
                {getSiteSurveyDataChart(siteSurvey, is5Ghz)}
                <ChartLabel
                    text={"Canais"}
                    includeMargin={false}
                    xPercent={0.5}
                    yPercent={1.18}
                    style={{ textAnchor: 'middle', fontWeight: 'bold' }}
                />
                <ChartLabel
                    text={"Nível de sinal (dBm)"}
                    includeMargin={false}
                    xPercent={-0.058}
                    yPercent={0.5}
                    style={{ transform: 'rotate(-90)',textAnchor: 'middle', fontWeight: 'bold' }}
                />
            </XYPlot>
        </div>
    </React.Fragment>
}