import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../../../../components/loading/loading'
import { BackendContext, backendStatus } from '../../../../backend/backend'
import { MainHeaderContext } from '../../../../components/main-header/main-header-state'

import DeviceFactory from '../../../apis/device-factory'

import { canBeManaged } from '../devices-page'

import './manage.css'

export const DEVICE_STATUS_OFFLINE = 1

export function  getTraffic(value, unitBytes, time) {

    const BYTE_SIZE = 8
    const MEGABIT_SIZE = 1000000

    if(time === 0) return 0

    return ((value * unitBytes * BYTE_SIZE) / time) / MEGABIT_SIZE
}

export const DeviceContext = React.createContext(null)

export default function DeviceManagePage() {


    const [deviceInfo, setDeviceInfo] = useState(null)

    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)
    const params = useParams()

    const deviceid = params.id

    const fetchDeviceInfo = async() => {

        let result = await backend.retrieveFresh(`devices/${deviceid}`)

        if(result.status === backendStatus.SUCCESS)
            setDeviceInfo(result.content[0])
    }

    useEffect(() => {

        fetchDeviceInfo()

        let deviceInterval = setInterval(fetchDeviceInfo, 6000)

        return () => clearInterval(deviceInterval)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!deviceInfo) return

        header.title.set(`${deviceInfo.model} - ${deviceInfo.deviceid}`)

        // eslint-disable-next-line
    }, [deviceInfo])

    if(!deviceInfo || !canBeManaged(deviceInfo))
        return <Loading show={true}></Loading>

    let device = DeviceFactory.getDevice(deviceInfo, backend)

    return (
        <div id="device-management-page">

            {!DeviceFactory.isTR069(deviceInfo.model) && deviceInfo.status === DEVICE_STATUS_OFFLINE ?

            <div className='offline-info'>
                <Loading show={true}></Loading>
                <span>O dispositivo está offline. Aguardando o retorno.</span>
            </div>

            :

            <DeviceContext.Provider value={device}>

                {device.getManagementPage()}

            </DeviceContext.Provider>}

        </div>
    )
}