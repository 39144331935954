import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import { BackendContext, backendStatus } from '../../../backend/backend';
import { useToasts } from 'react-toast-notifications'
import DiagramRemotize from '../../components/diagram';
import Button from '../../../components/button/button';
import List from '../../../components/list/list'
import Input from '../../../components/input/input'
import Modal, { ModalTypes } from '../../../components/modal/modal'
import HelpIcon from '../../../components/icons/wizard'
import DeviceTour from '../devices/device-tour';

import './associate-device.css'
import isElectron from '../../server/is-electron';
import { UserContext } from '../../user-context';
import Loading from '../../../components/loading/loading';

const FIND_TIMEOUT = 15
const REAPPLY_ACTION = 4

const helpContent = {

    "Aguardando contato": "Este dispositivo ainda não conclui sua conexão com o remotize. Assim que ele contactar a plataforma com sucesso, o processo de associação será concluído.",

    "Dispositivo desconhecido": "Este erro ocorre quando seu dispositivo ainda não conseguiu realizar contato com a plataforma Remotize. Verifique a conexão do seu dispositivo com a internet.",

    "Dispositivo offline": "Este erro ocorre quando seu dispositivo não está mais se comunicando com a plataforma Remotize. Verifique a conexão com a internet do seu dispositivo.",

    "Credenciais inválidas": "Este problema ocorre quando seu aplicativo de associação não consegue negociar suas credenciais com o Remotize. Contate o suporte Intelbras.",

    "Dispositivo já associado": "Este erro ocorre quando seu dispositivo já está associado a outra conta Remotize.",

    "Fora do padrão": "O dispositivo parece já ter sido configurado. Para associar dispositivos fora do padrão de fábrica Intelbras, utilize a associação manual na página web."
}

export default function AssociateDevice(){

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const [, setUsername] = useState("")
    const [modalTitle, setModalTitle] = useState("")
    const [modalContent, setModalContent] = useState("")
    const [modalShow, setModalShow] = useState(false)
    const [modalTokenExpired, setModalTokenExpired] = useState(false)
    const [modalType, setModalType] = useState(ModalTypes.SUCCESS)
    const [buttonLoading, setbuttonLoading] = useState(false)
    const [devices, setDevices] = useState([])
    const [filter, setFilter] = useState("")
    const { addToast } = useToasts()
    const history = useHistory()

    const [user,] = useContext(UserContext)

    const getColumns = () => {

        let columns = [
            {header:'Dispositivo'},
            {header:'Endereço IP'},
            {header: 'Status'},
            {header: 'Observação'}]

        return columns
    }

    const deviceFilter = (device) => {

        const lowerFilter = filter.toLowerCase()

        return device.deviceid.toLowerCase().indexOf(lowerFilter) >= 0 ||
           device.ip_addr.toLowerCase().indexOf(lowerFilter) >= 0 ||
           device.error.toLowerCase().indexOf(lowerFilter) >= 0 ||
           (device.status ? 'Associado' : 'Não Associado').toLowerCase().indexOf(lowerFilter) >= 0
    }

    const getDeviceHelpContent = (error) => {
        if(helpContent[error] !== undefined) {
            return helpContent[error]
        }
        
        return "Erro desconhecido, contate o suporte Intelbras."
    }

    const deviceStatusHelp = (status, error) => {
        setModalTitle(error)
        setModalContent(getDeviceHelpContent(error))
        setModalShow(true)

        if(status){
            setModalType(ModalTypes.SUCCESS)
        } else {
            setModalType(ModalTypes.ERROR)
        }
    }

    const dismissDeviceStatusHelp = () => {
        setModalShow(false)
    }

    const dismissTokenExpiredModal = () => {
        setModalTokenExpired(false)
        history.push('/glogin')
    }

    const onUnauthorized = () => {
        console.log('Checking auth token')
        var current_time = new Date().getTime()
        if(current_time > localStorage.getItem('token_expires_at')) {
            console.log("Token expired need refresh!")
            setModalTokenExpired(true)
        }
    }

        const getDeviceProfile = async(deviceid) => {
        let result = await backend.retrieveFresh(`devices/${deviceid}/profile`)
        let resultcontent = result.content
        console.log(" getDeviceProfile resultcontent: "+resultcontent)
            if(resultcontent.name !== "" ){
             return resultcontent
            }
  
            return false
 
    }
    
    const updateDeviceProfile = async(deviceid) => {
        let profileInfo = await getDeviceProfile(deviceid)
        if(profileInfo === false){
            return profileInfo
        }
            profileInfo.status = REAPPLY_ACTION
        let profileApply = await backend.create(`devices/${deviceid}/profile`, profileInfo)
        
        let notify = profileApply.status === backendStatus.SUCCES

            return notify
    }

    const getDevicesReaply = async (data) => {
        for(let deviceid of data.devices) {
            
            await  updateDeviceProfile(deviceid)
        }

        return true
    }

    const sendAssociateRequest = async(data) => {
        backend.setOnUnauthorized(onUnauthorized)
        let result = await backend.create('devices/associate_request', data)
        let response = result.content

        if(result.status !== backendStatus.SUCCESS){

            if(result.status === backendStatus.UNAUTHORIZED)
                return

            setbuttonLoading(false)
            addToast('Falha ao se comunicar com o servidor.', { 
                appearance: 'error',
                autoDismiss: true,
                pauseOnHover: true, }
            )
            if(isElectron()){
                const { ipcRenderer } = window.require('electron');
                ipcRenderer.send('online-status-changed', false)
            }
            return
        }
        
        response.success.forEach(device => {
            var device_info = data.devices_info.filter(deviceinfo => {
                return deviceinfo.mac === device.deviceid
            })
            device.ip_addr = device_info[0].ip_addr
            setDevices(oldArray => [...oldArray, device])
        })

        response.error.forEach(device => {
            var device_info = data.devices_info.filter(deviceinfo => {
                return deviceinfo.mac === device.deviceid
            })
            device.ip_addr = device_info[0].ip_addr
            setDevices(oldArray => [...oldArray, device])
        })

        /**
         * Enviar mensagem mqtt para atualizar a tela na view do remotize
         */

    }

    const findDeviceTimeout = async() => {

        setbuttonLoading(false)

        addToast('Nenhum dispositivo foi encontrado em sua rede.', { 
            appearance: 'error',
            autoDismiss: true,
            pauseOnHover: true, }
        )
    }

    const findDevices = async() => {
        const { ipcRenderer } = window.require('electron');

        let data = {
            isp: user.ispid
        }

        let findTimeout = setTimeout(findDeviceTimeout, FIND_TIMEOUT * 1000)

        setbuttonLoading(true)
        setDevices([])

        ipcRenderer.send('devices-request', data)

        ipcRenderer.once('devices-response', async(event, arg) => {

            clearTimeout(findTimeout)

            if(arg.devices.length > 0) {
                addToast(arg.devices.length > 1 ? 'Foram encontrados ' + arg.devices.length + ' dispositivos em sua rede.' : 
                'Foi encontrado ' + arg.devices.length + ' dispositivo em sua rede.', { 
                    appearance: 'info',
                    autoDismiss: true,
                    pauseOnHover: true, }
                )
                let resultDevicesReaply =  await getDevicesReaply({devices: arg.devices})
                if(resultDevicesReaply){
                    await sendAssociateRequest({devices: arg.devices, devices_info: arg.devices_info, password: arg.password})
                }
               
            } else {
                addToast('Nenhum dispositivo foi encontrado em sua rede.', { 
                    appearance: 'error',
                    autoDismiss: true,
                    pauseOnHover: true, }
                )
            }

            setbuttonLoading(false)
            
        });
    }

    const getHelpError = (status, error) => {
        return(
            <div className='clickable helper' onClick={ () => { deviceStatusHelp(status, error)}}>
                <span>{error}</span>
                <div className='helpicon'>
                    <HelpIcon title={"Ajuda"}></HelpIcon>
                </div>
            </div>
        )
    }

    useEffect(() => {

        header.hidden.set(false)
        header.navigation.set(false)
        header.title.set('Associação de Dispositivos')

        setUsername(localStorage.getItem('username'))
        var token = localStorage.getItem('token')
        if(token !== null){
            backend.setAuthInfo({token: token})
        }

        let tokenInterval = setInterval(onUnauthorized, 30000)

        return () => { clearInterval(tokenInterval) }

        // eslint-disable-next-line 
    }, [])

    if(!user.ispid)
        return <Loading show={true}></Loading>

    return <div id='associate-device-page' className='page'>

        <div className='container scroll-area'>
            
            {<div className="discovery">
                <Button 
                    title={'Procurar dispositivos em sua rede'}
                    id='btn-discovery'
                    text={'Procurar dispositivos'}
                    loading={buttonLoading ? 1 : 0}
                    onClick={findDevices}
                ></Button> 
            </div>}
            
            <div className='device-filter'>

                <Input
                    placeholder='Filtro'
                    onChange={(e) => {
                        setFilter(e.target.value)
                    }}
                ></Input>

            </div>

            <Modal
                title={'Sessão Expirada'}
                content={'Sua sessão expirou, efetue o login novamente!'}
                dismissText='Entendi'
                onDismissClick={dismissTokenExpiredModal}
                show={modalTokenExpired}
                type={ModalTypes.INFO}
            ></Modal>

            <Modal
                title={modalTitle}
                content={modalContent}
                dismissText='Entendi'
                onDismissClick={dismissDeviceStatusHelp}
                show={modalShow}
                type={modalType}
            ></Modal>

            {devices.length > 0 ? <List
                columns={getColumns()}
                lines={devices
                    .filter(deviceFilter)
                    .map(device => {
                        let values = [device.deviceid, device.ip_addr]
                        values.push(device.status ? 'Associado' : 'Não Associado')
                        values.push(device.error ?  getHelpError(device.status, device.error) : '')

                        return values
                    })}
            ></List> : null}

            {devices.length === 0 ? <DiagramRemotize
                infoText="Para associar dispositivos à sua conta você precisa conectar os equipamentos de rede conforme o
                diagrama abaixo. Os roteadores deverão estar conectados via porta INTERNET a um switch com link DHCP e acesso à
                internet, para que possam se comunicar com o servidor Remotize."
            ></DiagramRemotize> : null}

            <DeviceTour/>
        </div>
</div>

}
