import React from 'react'
//import { backendStatus } from '../../../../../../backend/backend'
import Input from '../../../../../../components/input/input'
import Checkbox from '../../../../../../components/checkbox/checkbox'
import network from '../../../../../../components/form/validators/network';
import common from '../../../../../../components/form/validators/common';

export default function SipAccount({
    fxsSip,
    sipNat,
    sipServer,
    setFxsSip,
    setSipNat,
    setSipServer,
    setSipGroup,
    sipGroup,
    modelFiber

}){

    return (


    <div className='fxs-sip'>


    <div className='subtitle'>Configurações Da Conta SIP </div>

        <React.Fragment>
  <Checkbox
    id="ProxySip"
    label="Habilitar Conta"
    value={
        modelFiber
            ? sipGroup.voip_proxy_server_register.proxy_enable
            : sipGroup.voip_proxy_account.default_proxy
    }
    checked={
        modelFiber
            ? sipGroup.voip_proxy_server_register.proxy_enable
            : sipGroup.voip_proxy_account.default_proxy
    }
    toggleFn={(e) => {
        if (modelFiber) {
            sipGroup.voip_proxy_account.default_proxy= !sipGroup.voip_proxy_server_register.proxy_enable;
            sipGroup.voip_proxy_server_register.proxy_enable= !sipGroup.voip_proxy_server_register.proxy_enable;
            setSipGroup({...sipGroup, enabled: e.target.value})

        } else {
            const newValue = !sipGroup.voip_proxy_account.default_proxy;
            setSipGroup({
                ...sipGroup,
                voip_proxy_account: {
                    ...sipGroup.voip_proxy_account,
                    default_proxy: newValue,
                },
            });
        }
    }}
/>




     <Input id='NumberSip' label='Número'
            value={fxsSip.number}
            name="ip2"
            //disabled={true}
            onChange={(event) => {
                    let value = event.target.value



                    sipGroup.voip_proxy_account.number = value
                    setSipGroup({...sipGroup})
            }}
            validators={[common.required]}
    ></Input>

     <Input id='LoginSip' label='Login'
            value={fxsSip.login_id}
            name="ip3"
            //disabled={true}
            onChange={(event) => {
                    let value = event.target.value


                    sipGroup.voip_proxy_account.login_id = value
                    setSipGroup({...sipGroup})
            }}
            validators={[]}
    ></Input>
     <Input id='PasswordSip' label='Senha'
            value={fxsSip.password}
            name="ip4"
            //disabled={true}
            onChange={(event) => {
                    let value = event.target.value

                    sipGroup.voip_proxy_account.password = value
                    setSipGroup({...sipGroup})
            }}
            validators={[common.required]}
    ></Input>


     <Input id='RegisterAddrSip' label='Endereço servidor SIP'
            value={sipServer.proxy_addr}
            name="ip5"
            //disabled={true}
              onChange={(event) => {
                    let value = event.target.value

 

                    sipGroup.voip_proxy_server_register.proxy_addr = value
                    setSipGroup({...sipGroup})
            }}
            validators={[common.required]}
    ></Input>

     <Input id='RegisterPortSip' label='Porta servidor SIP'
            value={sipServer.proxy_port}
            name="ip6"
            //disabled={true}
              onChange={(event) => {
                    let value = event.target.value

                    if(isNaN(value)) return

                    sipGroup.voip_proxy_server_register.proxy_port = value
                    setSipGroup({...sipGroup})
            }}
            validators={[common.required, network.port]}
    ></Input>
     <Input id='DomainSip' label='Domínio SIP'
            value={sipServer.sip_domain}
            name="ip7"
            //disabled={true}
              onChange={(event) => {
                    let value = event.target.value



                    sipGroup.voip_proxy_server_register.sip_domain = value
                    setSipGroup({...sipGroup})
            }}
           validators={[common.required, network.hostname]}
    ></Input>
    <Input id='RegisterExpSip' label='Tempo de registro (s)'
            value={sipServer.reg_expire}
            name="ip8"
            //disabled={true}
              onChange={(event) => {
                    let value = event.target.value

                    if(isNaN(value)) return

                    sipGroup.voip_proxy_server_register.reg_expire = value
                    setSipGroup({...sipGroup})
            }}
            validators={[common.required]}
    ></Input>

    <Input id='ProxyOutboundAddrSip' label='Endereço Outbound Proxy'
            value={sipNat.outbound_proxy_addr}
            //disabled={true}
            name="ip10"
              onChange={(event) => {
                    let value = event.target.value

                    

                    sipGroup.voip_proxy_nat_transv_server.outbound_proxy_addr = value
                    setSipGroup({...sipGroup})
            }}
            validators={[]}
    ></Input>
    <Input id='ProxyOutboundPortSip' label='Porta Outbound Proxy'
            value={sipNat.outbound_proxy_port}
            name="ip11"
            //disabled={true}
              onChange={(event) => {
                    let value = event.target.value

                    if(isNaN(value)) return

                     sipGroup.voip_proxy_nat_transv_server.outbound_proxy_port = value
                    setSipGroup({...sipGroup})
            }}
            validators={[]}
    ></Input>
</React.Fragment>



    </div>
    )
}   