import React from 'react'
import Input from '../../../components/input/input';
import common from '../../../components/form/validators/common'
import network from '../../../components/form/validators/network'


export default function WanDNS( wan, setWan) {
    const handleDns = (event,refDns) => {
        const newWan = {...wan};
        newWan.dns_v4[refDns] = event.target.value;
        setWan(newWan);
    }
    
    const validateSameDNS = (value, {dnsIndex}) => {

        if ((wan.dns_v4.automatic && wan.mode !== 1) || value === '') {
            return ''
        }
        if (dnsIndex === 1 && (value === wan.dns_v4.dns2 || value === wan.dns_v4.dns3)) {
            return 'Os endereços DNS não podem ser repetidos'
        }
    
        if (dnsIndex === 2 && (value === wan.dns_v4.dns1 || value === wan.dns_v4.dns3)) {
            return 'Os endereços DNS não podem ser repetidos'
        } 
        if( dnsIndex === 3 && (value === wan.dns_v4.dns1 || value === wan.dns_v4.dns2)) {
            return 'Os endereços DNS não podem ser repetidos'
        }
        return ''
    }


    return (
        wan.dns_v4.automatic ? <React.Fragment></React.Fragment> :
        <React.Fragment>
            <Input id='wan-dns1'
                name='dns1'
                label='DNS 1'
                value={wan?.dns_v4.dns1}
                onChange={(e) => handleDns(e,`dns1`)}
        
                validators={[
                    common.required,
                    network.ip4,
                    {fn:validateSameDNS, params: {dnsIndex: 1}}
                ]}
            ></Input>
            <Input id='wan-dns2'
                name='dns2'
                label='DNS 2'
                value={wan?.dns_v4.dns2}
                onChange={(e) => handleDns(e,`dns2`)}

                validators={[
                    network.ip4,
                    {fn:validateSameDNS, params: {dnsIndex: 2}}
                ]}
            ></Input>
            <Input id='wan-dns3'
                name='dns3'
                label='DNS 3'
                value={wan?.dns_v4.dns3}
                onChange={(e) => handleDns(e,`dns3`)}

                validators={[
                    network.ip4,
                    {fn:validateSameDNS, params: {dnsIndex: 3}}
                ]}
            ></Input>

        </React.Fragment>
    )
}