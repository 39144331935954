export const WanOpMode = {
    DHCP: 0,
    STATIC: 1,
    PPPOE_MODE: 2,
    BRIDGE: 3,
    BRIDGE_PPPOE: 4
}

export const DefaultWan = {
    id: "",
    interfaceID: "new",
    mode: 0,
    gateway: "",
    gateway_v6: "",
    dns_v4:{
        automatic: true,
        dns1: "",
        dns2: "",
        dns3: ""
    },
    dns_v6: {
        automatic: true,
        dns1: "",
        dns2: "",
        dns3: ""
    },
    clonedMac: "",
    is_default: false
}


export const DefaulLanList = {
    lan_list: {
        enabled: true, 
        lan_id: ""
    },

}

//var lan_list = [];
export const DefaultBindLan = {
    lan_list : [{
        "enabled":true,
        "lan_id":"LAN_1"
        },
        {
            "enabled":true,
            "lan_id":"LAN_2"
        }, 
        {
            "enabled":true,
            "lan_id":"WLAN_0"
        }, 
        {
            "enabled":true,
            "lan_id":"WLAN_1"
        } 
        ]
}

export const DefaultBindLanAX = {
    lan_list : [{
        "enabled":true,
        "lan_id":"LAN_1"
        },
        {
            "enabled":true,
            "lan_id":"LAN_2"
        }, 
        {
            "enabled":true,
            "lan_id":"LAN_3"
        }, 
        {
            "enabled":true,
            "lan_id":"LAN_4"
        }, 
        {
            "enabled":true,
            "lan_id":"WLAN_0"
        }, 
        {
            "enabled":true,
            "lan_id":"WLAN_1"
        } 
        ]
}

export const DefaultBindLanSR = {
    lan_list : [{
        "enabled":true,
        "lan_id":"LAN_1"
        },
        {
            "enabled":true,
            "lan_id":"LAN_2"
        }, 
        {
            "enabled":true,
            "lan_id":"LAN_3"
        }, 
        {
            "enabled":true,
            "lan_id":"WLAN_0"
        }, 
        {
            "enabled":true,
            "lan_id":"WLAN_1"
        } 
        ]
}

export const DefaultBindLan5F = {
    lan_list : [{
        "enabled":true,
        "lan_id":"LAN_1"
        },
        {
            "enabled":true,
            "lan_id":"LAN_2"
        }, 
        {
            "enabled":true,
            "lan_id":"LAN_3"
        }, 
        {
            "enabled":true,
            "lan_id":"LAN_4"
        },         
        {
            "enabled":true,
            "lan_id":"WLAN_0"
        }, 
        {
            "enabled":true,
            "lan_id":"WLAN_1"
        } 
        ]
}


export const DefaultSipServer = {
    proxy_enable: false,
    proxy_addr: "",
    proxy_port: "",
    reg_expire: "",
    sip_domain: "",
    sip_subscribe: "",


}
export const DefaultSipNat = {
    proxy_enable: false,
    outbound_proxy_addr: "",
    outbound_proxy_port: "",


}
export const DefaultSipAccount = {
    
    default_proxy: false,
    display_name: "",
    login_id: "",
    number: "",
    password: "",


}

export const DefaultIpv6Prefix = {
    prefix: "",
    prefix_size: "",
    adv_valid_lifetime: "",
    adv_preferred_lifetime: "",
    adv_on_link: false,
    adv_autonomous: false,
    rdnss1: "",
    rdnss2: "",
}