import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BackendContext, backendStatus } from '../../../backend/backend'
import ClockIcon from '../../../components/icons/clock-icon'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import { UserContext } from '../../user-context'
import { RemotizeType } from '../signup/signup'

import './blocked.css'

export default function BlockedAccount() {

    const header = useContext(MainHeaderContext)
    const [user,] = useContext(UserContext)
    const [ispUsers, setIspUsers] = useState([])
    const history = useHistory()
    const backend = useContext(BackendContext)


    useEffect(() => {

        header.title.set('Complete seu cadastro')
        header.navigation.set(false)
        header.notifications.set(false)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {


        if(!user || user.type !== RemotizeType.BLOCKED) return

        fetchIspUsers()

        if(user.type !== RemotizeType.BLOCKED){
            history.push('/devices')
            return
        }

        // eslint-disable-next-line
    }, [user])

    const fetchIspUsers = async() => {

        console.log('fetching')

        let result = await backend.retrieve(`isp/${user.ispid}/users`)

        if(result.status === backendStatus.SUCCESS){
            setIspUsers([...result.content])
        }
    }

    return <div id='blocked-account-page' className='page' style={{padding: '80px'}}>

        <ClockIcon size='70px'></ClockIcon>

        <br></br>
        <br></br>

        A sua requisição foi enviada.
        <br></br>
        <br></br>
        A liberação do seu acesso deve ser realizada por um administrador do seu provedor através do menu <b>Gerenciamento de contas</b>.
        <br></br>
        <br></br>
        <b>Administradores</b>
        <br></br>
        <br></br>

        {
            ispUsers.map((user) => {
                if(user.type !== RemotizeType.ADMIN)
                    return null
                return <span key={user.email}>{`${user.name} (${user.email})`}</span>
            })
        }

    </div>
}