import React, { useContext, useEffect } from 'react'

import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import { useHistory } from 'react-router-dom'

import RemotizeLogo from '../../../images/remotize-green.png'
import GoogleLogo from '../../../images/g-logo.png'

import './login.css'
import { MenuContext } from '../../../components/menu/menu-state'

import firebase from 'firebase/app'
import 'firebase/auth'

import HeaderBig from '../../../components/headerbig/headerbig'
import { BackendContext } from '../../../backend/backend'

const LoginOAuthPage = () => {

    let header = useContext(MainHeaderContext)

    let history = useHistory()
    let menu = useContext(MenuContext)
    let backend = useContext(BackendContext)

    useEffect(() => {

        header.hidden.set(true)
        menu.open.set(false)
        menu.fixed.set(false)

        if(firebase.apps.length === 0){
            var firebaseConfig = {
                apiKey: "AIzaSyAicVjsBJ9rCeZ2iJzNxxMbJnfz8NqamSA",
                authDomain: "meu-wi-fi-intelbras.firebaseapp.com",
                databaseURL: "https://meu-wi-fi-intelbras.firebaseio.com",
                projectId: "meu-wi-fi-intelbras",
                storageBucket: "meu-wi-fi-intelbras.appspot.com",
                messagingSenderId: "962814661316",
                appId: "1:962814661316:web:d17576752a660335aa6f91",
                measurementId: "G-LSNN1B2B7R"
            };
            firebase.initializeApp(firebaseConfig)

        }
        handleAuth()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        console.log("auth", backend.auth)

        if(!backend.auth) return

        fetchTokens()
        // eslint-disable-next-line
    }, [backend.auth])

    const handleAuth = async() => {

        firebase.auth().onAuthStateChanged(async(user) => {

            if(!user) return

            let token = await user.getIdToken()

            backend.setAuthInfo({token: token})

        })
    }

    const fetchTokens = async() => {

        let result = await backend.create("oauth/session")

        //TODO: check result
        let token = result.content
        const urlParams = new URLSearchParams(window.location.search)
        // State sent by Alexa which we have to return.
        const state = urlParams.get("state");
        // Redirect uri sent by Alexa.
        const redirect_uri = urlParams.get("redirect_uri");
        // Combine all the uri elements.
        let url = redirect_uri +"?constaccess_type=offline&state="+ state + "&code=" + token;
        console.log('url',url)
        // Redirect
        window.location.href = url;
        
        //TODO: refact all this mess
    }

    const authWithGoogle = async() => {

        let provider = new firebase.auth.GoogleAuthProvider()

        provider.addScope('profile')
        provider.addScope('email')

        await firebase.auth().signInWithPopup(provider)

        history.push('/#/auth')

    }

    return(
        <div className='login-page'>

            <HeaderBig></HeaderBig>

            <div id='login-remotize-logo'>
                <img alt='remotize' width='400' src={RemotizeLogo}></img>
            </div>

            <div id='login-form-container'>

                <div className='login-welcome'>Bem vindo ao <b>Intelbras Remotize</b></div>

                <div className='login-content'>

                    <span>Entrar com:</span>

                    <div className='google-button' onClick={authWithGoogle}>
                        <img alt='google' src={GoogleLogo}></img>
                        <span>Google</span>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default LoginOAuthPage
