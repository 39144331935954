import React, { useContext, useEffect, useRef, useState } from 'react'
import { backendStatus } from '../../../../../../backend/backend'
import Form from '../../../../../../components/form/form'
import common from '../../../../../../components/form/validators/common'
import network from '../../../../../../components/form/validators/network'
import Input from '../../../../../../components/input/input'
import Loading from '../../../../../../components/loading/loading'
import DefaultStatusModals from '../../../../../../components/modal/default-status-modals'
import iputils from '../../../../../../components/utils/iputils'
import { WanOpMode } from '../../bifrost/wan/wan-constants'
import { DeviceContext } from '../../manage-page'
import Checkbox from '../../../../../../components/checkbox/checkbox'

import './lan.css'
import DeviceFactory from '../../../../../apis/device-factory'

export default function LanManagementPage() {

    const [lan, setLan] = useState(null)
    const [wan, setWan] = useState(null)
    const [lanDhcp, setLanDhcp] = useState(null)
    const [lansGate, setLansGate] = useState(null)
    const [lanGroup, setlanGroup] = useState(null)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)

    const device = useContext(DeviceContext)
    const lanTimer = useRef(null)
    const wanTimer = useRef(null)
    const lanGroupTimer = useRef(null)
    const mounted = useRef(true)
    const idRfo = DeviceFactory.isRFO(device.data.model)

    if (idRfo) {
        WanOpMode['BRIDGE'] = 3 //Temp: sharing lan page with tr069. Needs a refact.
    }


    useEffect(() => {
        const fetchData = async () => {
            await fetchLanGroup();
            await fetchWan();
        };

        fetchData();

        return () => {
            mounted.current = false;
            clearTimeout(lanTimer.current);
        };
    }, []);

    const fetchLanGroup = async () => {

        let result = await device.retrieveResource('lan-group')
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: lan-group')
            if (mounted.current)
                lanGroupTimer.current = setTimeout(fetchLanGroup, 5000)
            return
        }
        setlanGroup(result.content)
        setLanDhcp(result.content.dhcpserver)
        setLansGate(result.content.laninterface)
        window.oldLanGroup = Object.assign({}, result.content.dhcpserver);

        let lan = result.content.laninterface
        result = await device.retrieveResource(`interface/${lan.interfaceID}`)
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: lan')
            if (mounted.current)
                lanTimer.current = setTimeout(fetchLanGroup, 5000)
            return
        }

        setLan(result.content)
        window.oldlan = Object.assign({}, result.content);
    }

    const fetchWan = async () => {
        let result = await device.retrieveResource('wan')
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: wan')
            if (mounted.current)
                wanTimer.current = setTimeout(fetchWan, 5000)
            return
        }

        let wan = result.content[0]
        let interfaceID = ''
        if (wan.mode === WanOpMode.PPPOE_MODE) {

            result = await device.retrieveResource('pppoe')
            if (result.status !== backendStatus.SUCCESS) {
                console.error('error fetching resource: wan - pppoe')
                if (mounted.current)
                    wanTimer.current = setTimeout(fetchWan, 5000)
                return
            }

            interfaceID = result.content[0].interfaceID

        } else {
            interfaceID = wan.interfaceID
        }


        result = await device.retrieveResource(`interface/${interfaceID}`)
        if (result.status !== backendStatus.SUCCESS) {
            console.error('error fetching resource: wan')
            if (mounted.current)
                wanTimer.current = setTimeout(fetchWan, 5000)
            return
        }

        setWan(result.content)
    }



    const saveLan = async () => {

        setSaving(true)
        if (JSON.stringify(lan) !== JSON.stringify(window.oldlan)) {
            let result = await device.updateResource('interface', lan)

            if (result.status !== backendStatus.SUCCESS) {
                setSaving(false)
                setError(true)
                return
            }
        }

        if (JSON.stringify(lanGroup.dhcpserver) !== JSON.stringify(window.oldLanGroup)) {
            if (!DeviceFactory.isRFO(device.model)) {
                lanGroup.id = lanGroup.laninterface.interfaceID
            }
            let result = await device.updateResource('lan-group', lanGroup)
            if (result.status !== backendStatus.SUCCESS) {
                setSaving(false)
                setError(true)
                return
            }
        }
        let result = await device.apply()

        if (result.status !== backendStatus.SUCCESS) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSuccess(false)
        setSaving(false)
        setError(false)
    }

    return <div className='lan-management-page'>

        <DefaultStatusModals
            success={success}
            error={error}
            saving={saving}
            continueFn={dismissModal}
        ></DefaultStatusModals>

        {!lan || !lanDhcp ? <Loading show={true}></Loading> :
            <div className='lan-flex-container'>
                <div className='lan-ip'>
                    <Form onSubmit={saveLan} buttonId='button-save-lan'>

                        <div className='subtitle'>Configurações da LAN</div>

<Input id='lan-ip' label='Endereço IP'
    value={lan.ip4}
    name="ip"
    onChange={(e) => {
        const ip = e.target.value;
        setLan({ ...lan, ip4: ip });

        // Verifica se o IP é válido (IPv4)
        const ipParts = ip.split('.');
        if (ipParts.length === 4) {
            const dhcpStart = `${ipParts[0]}.${ipParts[1]}.${ipParts[2]}.100`;
            const dhcpEnd = `${ipParts[0]}.${ipParts[1]}.${ipParts[2]}.200`;

            setLanDhcp({
                ...lanDhcp,
                rangeStart: dhcpStart,
                rangeEnd: dhcpEnd,
            });

            setlanGroup({
                ...lanGroup,
                dhcpserver: {
                    ...lanGroup.dhcpserver,
                    rangeStart: dhcpStart,
                    rangeEnd: dhcpEnd,
                },
            });
        }
    }}
    validators={[common.required, network.ip4]}
/>
                        <Checkbox
                            id={'DhcpServer'}
                            label='Servidor DHCP'
                            value={lanDhcp.enabled}
                            checked={lanDhcp.enabled}
                            toggleFn={(e) => {
                                lanGroup.dhcpserver.enabled = !lanGroup.dhcpserver.enabled
                                setlanGroup({ ...lanGroup, enabled: e.target.value })
                            }
                            }
                        ></Checkbox>

                        {!lanDhcp.enabled ? <React.Fragment></React.Fragment> :
                            <React.Fragment>

                                <Input id='dhcpRangeStart' label='Início de Endereço IP'
                                    value={lanDhcp.rangeStart}
                                    name="ip1"
                                    onChange={(event) => {
                                        let value = event.target.value
                                        lanGroup.dhcpserver.rangeStart = value
                                        setlanGroup({ ...lanGroup })

                                    }}
                                    validators={[common.required, network.ip4]}
                                ></Input>
                                <Input id='dhcpRangeEnd' label='Fim de Endereço IP'
                                    value={lanDhcp.rangeEnd}
                                    name="ip2"
                                    onChange={(event) => {
                                        let value = event.target.value
                                        lanGroup.dhcpserver.rangeEnd = value
                                        setlanGroup({ ...lanGroup })

                                    }}
                                    validators={[common.required, network.ip4]}
                                ></Input>
                                {idRfo &&
                                    <Input id='lan-netmask' label='Máscara de sub-rede'
                                        value={lan.netmask}
                                        name="ip3"
                                        onChange={(e) => {
                                            lan.netmask = e.target.value

                                        }}
                                        validators={[common.required, network.ip4]}
                                    ></Input>
                                }

                                <Input id='lease-time' label='Lease Time'
                                    value={lanDhcp.leases}
                                    name="ip4"
                                    onChange={(event) => {
                                        let value = event.target.value
                                        lanGroup.dhcpserver.leases = value
                                        setlanGroup({ ...lanGroup })

                                    }}
                                    validators={[common.required]}
                                ></Input>
                                {idRfo &&
                                    <Input id='lan-gateway' label='Gateway'
                                        value={lansGate.gateway}
                                        name="ip5"
                                        onChange={(e) => {
                                            lansGate.gateway = e.target.value
                                            setLansGate({ ...lansGate })
                                        }}
                                        validators={[common.required, network.ip4]}
                                    ></Input>}

                                {!idRfo && (<>
                                    <Input id='dns1'
                                        name='dns1'
                                        label='DNS 1'
                                        value={lanGroup.dhcpserver.dns1}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            lanGroup.dhcpserver.dns1 = value
                                            setlanGroup({ ...lanGroup })
                                        }}
                                        validators={[common.required, network.ip4]}
                                    ></Input>
                                    <Input id='dns2'
                                        name='dns2'
                                        label='DNS 2'
                                        value={lanGroup.dhcpserver.dns2}
                                        onChange={(e) => {
                                            let value = e.target.value
                                            lanGroup.dhcpserver.dns2 = value
                                            setlanGroup({ ...lanGroup })
                                        }}
                                        validators={[network.ip4]}
                                    ></Input></>)}
                            </React.Fragment>}
                    </Form>
                </div>
            </div>
        }
    </div>
}
