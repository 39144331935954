import React, { useEffect } from 'react'
//import Checkbox from '../../../../../../components/checkbox/checkbox'
import Select from '../../../../../../components/select/select'
import DeviceFactory from '../../../../../apis/device-factory'
//import { WanOpMode } from './tr069-wan-constants'

const WanTypeOptions = [
    {text: 'INTERNET', value:1},
    {text: 'TR069', value:2},
    {text: 'INTERNET_TR069', value:3},
    {text: 'VOICE', value:4},
    {text: 'VOICE_INTERNET', value:5},
    {text: 'VOICE_TR069', value:6},
    {text: 'INTERNET_VOICE_TR069', value:7}
]

export default function RFOFields({device, wan, wanIndex, setWan}) {

    useEffect(() => {
        if(!wan[wanIndex]['type']){
            wan[wanIndex]['type'] = 2
            setWan([...wan])
        }
    })

    if(!DeviceFactory.isTR069(device.data.model)) return null

    let fields = []

   /* if(wan[wanIndex].mode !== WanOpMode.BRIDGE && DeviceFactory.isRFO(device.data.model)){

        fields.push(
            <Checkbox id='wan-default' key='wan-default'
                label='Interface padrão'
                value={wan[wanIndex].is_default}
                toggleFn={e => {
                    wan[wanIndex]['is_default'] = !wan[wanIndex]['is_default']
                    setWan([...wan])
                }}
            ></Checkbox>
        )
    }*/


    if(DeviceFactory.isRFO(device.data.model)){

        fields.push(<Select id='wan-type'
            key='wan-type'
            label='Tipo da WAN'
            value={wan[wanIndex].type}
            options={WanTypeOptions}
            onChange={e => {
                wan[wanIndex].type = Number(e.target.value)
                setWan([...wan])
            }}
        ></Select>)
    }

    return fields
}