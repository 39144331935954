import React, { useEffect, useContext } from 'react'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import { Switch, Route } from 'react-router-dom'
import AppPermissionsList from './app-permissions-list'
import AppPermissions from './app-permissions'

export default function AppPermissionsPage(){

    const header = useContext(MainHeaderContext)

    useEffect(() => {

        header.title.set('Aplicativo')
        header.backRoute.set('/app')

        // eslint-disable-next-line
    }, [])

    return <Switch>
        <Route path='/app/:id' component={AppPermissions}></Route>
        <Route path='/app*' component={AppPermissionsList}></Route>
    </Switch>

}