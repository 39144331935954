import React from 'react'
import Input from '../../../components/input/input';
import network from '../../../components/form/validators/network'
import common from '../../../components/form/validators/common'
import constants from './profile-contants';

export default function ProfileWanStatic({wan, setWan, lan}) {


    function handleIPInput(e){

        let value = e.target.value
        let field = e.target.name

        wan.static[field] = value
        setWan({...wan})
    }

    function handleNetmask(e){

        let value = e.target.value

        wan.static.netmask = value
        setWan({...wan})
    }

    const handleMTU = (e) => {

        let value = e.target.value

        if(isNaN(value))
            return

        value = Number(value)

        if(value > constants.MAX_IP4_MTU + 100000) return

        wan.mtu = value
        setWan({...wan})

    }

    const dontUseWanIpAsGw = (value) => {

        if(wan.static.ip === value)
            return 'O endereço IP não pode ser utilizado como gateway'

        return ''
    }

    const dontUseWanIpAsDNS = (value) => {

        if(wan.static.ip === value)
            return 'O endereço WAN não pode ser utilizado como DNS'

        return ''
    }

    const validateDNSWithDNS1 = (value) => {
        if(value === wan.static.dns1)
            return 'Este endereço já foi utilizado como DNS 1'
        return ''
    }

    const validateDNSWithDNS2 = (value) => {
        if(value === wan.static.dns2)
            return 'Este endereço já foi utilizado como DNS 2'
        return ''
    }

    const validateWithLan = () => {
        if(network.sameNetwork(wan.static.ip, {network: lan.netmask, ip: lan.ip}) === '')
            return 'Este endereço está em conflito com a LAN'

        return ''
    }


    return [

        <Input id='static-ip'
            name='ip'
            label='Endereço IP'
            value={wan.static.ip}
            onChange={handleIPInput}
            validators={[common.required, network.ip4, validateWithLan]}
            key='static-1'
        ></Input>,

        <Input id='static-netmask'
            label='Máscara de sub-rede'
            value={wan.static.netmask}
            onChange={handleNetmask}
            validators={[common.required, network.netmask]}
            key='static-2'
        ></Input>,

        <Input id='statip-gw'
            label='Gateway'
            value={wan.static.gateway}
            name='gateway'
            onChange={handleIPInput}
            validators={[
                common.required,
                network.ip4,
                {fn:network.sameNetwork, params:{network: wan.static.netmask, ip: wan.static.ip}},
                dontUseWanIpAsGw
            ]}
            key='static-3'
        ></Input>,

        <Input id='statip-dns1'
            label='DNS 1'
            value={wan.static.dns1}
            name='dns1'
            onChange={handleIPInput}
            validators={[common.required, network.ip4, dontUseWanIpAsDNS]}
            key='static-4'
        ></Input>,

        <Input id='statip-dns2'
            label='DNS 2'
            value={wan.static.dns2}
            name='dns2'
            onChange={handleIPInput}
            validators={[network.ip4, validateDNSWithDNS1, dontUseWanIpAsDNS]}
            key='static-5'
        ></Input>,

        <Input id='statip-dns3'
            label='DNS 3'
            value={wan.static.dns3}
            name='dns3'
            onChange={handleIPInput}
            validators={[network.ip4, validateDNSWithDNS1, validateDNSWithDNS2, dontUseWanIpAsDNS]}
            key='static-6'
        ></Input>,

        <Input id='static-mtu'
            label='MTU'
            value={wan.mtu}
            onChange={handleMTU}
            validators={[network.mtu]}
            key='static-7'
        ></Input>

    ]
}