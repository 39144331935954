import React from 'react'

import diagramRemotize from '../../images/diagrama_conexao.png'

import './diagram.css'

export default function DiagramRemotize({infoText}){

    return <div id='diagram'>

        <div className='info-text'>{infoText ? infoText : 'Faça o download dos nossos aplicativos para desktop:'}</div>

        <span className='diagram'>
            <img id='diagram_remotize' alt='Diagrama de rede remotize' src={diagramRemotize}></img>
        </span>

    </div>
}