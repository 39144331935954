import React from 'react'

import './tabs.css'

function getTabClasses(tabData, activeRoute) {
    let classes = 'tab'

    if(tabData.disable) {
        classes += ' disable'
    } else {
        classes += ' clickable'
    }

    if(activeRoute.includes(tabData.route))
        classes += ' active'

    return classes
}

export default function Tabs(props){

    let {data, route} = props

    return (
        <div className='tabs-container'>
            {
                data.map((tabData, key) => {
                    return <div key={key} id={`tab-${tabData.label}`} 
                        className={getTabClasses(tabData, route)}
                        onClick={tabData.disable ? null : () => props.activateFn(tabData.route)}
                    >{tabData.label}</div>
                })
            }
        </div>
    )
}