import React, { useContext, useEffect, useState } from 'react';
import isElectron from './server/is-electron'
import { useHistory } from 'react-router-dom'

import './glogin.css'
import { MenuContext } from '../components/menu/menu-state';
import { BackendContext } from '../backend/backend';
import { UserContext } from './user-context';
import { MainHeaderContext } from '../components/main-header/main-header-state';
import HeaderBig from '../components/headerbig/headerbig';
import Loading from '../components/loading/loading';
import RoundedCheckIcon from '../components/icons/rounded-check'
import RemotizeLogo from '../images/remotize-green.png'

let auth2 = null

export let isGLogin = false

function GLogin() {

    const [signed, setSigned] = useState(false)
    const menu = useContext(MenuContext)
    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const [,setUser] = useContext(UserContext)
    const history = useHistory()
    let isLogout = history.location.pathname.includes('logout')

    useEffect(() => {

        menu.open.set(false)
        header.hidden.set(true)
        header.navigation.set(false)
        header.notifications.set(false)
        isGLogin = true

        if(!isElectron())
            googleSDK();

        if(!isLogout)
            startLogin()

        // eslint-disable-next-line 
    }, [])

    const startLogin = () => {

        if(!isElectron())
            return

        const { ipcRenderer } = window.require('electron');
        ipcRenderer.send('login')
        ipcRenderer.once('login-data', async(event, arg) => {

            localStorage.setItem('username', arg.profile.name) //FIXME: remove this use of localStorage
            localStorage.setItem('user_id', arg.profile.id) //FIXME: remove this use of localStorage
            localStorage.setItem('uemail', arg.profile.email) //FIXME: remove this use of localStorage
            localStorage.setItem('token', arg.token)
            localStorage.setItem('token_expires_at', arg.token_expires_at)
            menu.username.set(arg.profile.username)
            menu.avatar.set(arg.profile.picture)

            backend.setAuthInfo({token: arg.token})
            setUser({username: arg.profile.id})

            history.push('/devices')
        })
    }

    const sendAuthInfo = async(info) => {

        try {

            await fetch('http://localhost:3030', {method: 'POST', body: JSON.stringify(info)})
            window.close()

        }catch(error){

            console.log(error)
            console.log('Trying again')
            setTimeout(() => {
                sendAuthInfo(info)
            }, 1000)

        }
    }

    const handleUser = (googleUser) => {

        let profile = googleUser.getBasicProfile();

        sendAuthInfo({token: googleUser.getAuthResponse().id_token, profile: {
            id: profile.getId(),
            name: profile.getName(),
            picture: profile.getImageUrl(),
            email: profile.getEmail()
        }, token_expires_at: googleUser.getAuthResponse().expires_at})

        setSigned(true)
    }

    const prepareLoginButton = async() => {

        let button = document.getElementById('gbutton')

        auth2.attachClickHandler(button, {}, handleUser, (error) => {
            alert(JSON.stringify(error, undefined, 2));
        });
    }
    
    const googleSDK = () => {
 
        window['googleSDKLoaded'] = () => {
          window['gapi'].load('auth2', () => {
            auth2 = window['gapi'].auth2.init({
              client_id: process.env.REACT_APP_GOOGLE_CLIENT,
              cookiepolicy: 'single_host_origin',
              scope: 'profile email'
            });
            setTimeout(prepareLoginButton, 2000)
          });
        }
     
        (function(d, s, id){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
     
    }
   
    return (
    <div className="google-auth login-page">

        <HeaderBig></HeaderBig>

        <div id='login-remotize-logo'>
            <img alt='remotize' width='400' src={RemotizeLogo}></img>
        </div>

            <div className='glogin-box'>

                <div className='glogin-box-title'>
                    Bem vindo ao <b>Intelbras Remotize</b>
                </div>

                {!isElectron() ? <React.Fragment>

                    {signed ? 
                        <div className='glogin-signed'>
                            <RoundedCheckIcon></RoundedCheckIcon>
                            <div>Login realizado com sucesso.</div> 
                            Continue no aplicativo desktop.
                            Esta janela já pode ser fechada.
                        </div>
                    :null}

                    {!signed ? <React.Fragment>
                        <div className='glogin-explain'>
                            Entre com sua conta para acessar o aplicativo desktop
                        </div>

                        <button className="ui button google-auth__button" id='gbutton'>
                            {/* FIXME create logo icon in react and import */}
                            <img alt="google_logo" className="google-auth__logo" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" /> 
                            Logar com Google
                        </button>
                    </React.Fragment> : null}

                </React.Fragment> : null}

                {isElectron() ? (
                    <React.Fragment>

                        {isLogout ? 

                            <div className='glogin-again-button' onClick={startLogin}>Entrar</div> : 

                        null}

                        {!isLogout ? 
                            <React.Fragment>
                                <Loading show='true'></Loading>
                                <span>Complete seu login no browser e retorne para continuarmos</span>
                            </React.Fragment> : 
                        null}

                    </React.Fragment>
                ) : null}

            </div>
        </div>
    );
}
 
export default GLogin;