import React, { useState, useRef, useEffect } from 'react'
import Button from '../../../components/button/button'
import Overlay from '../../../components/overlay/overlay'
import Input from '../../../components/input/input'
import List from '../../../components/list/list'
import common from '../../../components/form/validators/common'
import CloseIcon from '../../../components/icons/close'
import RadioButton from '../../../components/radio-button/radio'

import { useToasts } from 'react-toast-notifications'

const DISABLE_MAC_VALIDATION = process.env.REACT_APP_DISABLE_MAC_VALIDATION === "true"
const DISABLE_GATEWAYS = process.env.REACT_APP_DISABLE_GATEWAYS === "true"

const DeviceCategory = {
    ROUTER: 0,
    GATEWAY: 1
}

export default function DeviceAsssociator({ onSubmit }) {

    const [selecting, setSelecting] = useState(false)
    const [maclist, setMacList] = useState([])
    const [mac, setMac] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [category, setCategory] = useState(DeviceCategory.ROUTER)

    const inputRef = useRef(null);
    const [fileName, setFileName] = useState("Selecione um arquivo.");
    const [csvData, setCsvData] = useState(new Map())
    const [csvWasLoaded, setCsvWasLoaded] = useState(false)
    const csvFileModel = "data:text/csv;base64,VVNVQVJJTztTRU5IQTtNQUMKYWRtaW47YWRtaW47MDA6MDA6MDA6MDA6MDA6MDA="

    useEffect(() => {
        if (csvWasLoaded) {
            if (maclist.length === 0) {
                resetState()
            }
        }
    }, [maclist])

    const { addToast } = useToasts()

    const openFile = () => {
        inputRef.current.click();
    };

    const blobToData = (blob) => {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.readAsText(blob)
        })
    }

    const handleOpenFile = async event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        resetState()

        const response = await blobToData(fileObj)
        const separator = response.includes(';') ? ';' : ','

        let lines = response.split('\n').filter(e => e.length !== 0).map(function (line) {
            let res = line.split(separator).map(e => e.replace('\r', ''))
            return res.filter(e => e.length !== 0);
        });

        let csv = new Map()

        try {
            for (let i = 0; i < lines.length; i++) {
                if (i === 0) {
                    const res = lines[i][0] === "USUARIO" && lines[i][1] === "SENHA" && lines[i][2] === "MAC"

                    if (res) { continue }

                    addToast('Erro no cabeçalho do arquivo. Obtenha o padrão correto clicando em "Baixar modelo".', {
                        appearance: 'error',
                        autoDismiss: true,
                        autoDismissTimeout: 5000,
                    })
                    resetState()
                    return
                }

                csv.set((lines[i][0], lines[i][1]), {
                    username: lines[i][0].length !== 0 ? lines[i][0].trim() : 'admin',
                    password: lines[i][1].length !== 0 ? lines[i][1].trim() : 'admin',
                    devices: [],
                    category: category
                })
            }
        } catch (error) {
            addToast('Erro: Arquivo corrompido (0).', {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 4000,
            })
            resetState()
            return
        }

        try {
            for (let i = 0; i < lines.length; i++) {
                if (i === 0) { continue }

                const mac = lines[i][2].replace(/:/g, '').toLowerCase().trim()

                if (validadeMac(mac) === '') {
                    csv.get((lines[i][0], lines[i][1])).devices.push(mac)
                } else {
                    addToast('Atenção: Um ou mais endereços MAC presentes no arquivo estão incorretos.', {
                        appearance: 'warning',
                        autoDismiss: true,
                        autoDismissTimeout: 1500,
                    })
                }
            }
        } catch (error) {
            addToast('Erro: Arquivo corrompido (1).', {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 4000,
            })
            resetState()
            return
        }

        csv.forEach(element => {
            element.devices.forEach((mac) => {
                addMacFromCsv(mac)
            })
        })

        setCsvData(csv)
        setFileName(fileObj.name)
        setCsvWasLoaded(true)
    };

    const addMacFromCsv = (mac) => {

        if (!maclist.every(item => item[0].props.children[0] !== mac)) return

        maclist.push([
            <div className='mac-entry'>
                {mac.replace(/:/g, '').toLowerCase()}

                <div className='mac-entry del-icon' onClick={() => {
                    setMacList(prev => [...prev.filter((entry) => {
                        return entry[0].props.children[0] !== mac.replace(/:/g, '').toLowerCase()
                    })])

                    setCsvData(prev => {
                        prev.forEach((value, key) => {
                            value.devices = value.devices.filter(item => item !== mac.replace(/:/g, '').toLowerCase())
                            if (value.devices.length === 0) { prev.delete(key) }
                        })
                        return prev
                    })
                }}>
                    <CloseIcon size={12}></CloseIcon>
                </div>
            </div>
        ])

        setMacList([...maclist])
        setMac('')
    }

    const addMac = () => {

        maclist.push([
            <div className='mac-entry'>
                {mac.replace(/:/g, '').toLowerCase()}

                <div className='mac-entry del-icon' onClick={() => {
                    setMacList(prev => [...prev.filter((entry) => entry[0].props.children[0] !== mac)])
                }}>
                    <CloseIcon size={12}></CloseIcon>
                </div>
            </div>
        ])

        setMacList([...maclist])
        setMac('')
    }

    const submitAssociation = () => {
        onSubmit({
            username: username,
            password: password,
            devices: maclist.map((mac) => mac[0].props.children[0]),
            category: category
        })

        setSelecting(false)
        resetState()
    }

    const submitAssociationCsv = () => {
        csvData.forEach((value) => {
            onSubmit(value)
        })

        setSelecting(false)
        resetState()
    }

    const resetState = () => {

        setUsername('')
        setPassword('')
        setMacList(maclist.splice(0, maclist.length))
        setMacList([])

        setCsvWasLoaded(false)
        csvData.clear()
        setFileName("Selecione um arquivo.")
        if(inputRef.current !== null)
        {
            inputRef.current.value = ""
        }
    }

    const submitDisabled = () => {
        return username === '' || password === '' || maclist.length === 0
    }


    const validadeMac = (value) => {

        if (category !== DeviceCategory.ROUTER)
            return ''

        if (value === '') {
            return 'Não pode ser vazio'
        }

        if (DISABLE_MAC_VALIDATION)
            return ''

        let stripedMac = value.replace(/:/g, '').toLowerCase()
        let regex = /[0-9a-f]{12}/

        if (stripedMac.length !== 12 || !regex.test(stripedMac)) {
            return 'Mac inválido'
        }

        for (let mac of maclist) {

            let macValue = mac[0].props.children[0]
            if (macValue === value)
                return 'Este dispositivo já foi adicionado'
        }

        return ''
    }

    return <div id='device-associator'>

        <Button className='itb-button'
            text='Associar dispositivos'
            onClick={() => setSelecting(true)}
        ></Button>

        <Overlay open={selecting} onClick={() => setSelecting(false)}></Overlay>

        {selecting &&

            <div id='device-associator-container'>

                <div className='close' onClick={() => { setSelecting(false); resetState() }}>
                    <CloseIcon size={15}></CloseIcon>
                </div>

                <div className='device-associator-form'>
                    <div className='subtitle'>Associar dispositivos</div>

                    {category === DeviceCategory.GATEWAY && <div className='info-text warning'>
                        Acesse o guia de instalação e faça o download <a href="https://remotize.intelbras.com.br/manual/#51">clicando aqui!</a>
                    </div>}

                    <div className='info-text'>
                        Para associar o dispositivo, coloque as credenciais de acesso aos dispositivos e seus
                        identificadores conforme indicado. Você pode realizar a associação para todos os dispositivos
                        compatíveis que desejar.
                    </div>

                    {DISABLE_GATEWAYS ? null :

                        <div className='category-select'>

                            <span>Tipo de dispositivo</span>

                            <RadioButton id='cat-router'
                                value={category === DeviceCategory.ROUTER}
                                label='Roteadores'
                                toggleFn={() => setCategory(DeviceCategory.ROUTER)}
                            ></RadioButton>
                            <RadioButton id='cat-gateway'
                                value={category === DeviceCategory.GATEWAY}
                                label='Gateway'
                                toggleFn={() => setCategory(DeviceCategory.GATEWAY)}
                            ></RadioButton>
                        </div>

                    }

                    {category === DeviceCategory.ROUTER ? <Input
                        label='Usuário dos roteadores'
                        id='associate-user'
                        placeholder='Usuário'
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value)
                        }}
                        validators={[common.required]}
                        disabled={csvWasLoaded}
                    ></Input> : null}

                    <Input
                        label={category === DeviceCategory.ROUTER ? 'Senha dos roteadores' : 'Token de autenticação'}
                        id='associate-password'
                        placeholder='Senha'
                        vaue={password}
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                        validators={[common.required]}
                        disabled={csvWasLoaded}
                    ></Input>

                    <div className='input-add'>
                        <Input id='associate-add'
                            label={category === DeviceCategory.ROUTER ? 'MAC da LAN' : 'Identificador do gateway'}
                            value={mac}
                            onChange={(e) => { setMac(e.target.value) }}
                            validators={[validadeMac]}
                            disabled={csvWasLoaded}
                        ></Input>

                        {category === DeviceCategory.ROUTER ? <Button text={maclist.length === 0 ? 'Adicionar' : 'Adicionar mais'}
                            onClick={addMac}
                            disabled={validadeMac(mac) !== ''}
                        ></Button> :
                            <Button text={'Associar'}
                                onClick={() => {
                                    addMac()
                                    submitAssociation()
                                }}
                                disabled={mac.length === 0}
                            ></Button>}

                    </div>

                    {category === DeviceCategory.ROUTER ?
                        <div className='input-add'>
                            <div className='open-file'>
                                <div className='file-name'>
                                    <input
                                        style={{ display: 'none' }}
                                        ref={inputRef}
                                        type="file"
                                        accept='.csv'
                                        onChange={handleOpenFile}
                                    />

                                    <div className='filename-label'>{fileName}</div>
                                </div>


                                <div className='import-btn'>
                                    <Button text={'Importar csv'}
                                        onClick={openFile}
                                    ></Button>
                                </div>
                            </div>

                        </div> : null}

                    {category === DeviceCategory.ROUTER ?
                        <div className='download-model'>
                            <div>   
                                <a className='download-link'
                                    href={csvFileModel}
                                    download="modelo.csv">
                                    Baixar modelo
                                </a>

                            </div>

                        </div> : null}

                </div>

                {category === DeviceCategory.ROUTER ? <div className='device-associator-list'>

                    <div className='assoc-list-container'>
                        <List
                            columns={[{ header: 'MAC do dispositivo' }]}
                            lines={maclist}
                            width={300}
                        ></List>
                    </div>

                    <Button id='associate-submit'
                        text='Pronto! Associar'
                        onClick={csvWasLoaded ? submitAssociationCsv : submitAssociation}
                        disabled={submitDisabled() && !csvWasLoaded}
                    ></Button>

                </div> : null}

            </div>

        }

    </div>
}