
const PPPoEConstants = {
    PPPOE_FIELD_MIN_SIZE: 0,
    PPPOE_FIELD_MAX_SIZE: 128,
    PPPOE_SERVER_FIELD_MAX_SIZE: 40,

    PPPOE_MIN_MTU_VALUE: 68,
    PPPOE_MAX_MTU_VALUE: 1492,
}

export const DefaultPPPoE = {
    interfaceID: 'ppp0',
    username: '',
    password: '',
    server: '',
    service: '',
    mtu: 1492
}

export default PPPoEConstants
