import React, { useEffect, useContext, useState } from 'react'
import Form from '../../../components/form/form'
import Input from '../../../components/input/input'
import { UserContext } from '../../user-context'

import './signup.css'
import common from '../../../components/form/validators/common'
import cnpjValidator from '../../../components/form/validators/cnpj'
import phoneValidator from '../../../components/form/validators/phone'
import cnpjUtils from './cnpj-utils'
import phoneUtils from './phone-utils'
import { BackendContext, backendStatus } from '../../../backend/backend'
import { useHistory } from 'react-router-dom'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import DefaultStatusModals from '../../../components/modal/default-status-modals'
import httpStatus from 'http-status'
import Loading from '../../../components/loading/loading'
import Checkbox from '../../../components/checkbox/checkbox'
import { fetchLatestTerms, LGPDFileLink, saveConsent } from '../lgpd/lgpd-consent'
import { FirebaseContext } from '../../firebase'

export const RemotizeType = {
    BLOCKED: 0,
    ADMIN: 1,
    COMMON: 2
}

const emptyIsp = {
    cnpj: '',
    ispid: '',
    name: '',
    phone: '',
    subscribers: 0
}

export default function SignUPPage(){

    const firebase = useContext(FirebaseContext)

    const [userContext,] = useContext(UserContext)
    const token = firebase.getToken()
    const backend = useContext(BackendContext)
    const headerState = useContext(MainHeaderContext)
    const history = useHistory()

    const [user, setUser] = useState(null)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [consent, setConsent] = useState(false)
    const [latestTerms, setLatestTerms] = useState(null)

    const [cnpj, setCnpj] = useState('')
    const [ispLoading, setIspLoading] = useState(false)
    const [isp, setIsp] = useState(null)
    const [isNewISP, setIsNewISP] = useState(false)

    const [newIspData, setNewIspData] = useState({
        ispid: '',
        cnpj: '',
        name: '',
        phone: '',
        subscribers: '',
    })

    const [mounted, setMounted] = useState(true)

    useEffect(() => {

        if(userContext.ispid !== ''){
            history.push('/')
        }

        headerState.title.set('Complete seu cadastro')
        headerState.navigation.set(false)

        fetchLatestTerms(backend, setLatestTerms)

        if(mounted)
            setUser(userContext)


        return () => {
            headerState.navigation.set(true)
            setMounted(false)
        }

        // eslint-disable-next-line 
    },[userContext])

    useEffect(() => {

        if(cnpj === '') return

        fetchISP(cnpj)

        // eslint-disable-next-line
    }, [cnpj])

    const submitISP = async() => {

        newIspData.cnpj = cnpj
        newIspData.ispid = user.username
        newIspData.subscribers = String(newIspData.subscribers)

        let result = await backend.create(`isp`, newIspData)

        if(result.status === backendStatus.ERROR){
            setErrorText("Houve um erro ao cadastrar sua conta, tente novamente mais tarde.")
            setSaving(false)
            setError(true)
            return
        }

    }

    const submit = async(values) => {

        setSaving(true)

        let saved = saveConsent(backend, user.username, latestTerms)
        if(!saved){
            setError(true)
            return
        }

        if(isNewISP)
            await submitISP()

        let result = await backend.update(`users/${user.id}`, user)

        if(result.status === backendStatus.SUCCESS){
            setTimeout(() => {
                setSaving(false)
                history.push('/')
            }, 1000)
            return
        }

        if(result.content === 409){
            setErrorText('Já existe uma conta cadastrada para o CNPJ informado.')
            setError(true)
            return
        }

        setErrorText('Houve um erro ao processar sua solicitação de cadastro.')
        setError(true)
    }

    const fetchISP = async(cnpj) => {

        let message = cnpjValidator.cnpj(cnpj)

        if(message !== '') return

        setIspLoading(true)

        let result = await backend.retrieve(`isp/cnpj/${cnpjUtils.removeMask(cnpj)}`)

        if(result.status === backendStatus.SUCCESS){
            let isp = result.content
            setIsp(isp)
            setIsNewISP(false)
            setUser({ ...user,
                ispid: result.content.ispid,
                type: RemotizeType.BLOCKED
            })
        }
        if(result.content.status === httpStatus.NOT_FOUND){
            setIsNewISP(true)
            setIsp({...emptyIsp, ispid: user.username})
            setUser({ ...user,
                ispid: user.username,
                type: RemotizeType.ADMIN
            })
        }

        setIspLoading(false)
    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
    }

    const userIsDefined = () => {
        return user && user.username !== ''
    }
    const forbiddenCharacters = async(value) => {
        return (/[üäåëèîìÄÅæÆöòûùÿÖÜø£Ø×ùúñÑªº¿®¬½¼«»¡¢ãÃÊËÈÎÏßÔÒõÕµÚÛÙýÝ]/).test(value) ? 'Contém caracteres inválidos' : ''
    }

    return (
        <div id='signup-page'>

            <DefaultStatusModals
                saving={saving}
                savingText='Aguarde enquanto concluímos o seu cadastro.'
                error={error}
                errorText={errorText}
                continueFn={dismissModal}
            >
            </DefaultStatusModals>

            <div className='signup-welcome'>
                Bem vindo {token ? <b>{token.given_name}</b> : null}! Precisamos de mais algumas informações para completar o seu cadastro.
            </div>

            { userIsDefined() ? <Form onSubmit={submit} submitText='Cadastrar' disableButton={!consent || cnpj === ''}>

                <Input
                    name='cnpj'
                    label='CNPJ do provedor'
                    validators={[common.required, cnpjValidator.cnpj]}
                    value={cnpj}
                    onChange={(e) => {

                       if(cnpjUtils.isValidValue(e)){
                            setCnpj(e.target.value)
                       }
                    }}
                    onBlur={() => {
                        setCnpj(cnpjUtils.applyMask(cnpj))
                    }}
                    onFocus={() => {
                        setCnpj(cnpjUtils.removeMask(cnpj))
                    }}
                ></Input>

                {ispLoading && <Loading show={true}></Loading>}

                {!isp ? null : isNewISP ? <React.Fragment>
                    <Input
                        name='name'
                        label='Nome do provedor'
                        value={newIspData.name}
                        validators={[common.required, forbiddenCharacters, {fn: common.size, params: {min: 1, max: 128}}]}
                        onChange={(e) => {
                            newIspData.name = e.target.value
                            setNewIspData({...newIspData})
                        }}
                    ></Input>

                    <Input
                        name='phone'
                        label='Telefone do provedor'
                        value={newIspData.phone}
                        validators={[common.required, phoneValidator.phone]}
                        onChange={(e) => {

                           if(phoneUtils.isValidValue(e)){
                                newIspData.phone = phoneUtils.applyMaskOnchange(e.target.value)
                                setNewIspData({...newIspData})

                           }
                        }}
                        onBlur={() => {
                            newIspData.phone = phoneUtils.removeMask(newIspData.phone)
                            setNewIspData({...newIspData})
                        }}
                        onFocus={() => {
                            newIspData.phone = phoneUtils.applyMaskOnchange(newIspData.phone)
                            setNewIspData({...newIspData})
                        }}
                    ></Input>

                    <Input
                        name='subscribers'
                        label='Número de assinantes'
                        value={newIspData.subscribers}
                        validators={[common.required, {fn: common.size, params: {min: 1, max: 9}}]}
                        onChange={(e) => {

                            if(isNaN(e.target.value))
                                return

                            newIspData.subscribers = e.target.value
                            setNewIspData({...newIspData})
                        }}
                    ></Input>

                </React.Fragment>

                : <div id='signup-isp-info'>
                    Sua conta será associada ao seguinte provedor:
                    <br></br> <br></br>
                    <label><b>{isp.name}</b></label>
                    <br></br> <br></br>
                </div>

                }


                <Checkbox
                    id='consent-checkbox'
                    label={
                        <span className='lgpd-label'>Declaro que li e aceito os
                            <LGPDFileLink inline label='Termos de Uso' linkID='terms_of_use'></LGPDFileLink>
                            e a
                            <LGPDFileLink inline label='Política de Privacidade' linkID='privacy_policy'></LGPDFileLink>
                            da Intelbras.
                        </span>
                    }
                    value={consent}
                    toggleFn={() => setConsent(!consent)}
                >
                </Checkbox>

            </Form> : null}
        </div>
    )
}