import React, { useState, useContext } from 'react'

import './notifications.css'
import Overlay from '../overlay/overlay'
import CloseIcon from '../icons/close'
import { NotificationsContext } from './notifications-state'
import { useHistory } from 'react-router-dom'

function Notification({content, onRemove}) {

    const history = useHistory()
    const state = useContext(NotificationsContext)
    const [removed, setRemoved] = useState(false)

    return <div className={`notification ${removed ? 'removed' : ''}`}>
        <div className='notification-content' onClick={(e) => {
            e.stopPropagation()
            setRemoved(true)
            setTimeout(() => {
                onRemove(content.id)
                setRemoved(false)
            }, 200)
        }}>
            <CloseIcon size='13' ></CloseIcon>
        </div>
            <pre className='notification-content' onClick={() => {
                history.push(content.location)
                state.open.set(false)
            }}>
                {content.text}
            </pre>
    </div>
}

export default function Notifications({onRemove}) {

    const state = useContext(NotificationsContext)

    return <React.Fragment>
        
        <Overlay open={state.open.value} onClick={() => {state.open.set(false)}}></Overlay>
        <div id='notifications' className={state.open.value ? 'open' : null}>

            {
                state.notifications.value.length === 0 ? <div className='no-notification'>Nenhuma notificação</div> :
                <React.Fragment>
                    <div className='notifications-header'>Notificações
                        <div className='delete-all-notifications'>
                            <span onClick={(e) => {
                                state.notifications.value.forEach((notification) => {
                                    e.stopPropagation()
                                    setTimeout(() => {
                                        onRemove(notification.id)
                                    }, 200)
                                })
                            }}>
                                Excluir todas as notificações ({state.notifications.value.length})
                            </span>
                        </div>
                    </div>
                    {state.notifications.value.map((notification, key) => {
                        return <Notification content={notification} onRemove={onRemove} key={notification.id}></Notification>
                    })}
                </React.Fragment>
            }

        </div>

    </React.Fragment>}