import React, { useEffect } from 'react'
import common from '../../../../../../components/form/validators/common'
import network from '../../../../../../components/form/validators/network'
import Input from '../../../../../../components/input/input'
import Loading from '../../../../../../components/loading/loading'
import iputils from '../../../../../../components/utils/iputils'
import constants from './wan-constants'

import WanDNS from './wan-dns'

const MTU_INPUT_OFFSET = 100000

export default function WanStatic({_interface, setInterface, wan, setWan, wanIndex}) {

    useEffect(() => {

        wan[wanIndex].dns_v4.automatic = false
        setWan([...wan])

        // eslint-disable-next-line
    }, [])

    const isGatewayInSameNetwork = (value) => {
        if(iputils.sameNetwork(value, _interface.ip4, _interface.netmask))
            return ''

        return 'O gateway deve estar na mesma rede do endereço WAN.'
    }

    const handleMTU = (e) => {
        let value = e.target.value

        if(isNaN(value)) return

        let mtu = Number(value)

        if(mtu > constants.MAX_IP4_MTU + MTU_INPUT_OFFSET) return

        _interface.mtu = mtu
        setInterface({..._interface})
    }

    const sameIP = (value, {wanIp, gatewayIp}) => {

        if(value === wanIp)
            return 'O gateway deve ser diferente do endereço IP do roteador'
        else if(value === gatewayIp)
            return 'O IP do roteador deve ser diferente do endereço do gateway'

        return ''
    }

    const getExternalValidationMessage = () => {

        let message = network.broadcastAddress(_interface.ip4, _interface)
        if(message !== '')
            return message


        message = network.identificatorAddress(_interface.ip4, _interface)
        if(message !== '')
            return message

        return ''
    }

    return (
        !_interface || !wan[wanIndex] ? <Loading show={true}></Loading> :
        <React.Fragment>

            <Input id='wan-static-ip'
                name='ip4'
                label='Endereço IP'
                value={_interface.ip4}
                onChange={(e) => {
                    _interface.ip4 = e.target.value
                    setInterface({..._interface})
                }}
                validators={[
                    common.required,
                    network.ip4,
                    {fn:sameIP, params:{gatewayIp: wan[wanIndex].gateway}},
                ]}
                errorMessage={getExternalValidationMessage()}
            ></Input>

            <Input id='wan-static-mask'
                name='netmask'
                label='Máscara de sub-rede'
                value={_interface.netmask}
                onChange={(e) => {
                    _interface.netmask = e.target.value
                    setInterface({..._interface})
                }}
                validators={[common.required, network.netmask]}
            ></Input>

            <Input id='wan-static-gw'
                name='gateway'
                label='Gateway'
                value={wan[wanIndex].gateway}
                onChange={(e) => {
                    wan[wanIndex].gateway = e.target.value
                    setWan([...wan])
                }}
                validators={[
                    common.required,
                    network.ip4,
                    {fn:sameIP, params:{wanIp: _interface.ip4}},
                    isGatewayInSameNetwork
                ]}
            />

            <Input id='wan-static-mtu'
                label='MTU'
                value={_interface.mtu}
                onChange={handleMTU}
                validators={[common.required, network.mtu]}
            ></Input>

            {WanDNS(_interface, wan, setWan, wanIndex)}

        </React.Fragment>
    )

}