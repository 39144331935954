import React from 'react'


function getSteps(stepsStatus) {
    var elements = []

    Object.keys(stepsStatus).forEach(step => {

        elements.push(<div key={step} className={`form-step ${stepsStatus[step] ? 'form-step-active' : ''}`}></div>)
    })

    return elements
}

export default function FormStepsIndicator({stepsStatus}) {
    return <div className='form-steps-indicator'>
        {
            getSteps(stepsStatus)
        }
    </div>
}