import React, { useEffect, useState } from 'react'

import ChevronRightIcon from '../icons/chevron-right'
import ChevronLeftIcon from '../icons/chevron-left'

import './form.css'

export default function FormSegment({
    segmentID, children, title, active, nextLabel, previousLabel, nextClicked, previousClicked, disableValidatorsNext,
    disableValidatorsPrevious, disableNavigation, disableButton}){

    const [isFulfilled, setIsFulfilled] = useState(true)


    useEffect(() => {
        
        validateSegment(false)

        // eslint-disable-next-line
    }, [children])

    const runChildrenValidators = async(children, focus = true) => {
        let isValid = true

        if(Array.isArray(children)){
            for(let child of children){
                let valid = await runValidators(child, focus)
                if(!valid){
                    isValid = false
                }
            }
        } else {
            let valid = await runValidators(children, focus)
            if(!valid){
                isValid = false
            }
        }

        return isValid
    }

    const runValidators = async(child, focus = true) => {

        if(Array.isArray(child)){
            return runChildrenValidators(child, focus)
        }

        if(child && child.props && child.props.children){
            return runChildrenValidators(child.props.children, focus)
        }

        if(child && child.props && child.props.validators){
            for(let validator of child.props.validators){

                let message = ''

                if(validator.params)
                    message = await validator.fn(child.props.value, validator.params)
                else
                    message = await validator(child.props.value)
                if(message !== ''){
                    let element = document.getElementById(child.props.id)
                    if(focus && element){
                        element.focus()
                        element.blur()
                    }
                    setIsFulfilled(false)
                    return false
                }
            }
        }

        return true
    }

    const validateSegment = async(focus = true) => {
        setIsFulfilled(!disableButton)
        return runValidators(children, focus)
    }

    const nextStep = async (e) => {

        e.preventDefault()

        if((!disableValidatorsNext && !await validateSegment()) || disableButton)
            return
        nextClicked()
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter" && nextClicked) {
            e.preventDefault()
            nextStep(e)
        }
    }

    return <div className={`form-segment ${active ? 'form-segment-active' : ''}`} onKeyPress={handleKeyPress}>

        <div className='form-segment-title'>{title}</div>

        {children}

        {nextLabel && !disableNavigation ? 
        <button id={`${segmentID ? segmentID + '-' : ''}form-segment-next`}
            className='form-segment-button form-segment-next' disabled={!isFulfilled}
            onClick={nextStep}> {nextLabel} <ChevronRightIcon size={22}></ChevronRightIcon>
        </button> : null}

        {previousLabel && !disableNavigation ? <button id={`${segmentID ? segmentID + '-' : ''}form-segment-previous`}
            className='form-segment-button form-segment-previous'
            onClick={async(e) => {

                e.preventDefault()

                if(!disableValidatorsPrevious && !await validateSegment())
                    return
                previousClicked()
            }
        }>
            <ChevronLeftIcon size={20}></ChevronLeftIcon> {previousLabel}
            </button> : null}

    </div>
}