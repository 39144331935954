

let defaults = {
    EASY_MESH_TYPE: {
        CONTROLLER: 0,
        AGENT: 1,
    },

    WAN_MODE: {
        DHCP: 0,
        STATIC: 1,
        PPPOE: 2
    },

    defaultWan: {
        mode: 0,
        mtu: 1500,
        pppoe: {
            username: '',
            password: '',
            mtu: 1492
        },
        static: {
            ip: '',
            netmask: '',
            gateway: '',
            dns1: '',
            dns2: '',
            dns3: ''
        },
        dns_v4: {
            automatic: true,
            dns1: "",
            dns2: "",
            dns3: ""
        },
        dns_v6: {
            automatic: true,
            dns1: "",
            dns2: "",
            dns3: ""
        }
    },

    defaultLan: {
        interfaceID: '',
        ip: '10.0.0.1',
        netmask: '255.255.255.0',
        gateway: '',
    },

    defaultWlan: {
        broadcast: true,
        ssid: 'INTELBRAS',
        key: 'Int3lBr@s',
        cipher: 3,
        crypto: 2,
        openWifi: false,
        MacInSSID: false,
        enableDualBand: true,
        useCompatibilityMode: false,
        freq_2_4G: {
            broadcast: true,
            ssid: "INTELBRAS",
            key: "Int3lBr@s",
            cipher: 3,
            crypto: 2,
            openWifi: false,
            MacInSSID: false,
        },
        freq_5G: {
            broadcast: true,
            ssid: "INTELBRAS",
            key: "Int3lBr@s-5",
            cipher: 3,
            crypto: 2,
            openWifi: false,
            MacInSSID: false,
        },
    },
    defaultRadio: {
        enableConfigureRadio: false,
        freq_2_4G: {
            enabled: true,
            opMode: 4,
            frequency: 0,
            configuredChannel: 0,
            channel: 11,
            extension: 1,
            bandwidth: 1,
            mu_mimo: false,
            beamforming: true,
            ofdma: false,
            twt: false,
            power: 2,
        },
        freq_5G: {
            enabled: true,
            opMode: 6,
            frequency: 1,
            configuredChannel: 44,
            channel: 44,
            extension: 0,
            bandwidth: 2,
            mu_mimo: true,
            beamforming: true,
            ofdma: false,
            twt: false,
            power: 2,
        }
    },
    defaultBandSteering: {
        enabled: true,
    },

    defaultRemoteAccess: {
        enabled: false,
        port: 8080,
        pingWan: true,
        ipv6Enabled: false,
        https_enable: false,
        https_port: 8443,
    },

    defaultRouterAccess: {
        username: 'admin',
        password: ''
    },

    defaultDhcpServer: {
        enabled: true,
        rangeStart: '10.0.0.2',
        rangeEnd: '10.0.0.254',
        dns1: '10.0.0.1',
        dns2: '',
        dhcpLeaseTime: 480,
    },

    defaultMesh: {
        enabled: false,
        controllerIp: '',
        backhaul_mac: '',
        backhaul_rssi: 0,
        type: 1,
        backhaul: 2
    },

    defaultFactoryDefault: {
        pressedTime: 20,
    },

    defaultSystem: {
        dmz: {
            enabled: false,
            ip4: '0.0.0.0'
        },
        id: '0',
        ipv6: {
            enabled: true
        },
        operationMode: 0,
        upnp: true,
        upnp_wan_if: ''
    },

    defaultTr069: {
        tr069_enable: false,
        ca_cert: "",
        acs: {
            acs_url: "",
            acs_username: "",
            acs_password: "",
            tr069_inform: false,
            tr069_interval: 0
        },
        connection: {
            enabled: false,
            conreq_name: "",
            conreq_pw: "",
            conreq_path: "",
            conreq_port: 0
        },
        stun: {
            enabled: false,
            stun_server_addr: "",
            stun_server_port: 0
        }
    }
}

const ProfileDefaultValues = { ...defaults }

export default ProfileDefaultValues