import React, { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ChevronLeftIcon from '../../../components/icons/chevron-left'
import { MenuContext } from '../../../components/menu/menu-state'
import { FirebaseContext } from '../../firebase'

export default function MyAccountWidget() {

    const [open, setOpen] = useState(false)

    const menu = useContext(MenuContext)
    const history = useHistory()
    const location = useLocation()
    const firebase = useContext(FirebaseContext)

    let path = location.pathname

    if(path.includes('signup') || path.includes('blocked') || path.includes('glogin')) return null

    return <div className={`account-overlay ${open ? 'open' : ''}`} onClick={() => setOpen(false)}>
        <div id='my-account-widget' onClick={(e) => {
            e.stopPropagation()
            setOpen(!open)
        }}>
            <div id='my-account-picture'>
                <img alt='minha conta' src={menu.avatar.value}></img>
            </div>
            <ChevronLeftIcon size='10'></ChevronLeftIcon>

            <div id='my-account-menu' style={{display: open ? 'block' : 'none'}}>
                <div className='my-account-menu-item' onClick={() => history.push('/myaccount')}>Minha conta</div>
                <div className='section-divider'></div>
                <div className='my-account-menu-item' onClick={() => firebase.signOut()}>Sair</div>
            </div>
        </div>
    </div>
}