
export const toString = (ipInt) => {

    let ip = ((ipInt >> 24) & 0x000000ff).toString() + '.' +
        ((ipInt >> 16) & 0x000000ff).toString() + '.' +
        ((ipInt >> 8) & 0x000000ff).toString() + '.' +
        (ipInt & 0x000000ff).toString()

    return ip
}

export const toInt = (ip) => {

    let ipOctets = ip.split('.')

    ipOctets = ipOctets.map((value) => { return Number(value) })
    let ipInt = 0;
    for (let i = 0; i < 4; i++)
        ipInt += (ipOctets[i] << (24 - 8 * i)) >>> 0

    return ipInt
}

const IPUtils = {

    ipToNextValidNetwork: (netmask, ip) => {

        let ipOctets = ip.split('.')
        let netmaskOctets = netmask.split('.')

        ipOctets = ipOctets.map((value) => { return Number(value) })
        let ipInt = 0;
        for (let i = 0; i < 4; i++)
            ipInt += ipOctets[i] << (24 - 8 * i)

        netmaskOctets = netmaskOctets.map((value) => { return Number(value) })
        let netInt = 0;
        for (let i = 0; i < 4; i++)
            netInt += netmaskOctets[i] << (24 - 8 * i)

        let bitsCounter = 0;
        do {
            if ((netInt & 0x000001) === 0) {
                bitsCounter++
                netInt = netInt >> 1
            } else {
                break
            }
        } while (true)

        ipInt = ipInt >> bitsCounter
        ipInt += 1
        ipInt = ipInt << bitsCounter
        ipInt += 1

        return toString(ipInt)
    },

    sameNetwork: (ip1, ip2, netmask) => {
        let ipOctets = ip1.split('.')
        let ipCheckOctets = ip2.split('.')
        let netmaskOctets = netmask.split('.')

        ipOctets = ipOctets.map((value) => { return Number(value) })
        let ipInt = 0;
        for (let i = 0; i < 4; i++)
            ipInt += ipOctets[i] << (24 - 8 * i)

        ipCheckOctets = ipCheckOctets.map((value) => { return Number(value) })
        let ipCheckInt = 0;
        for (let i = 0; i < 4; i++)
            ipCheckInt += ipCheckOctets[i] << (24 - 8 * i)

        netmaskOctets = netmaskOctets.map((value) => { return Number(value) })
        let netInt = 0;
        for (let i = 0; i < 4; i++)
            netInt += netmaskOctets[i] << (24 - 8 * i)

        let ipNet = ipInt & netInt
        let ipCheckNet = ipCheckInt & netInt

        return ipNet === ipCheckNet
    },

    extractNetwork: (ip, netmask) => {

        let ipInt = toInt(ip)
        let netInt = toInt(netmask)
        let network = ipInt & netInt

        return toString(network)
    },

    isIpInRange: (ip, rangeStart, rangeEnd) => {
        const ipInt = toInt(ip)
        const rangeStartInt = toInt(rangeStart)
        const rangeEndInt = toInt(rangeEnd)

        if (ipInt < rangeStartInt || ipInt > rangeEndInt)
            return false

        return true
    }
}

export default IPUtils