import React from 'react'
import { useParams } from 'react-router-dom'
import RouterDeviceList from './router-device-list'
import MonitoringWifiInfo from './wifi-info'

import './wifi.css'

export default function MonitoringWifiData() {

    const params = useParams()

    const deviceid = params.id

    return <div id='management-wifi'>
        <div className='subtitle'>Redes Wi-Fi</div>

        <div className='flex-container'>

            <MonitoringWifiInfo
                deviceid={deviceid}
            ></MonitoringWifiInfo>

            <RouterDeviceList
                deviceid={deviceid}
            ></RouterDeviceList>

        </div>
    </div>
}