import React, { useState, useEffect, useContext } from 'react'
import { BackendContext, backendStatus } from '../backend/backend'
import { useHistory } from 'react-router-dom'
import { isGLogin } from './glogin'
import { RemotizeType } from './pages/signup/signup'
import isElectron from './server/is-electron'
import httpStatus from 'http-status'

import firebase from 'firebase/app'
import { FirebaseContext } from './firebase'

export const UserContext = React.createContext(null)

export const getUserId = () => {

    return localStorage.getItem('user_id')
}

const getUserEmail = () => {

    if(isGLogin || isElectron())
        return localStorage.getItem('uemail')

    return ''
}

const getUserProvider = () => {

    return 'google'
}

export default function User({children}) {


    const backend = useContext(BackendContext)
    const firebaseCtx = useContext(FirebaseContext)
    const history = useHistory()

    const [fetching, setFetching] = useState(false)
    const [user, setUser] = useState({username: ''})
    const [latestTerms, setLatestTerms] = useState(null)
    const [userConsent, setUserConsent] = useState(null)

    const createUser = async () => {

        let result = await backend.create(`users`, {
            username: getUserId(),
            email: getUserEmail(),
            idprovider: getUserProvider()
        })

        if(result.status === backendStatus.SUCCESS){
            fetchUser()
        }
    }

    const fetchUser = async () => {

        let userid = isElectron() ? user.username : firebase.auth().currentUser.providerData[0].uid

        if(!user || !userid || fetching)
            return

        setFetching(true)

        let result = await backend.retrieveFresh(`users/${userid}`)

        if(result.status === backendStatus.SUCCESS){

            let localUser = result.content

            localStorage.setItem('user_id', localUser.username) //FIXME: remove this use of localStorage

            setUser(localUser)

        }else{

            await createUser() //TODO: check this creation
            
        }

        setFetching(false)
    }

    const fetchLatestTerms = async() => {

        let result = await backend.retrieveFresh('lgpd/documents/latest')
        if (result.status === backendStatus.SUCCESS){
            setLatestTerms(result.content)
            return
        }

        setTimeout(fetchLatestTerms, 2000)
    }

    const fetchUserConsent = async() => {

        let result = await backend.retrieveFresh(`lgpd/consent/${user.username}`)


        if (result.status === backendStatus.SUCCESS){
            setUserConsent(result.content)
            return
        }

        if(result.content.status === httpStatus.NOT_FOUND){
            history.push('/consent')
            return
        }

        setTimeout(fetchUserConsent, 2000)
    }

    const isUserLoggedIn = () => {
        /* Login with electron app will fill username in glogin.js */
        if(user.username !== '' || history.location.pathname.includes('glogin')){
            return true
        }

        return false
    }

    useEffect(() => {

        if(isElectron() && user.username !== ''){
            fetchUser()
            return
        }

        if(!firebaseCtx || !firebaseCtx.initialized)
            return


        if(user.type === undefined) {
            fetchUser()
            return
        }

        if(user && (user.ispid === '' || !user.ispid)){
            history.push('/signup')
            return
        }


        if(user && user.type === RemotizeType.BLOCKED){
            history.push('/blocked')
            return
        }

        fetchLatestTerms()

        if(!userConsent){
            fetchUserConsent()
            fetchLatestTerms(backend, setLatestTerms)
        }

        if(!isElectron()){
            fetchUser()
        }

    // eslint-disable-next-line         
    }, [user, history.location])

    useEffect(() => {

        if(!userConsent || !latestTerms) return

        if(userConsent.terms_of_use !== latestTerms.terms_of_use || userConsent.privacy_policy !== latestTerms.privacy_policy){
            history.push('/consent')
        }

        // eslint-disable-next-line
    }, [userConsent, latestTerms])

    return <UserContext.Provider value={[user, setUser]}>{isUserLoggedIn() && children}</UserContext.Provider>
}