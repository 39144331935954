import GatewayManagePage from '../pages/devices/management/gateway/gateway-manage-page.js'
import { TR069API } from './tr069/tr069_api'
import Device from './device'

export default class GatewayDevice extends Device {
    //Have to do a new API backend for the gateway TR-069
    constructor(device, backend) {
        super(device, backend)
        this.api = new TR069API(backend)
    }

    getManagementPage() {
        return <GatewayManagePage></GatewayManagePage>
    }

}