import React, { useEffect, useState, forwardRef } from 'react'
import Joyride, { EVENTS, STATUS, ACTIONS } from 'react-joyride';
import isElectron from '../../server/is-electron'
import HelpIcon from '../../../components/icons/wizard'
import './device-tour.css'

const joyLocale = {back: 'Voltar', close: 'Fechar', last: 'Entendido', next: 'Próximo', skip: 'Pular'}

export default function DeviceTour(){
    const [dontShowAgain, setDontShowAgain] = useState(false)
    const [deviceTour, setDeviceTour] = useState(false)

    useEffect(() => {

        let device_tour = localStorage.getItem('device_tour')
        
        if((device_tour === 'true' || device_tour === null)){
            setDeviceTour(true)
        }

    }, [])

    const DontShowAgain = () => {
        return( <div className='dont-show'>
                    <label> 
                        <input 
                            type="checkbox"
                            checked={dontShowAgain}
                            onChange={(e) => {
                                setDontShowAgain(!dontShowAgain)
                                if(!dontShowAgain){
                                    localStorage.setItem('device_tour', 'false')
                                }
                            }}>
                        </input>
                        Não mostrar este guia novamente
                    </label>
                </div>
        )
    }

    const steps = [
        {
            content: <div>
                        <p>Este guia irá ajudar você a associar seus dispositivos à sua conta Remotize.</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'center',
            target: 'body',
            title: 'Bem vindo ao Remotize',
        },
        {
            content: <div>
                        <p>Esta é a lista de dispositivos do seu parque. Aqui você pode consultar as informações gerais de cada um, como MAC, status, versão de firmware, etc.</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'bottom',
            target: '.list',
            title: 'Lista de Dispositivos',
        },
        {
            content: <div>
                        <p>Antes de avançarmos para realizar associações de dispositivos à sua conta, certifique-se que sua rede local está configurada conforme o diagrama abaixo .</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'bottom',
            target: '.diagram',
            title: 'Diagrama de rede',
        },
        {
            content: <div>
                        <p>Ao clicar no botão <strong>Procurar dispositivos</strong> o aplicativo Remotize irá encontrar todos os dispositivos compatíveis com a plataforma que estiverem conectados ao seu switch.</p>
                        <DontShowAgain></DontShowAgain>
                    </div>,
            placement: 'bottom',
            target: '#btn-discovery',
            title: 'Busca de Dispositivos',
        }
    ]

    const handleTourCallback = (data) => {
        const { action, status, type } = data;

        if([ACTIONS.CLOSE].includes(action)){
            if(dontShowAgain){
                localStorage.setItem('device_tour', false)
            }

            setDeviceTour(false)
        }

        if([STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
            [EVENTS.TOUR_END].includes(type)) {

            if(dontShowAgain){
                localStorage.setItem('device_tour', false)
            }

            setDeviceTour(false)
        }
    };

    const handleShowTour = () => {
        setDeviceTour(true)
        setDontShowAgain(false)

        localStorage.setItem('device_tour', true)
    }

    const emptyBeacon = forwardRef((props, ref) => {
        return <div ref={ref}></div>;
    });

    return(
        <div>
            {isElectron() && <div className="help-icon" onClick={handleShowTour}>
                <HelpIcon title={"Executar o guia de ajuda"}></HelpIcon>
            </div>}

            {!deviceTour ? null :
            <div>
                <Joyride
                    steps={steps}
                    callback={handleTourCallback}
                    continuous={true}
                    run={true && isElectron()}
                    styles={{
                        options: {
                            primaryColor: '#00a335',
                            zIndex: 10000,
                        },
                    }}
                    locale={joyLocale}
                    showSkipButton={true}
                    disableOverlayClose={true}
                    disableCloseOnEsc={true}
                    beaconComponent={emptyBeacon}
                />

            </div>}
        </div>
        
    )
}