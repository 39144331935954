import React, { useContext } from 'react'
import './number-picker.css'
import ChevronLeftIcon from '../icons/chevron-left'
import { FormContext } from '../form/form'

export default function NumberPicker({value, onClickUp, onClickDown}) {

    const formContext = useContext(FormContext)

    return <div className='number-picker'>

        <div className='number-picker-up icon'
            onClick={() => {
                if(formContext)
                    formContext.setPristine(false)
                onClickUp()
            }}
        ><ChevronLeftIcon size={25}></ChevronLeftIcon></div>

        <div className='number-picker-value'>{value}</div>

        <div className='number-picker-down icon'
            onClick={() => {
                if(formContext)
                    formContext.setPristine(false)
                onClickDown()
            }}
        ><ChevronLeftIcon size={25}></ChevronLeftIcon></div>

    </div>
}